'use strict';
angular.module('bitnudgeApp')
    .controller('reportModalCtrl', function ($scope, $mdDialog, dataValues, selectedMetric, unit, sortOrder, thresholdValue, extras, ngXlsx, p0, $rootScope, Auth) {
        var me = Auth.getCurrentUser();
        $scope.title = selectedMetric.name;
        $scope.strings = $rootScope.strings;
        $scope.tableHeaders = [];
        $scope.allValueKeys = [];
        $scope.showDivisions = p0.allDivisions.length > 1;
        $scope.join = extras.join;
        $scope.options = {showAll:false};
        var title2;
        var groupedValues = _.groupBy(dataValues,'empId');
        if(extras.cycles.length > 1){
            if(!extras.join){
                $scope.tableHeaders = _.map(extras.cycles, 'name');
            }else{
                $scope.tableHeaders = [$scope.title];
            }
            $scope.tableHeaders = _.map($scope.tableHeaders, function (header) {
                return header + ' ('+ unit+')';
            });

            if(extras.selectedMetric2){
                title2 = extras.selectedMetric2.name;
                $scope.tableHeaders.push(title2+'('+extras.unit2+')');
            }
            $scope.tableHeaders.forEach(function (header, index) {
                $scope.allValueKeys.push('count'+index);
            });
        }else{
            $scope.tableHeaders = [$scope.title + ' ('+unit+')'];
            $scope.allValueKeys = ['count0'];
            if(extras.selectedMetric2){
                title2 = extras.selectedMetric2.name;
                $scope.tableHeaders.push(title2+'('+extras.unit2+')');
                $scope.allValueKeys.push('count1');
            }
        }
        function getUserValues(userData) {
            if(extras.join){
                var m1, m2, data = [];
                m1 = _.find(userData,{activity:selectedMetric.id});
                m2 = _.find(userData,{activity:extras.selectedMetric2.id});
                if(m1){data.push(m1)};
                if(m2){data.push(m2)};
                return data;
            }else{
                return userData;
            }
        }
        function compare(op1, op2, operator){
            if(operator == 'desc'){
                return op1 >= op2;
            }else{
                return op1 < op2;
            }
        }
        function join(c1, c2, operator){
            if(operator == 'And'){
                return c1 && c2;
            }else{
                return c1 || c2;
            }
        }
        function sortValues(data, cycles) {
            var sortedData = [];
            cycles.forEach(function(cycle){
                var cycleData = _.find(data, function(cData){
                    return cData.cycleId.toString() == cycle._id.toString();
                });
                if(cycleData){
                    sortedData.push(cycleData);
                }
            });
            return sortedData;
        }

        var allRows = [];
        for(var userId in groupedValues){
            var userValues = getUserValues(groupedValues[userId]);
            var row = userValues[0];
            var job = _.find(p0.allJobRoles, {_id: row.jobRole});
            row.roleName = job.name;
            var divisions = _.filter(p0.allDivisions, function (division) {
                return row.divisions.indexOf(division._id) != -1;
            });
            var divisionNames = _.map(divisions, 'name');
            row.divisionName = divisionNames.join(', ');
            if(userValues.length == 1){
                if (row.activity == 'Usage') {
                    row['count0'] = Math.round(row.count/60);
                }else{
                    row['count0'] = Math.round(row.count);
                }
            }else{
                if(extras.cycles.length > 1){
                    userValues = sortValues(userValues, extras.cycles);
                }
                userValues.forEach(function (userValue, index) {
                    if (userValue.activity == 'Usage') {
                        row['count'+index] = Math.round(userValue.count/60);
                    }else{
                        row['count'+index] = Math.round(userValue.count);
                    }
                });
            }
            if(!extras.join){
                row.aboveThreshold = true;
                $scope.allValueKeys.forEach(function (valueKey) {
                   row.aboveThreshold = row.aboveThreshold && row[valueKey] >=  thresholdValue;
                });
            }
            else{
                row.aboveThreshold = false;
                row.aboveThreshold = join(compare(row['count0'], thresholdValue, sortOrder),compare(row['count1'], extras.thresholdValue2, extras.sortOrder2), extras.join);
            }
            allRows.push(row);
        }
        $scope.allRows = allRows;
        if(extras.join && $rootScope.isSeniorManager){
            _.remove($scope.allRows,{_id:me._id});
        }
        if(selectedMetric.id == 'Usage' && !extras.join){
            $scope.showUsage = true;
            getInactiveUsersSummary();
        }
        $scope.downloadExcel = function(){
            var data = [];
            $scope.allRows.forEach(function(row){
                var rowValue = {
                    divisionName : row.divisionName,
                    employeeId: row.empId,
                    name: row.name,
                    teamName: row.team,
                    roleName: row.roleName
                };
                $scope.allValueKeys.forEach(function (value) {
                    rowValue[value] = row[value];
                });
                data.push(rowValue);
            });
            var sheetData = {
                sheetName: $scope.title + ' ('+unit+')',
                columnDefs: [
                    {field: "divisionName", displayName: $rootScope.strings.DIVISION.value + " Name"},
                    {field: "employeeId", displayName: "Emp ID"},
                    {field: "name", displayName: "Name"},
                    {field: "teamName", displayName: "Team Name"},
                    {field: "roleName", displayName: "Role Name"}
                ],
                data: data
            };
            $scope.tableHeaders.forEach(function (header, index) {
                sheetData.columnDefs.push({field: "count"+index, displayName:header});
            });

            var result = ngXlsx.writeXlsx([sheetData]);
            var rangeText = '';
            if(extras.cycles.length == 1){
                rangeText = extras.cycles[0].name;
            }else{
                rangeText = new Date().toDateString();
            }
            // TODO: move excel download to server side
            saveAs(new Blob([s2ab(result)],{type:"application/octet-stream"}), "Adoption Report("+$scope.header+")["+rangeText+"].xlsx");
        };

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        $scope.query = {
            limit: 10,
            page: 1
        };
        if(sortOrder == 'desc'){
            $scope.sortCriteria = '-count0';
        }else{
            $scope.sortCriteria = 'count0';
        }
        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        function getInactiveUsersSummary(){
            var divisions = _.groupBy($scope.allRows,'divisionName');
            $scope.summaryData = [];

            for(var divisionName in divisions){
                var tempObj = {};
                tempObj.name = divisionName;
                tempObj.count = _.filter(divisions[divisionName],{count0:0}).length;
                $scope.summaryData.push(tempObj);
            }
        }
        function getHeader() {
            var header = '';
            if(extras.kpi._id != 'overall'){
                header = extras.kpi.name+ ': ';
            }
            if(extras.join && (selectedMetric.id == 'mtd' || extras.selectedMetric2.id == 'mtd')){
                header += selectedMetric.name + ', '+extras.selectedMetric2.name +' ('+extras.cycles[0].name+')';
            }else if(selectedMetric.id == 'mtd' && !extras.join){
                header += selectedMetric.name;
            }else{
                var rangeText = "", metric2= '';
                if(extras.join){
                    metric2 = ', '+extras.selectedMetric2.name;
                }
                if(extras.cycles.length == 1){
                    rangeText = ' ('+extras.cycles[0].name+')';
                }
                header +=  selectedMetric.name + metric2 +rangeText;
            }
            $scope.header = header;
        }
        getHeader();
    });
