'use strict';

angular.module('bitnudgeApp')
  .controller('SettingsCtrl', function ($scope, $q, Auth,$state,toaster) {
    $scope.errors = {};
    $scope.user = {}
    const user = Auth.getCurrentUser();

    $scope.changePassword = function(form) {
        $scope.submitted = true;
            //min 1 digit, 1 alphabet, 1 special character, password length>= 8 characters
            var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/);
            var validatePassword = pattern.test($scope.user.newPassword);

            var confirmOldPassword = ($scope.user.oldPassword != $scope.user.newPassword);
            var confirmNewPassword = ($scope.user.newPassword == $scope.user.confirmPassword);

            if (!confirmOldPassword)
                $scope.message = 'New Password is same as Current Password. Please enter a new one.';
            if (!confirmNewPassword)
                $scope.message = 'Confirm Password is not same as New Password. Please enter again.';
            if (!validatePassword)
                $scope.message = 'Please enter the correct format(a letter, a special character, a digit and password length greater than 8 characters)';

        if(true) {
            console.log('jere')
            return Auth.changePassword( $scope.user.oldPassword, $scope.user.newPassword )
            .then( function(result) {
                if(result.status){
                    $scope.message = 'Password successfully changed.';
                    $state.go('userFlow.main');
                    toaster.pop('success', "Password successfully changed.");
                }else{
                    result.errors.forEach((error) => {
                        toaster.pop('error', error.message);
                    })
                }
            })
            .catch( function(err) {
                    if(err.status=='600')
                    {   toaster.pop("Failure","Please enter a new password!!");
                        $scope.errors.other = '';
                    }
                    else{
                        form.password.$setValidity('mongoose', false);
                        $scope.errors.other = 'Incorrect password';
                        toaster.pop("Failure","Please try again");
                    }
                    $scope.message = '';
            });
        } else {
            toaster.pop("Failure", "Try again!!");
            return $q.resolve()
        }
    };
  });
