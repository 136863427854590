const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/leadQuickFilterModal.html');

angular.module('bitnudgeApp').service('Tickets', ['$http', '$q', '$mdDialog', 'Auth', 'p0',
    '$rootScope', 'WorkflowService', '$filter',
    function($http, $q, $mdDialog, Auth, p0, $rootScope, WorkflowService, $filter) {
        var currentUser = '';
        var modalObj = {};
        var ticketObj = {};
        var isManager = false;
        var me = Auth.getCurrentUser();
        var contactNumbers;
		var ticketStatus;

        this.setUploadModalObject = function(obj) {
            modalObj = obj;
        };

        this.getUploadModalObject = function() {
            return modalObj;
        };

        this.setCurrentUser = function(user) {
            currentUser = user;
        };

        this.getCurrentUser = function() {
            return currentUser;
        };

        this.setContactNumbers = function(input) {
            contactNumbers = input;
        };

        this.getContactNumbers = function() {
            return contactNumbers;
        };

        this.deleteContactNumbers = function() {
            contactNumbers = undefined;
        };

        this.getSelectedTicket = function() {
            return ticketObj;
        };

        this.setSelectedTicket = function(ticket) {
            ticketObj = ticket;
        };

        this.showQuickFilterModal = function(filters, allTickets, tickets, callback) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'ticketQuickFilterModalController',
                clickOutsideToClose: true,
                fullscreen: false,
                resolve: {
                    filters: function() { return filters; },
                    allTickets: function() { return allTickets; },
                    tickets: function() { return tickets; },
                    callback: function() { return callback; }
                }
            });
        };

        this.shareCSReForm = (ticket) => {
            let baseUrl = '/api/tickets/shareCSReForm' 
            const postData= {
                ticketId: ticket._id
            }
            return $http.post(baseUrl, postData);
        } 

        /**
         * function to call API to fetch tickets by user
         */
        this.fetchTickets = function(userId, filterObj, pagination, cui, isCustomerView, sortingObj) {
            var baseUrl = '/api/tickets/getTickets/';
            //as per the CR only in case of followUpIn we will not consider the cycle dates
            var postData = {
                userId: userId,
                cui: cui,
                withReporting: filterObj.withReporting,
                startDate: filterObj.state === "followUpIn" ? new Date().getTime() :  new Date(filterObj.startDate).getTime(),
                endDate: new Date(filterObj.endDate).getTime(),
                state: filterObj.state,
                type: filterObj.type,
                //subType:null,
                searchKey: filterObj.searchParam,
                productId: filterObj.tab,
                archived: filterObj.archived,
                offset: pagination.offset,
                limit: pagination.limit,
                isCustomerView: isCustomerView,
                playtypeSelected: filterObj.playtypeSelected,
                unassignedView: filterObj.viewCategory === "unassigned",
                workflowIds:filterObj.workflowIds || null
            };
            if (postData.state == "") {
                var len = postData.state.length
                if (!len) {
                    delete postData.startDate;
                    delete postData.endDate;
                }

            }
            if (postData.state == "active") {
                delete postData.state;
            }
            if (sortingObj && !_.isUndefined(sortingObj)) {
                postData.sortKey = sortingObj.sortKey;
                postData.sortOrder = sortingObj.sortOrder;
            }

            return $http.post(baseUrl, postData);
        }

        this.getSubTabs = function(userId, withReportee, unassigned) {
            var startDate = new Date(p0.currentMonthlyCycle.startDate).getTime();
            var endDate = new Date(p0.currentMonthlyCycle.endDate).getTime()
            var url = '/api/tickets/counts/';
            var postData = {
                userId: userId,
                startDate: startDate,
                endDate: endDate,
                withReportee: withReportee,
                unassigned
            };
            return $http.post(url, postData);
        }

        var filterByType = function(tickets, type) {
            type = type.toString().toLowerCase();
            return _.remove(tickets, function(ticket) {
                return ticket.ticketType.toString().toLowerCase() != type;
            });
        }

        var filterByStatus = function(tickets, status, selectedCycle) {
            return _.remove(tickets, function(ticket) {
                var lastUpdatedTs = new Date(ticket.lastUpdateDate);
                var condition1 = (ticket.ticketStatus.toString().toLowerCase() == status.toString().toLowerCase())
                var condition2 = (lastUpdatedTs >= new Date(selectedCycle.startDate)) && (lastUpdatedTs <= new Date(selectedCycle.endDate))
                if (condition1 && condition2) {
                    return false;
                } else {
                    return true;
                }
            });
        }

        // var filterBySource = function(tickets, source) {
        //     source = source.toString().toLowerCase();
        //     return _.filter(tickets, function (ticket) {
        //         return ticket.source.creation.toString().toLowerCase() == source;
        //     });
        // }

        var filterByKpi = function(tickets, selectedKpiId) {
            selectedKpiId = selectedKpiId.toString();
            return _.remove(tickets, function(ticket) {
                if (ticket.kpiId == undefined) {
                    if (selectedKpiId == 'Others') {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return ticket.kpiId._id.toString() != selectedKpiId;
                }
            })
        }

        var filterByContactNumber = function(allTickets) {
            _.remove(allTickets, function(ticket) {
                //return contactNumbers.indexOf(ticket.contactNumber) == -1
                return contactNumbers.indexOf(ticket.contactNumber) == -1
            });
        }

        this.filterTickets = function(allTickets, filterObj, selectedCycle, selectedKpiId) {
            if (contactNumbers) {
                filterByContactNumber(allTickets);
            }
            //filter according to kpi
            filterByKpi(allTickets, selectedKpiId);
            if (filterObj.ticketType) {
                filterByType(allTickets, filterObj.ticketType);
            }
            if (filterObj.ticketStatus) {
                filterByStatus(allTickets, filterObj.ticketStatus, selectedCycle);
            }
            _.remove(allTickets, function(ticket) {
                return filterObj.materialized == ticket.materialized && filterObj.eligible == ticket.eligible;

            });
            // if(filterObj.source){
            //     filterBySource(allTickets, filterObj.source);
            // }
        };

        this.getTicket = function(ticketId) {
            return $http.post('/api/tickets/show',{id: ticketId});
        }

        this.fetchFiltersList = function() {
            return $http.post('/api/tickets/filtersList');

            //return WorkflowService.fetchFiltersList('api/tickets/filtersList');

        }
        
        fetchDateString = function(date) {
            let mm = date.getMonth() + 1; // getMonth() is zero-based
            let dd = date.getDate();
            
            return [date.getFullYear(),
                    (mm>9 ? '' : '0') + mm,
                    (dd>9 ? '' : '0') + dd
                    ].join('/');
        }

        this.createTicket = function(data) {
            const dateString = fetchDateString(new Date(data.requiredClosureDate));
            data.requiredClosureDate = dateString;
            return $http.post('/api/tickets/create', data)
        }


        // this.createLead = function(data){
        //     return $http.post('/api/tickets/create',data);
        // }

        this.getNextStates = function(ticketId, userId) {
            return WorkflowService.getNextStates('api/tickets/getNextStates', {ticketId, userId})
        };

        this.gotoState = function(ticket) {
            return WorkflowService.gotoState('api/tickets/update', ticket)
        };

        this.saveInteraction = function(interactedData) {
            return $http.post('/api/interactions/schedule', interactedData);
        };
        this.getInteractions = function() {
            return $http.post('/api/interactions/types');
        };

        this.getFutureFollowUps = function(ticketId) {
            return $http.post('/api/tickets/futureFollowUps', {ticketId});
        }

        var setTimestampStatus = function(ticket) {
            //today
            var today = new Date();
            var todayStart = new Date()
            todayStart.setHours(0, 0, 0, 0);
            var todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 59);
            //yesterday
            var yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            var yesterdayStart = new Date(yesterday);
            yesterdayStart.setHours(0, 0, 0, 0);
            var yesterdayEnd = new Date(yesterday);
            yesterdayEnd.setHours(23, 59, 59, 59);

            if (ticket.requiredClosureDate != undefined) {
                var requiredClosureDate = new Date(ticket.requiredClosureDate);
                if (requiredClosureDate < today) {
                    ticket.requiredClosureDateStatus = {
                        class: "swot-red",
                        text: 'Missed '
                    }
                } else if (requiredClosureDate >= today && requiredClosureDate < todayEnd) {
                    var dateText = $filter('date')(requiredClosureDate, 'MMM dd, yyyy HH:mm');
                    ticket.requiredClosureDateStatus = {
                        class: "green",
                        text: dateText
                    }
                } else {
                    var dateText = $filter('date')(requiredClosureDate, 'MMM dd, yyyy HH:mm');
                    ticket.requiredClosureDateStatus = {
                        class: "swot-green",
                        text: dateText
                    };
                }
            }
            if (ticket.actualClosureTs != undefined) {
                var actualClosureTs = new Date(ticket.actualClosureTs);
                if (actualClosureTs < today) {
                    ticket.actualClosureTsStatus = {
                        class: "swot-red",
                        text: 'Missed '
                    }
                } else if (actualClosureTs >= today && actualClosureTs < todayEnd) {
                    var dateText = $filter('date')(actualClosureTs, 'MMM dd, yyyy HH:mm');
                    ticket.actualClosureTsStatus = {
                        class: "green",
                        text: dateText
                    }
                } else {
                    var dateText = $filter('date')(actualClosureTs, 'MMM dd, yyyy HH:mm');
                    ticket.actualClosureTsStatus = {
                        class: "swot-green",
                        text: dateText
                    };
                }
            }
        }

        this.setExtraInfoOnTicketsList = function(tickets) {
            let todayStart = new Date()
            todayStart.setHours(0, 0, 0, 0);
            let todayEnd = new Date();
            todayEnd.setHours(23, 59, 59, 59);

            _.each(tickets, function(ticket) {
                ticket.creationText = $filter('date')(ticket.ticketCreationDate, 'mediumDate');
                if (ticket.requiredClosureDate) {
                    ticket.requiredClosureDate = new Date(ticket.requiredClosureDate);
                }

                setTimestampStatus(ticket);
                if (ticket.userId && $rootScope.isMiddleManager && ticket.userId._id == me._id)
                    ticket.noHandler = true;

                //set followUp date from scheduled interactions
                if (ticket.interactions.length) {
                    let scheduledInteractions = _.filter(ticket.interactions, {
                        status: 'scheduled'
                    });
                    if (scheduledInteractions.length) {
                        scheduledInteractions = _.sortBy(scheduledInteractions, 'scheduledAt');
                        let timestamp = Date.parse(ticket.followUpDate);
                        if (!isNaN(timestamp)) {
                            ticket.followUpDate = new Date(timestamp);
                        }
                        //setting followUpDate status and swot-color
                        var now = new Date();
                        ticket.followUpMissed = false;
                        if (ticket.followUpDate < now) {
                            ticket.followUpMissed = true;
                            ticket.followUpDateStatus = {
                                class: "swot-red",
                                text: 'Missed '
                            }
                        } else if (ticket.followUpDate >= now && ticket.followUpDate < todayEnd) {
                            var dateText = $filter('date')(ticket.followUpDate, 'MMM dd, yyyy');
                            ticket.followUpDateStatus = {
                                class: "green",
                                text: dateText
                            }
                        } else {
                            var dateText = $filter('date')(ticket.followUpDate, 'MMM dd, yyyy');
                            ticket.followUpDateStatus = {
                                class: "swot-green",
                                text: dateText
                            };
                        }
                    }
                }
            });
        }

        this.getPaginationObj = function() {
            var pagination = {
                offset: 0,
                limit: 50,
                total: 0,
                disableNext: true,
                disablePrevious: true
            }
            return pagination;
        }

        this.getCustomerNames = function(userId, includeUnassigned, customerCui) {
			let url = '/api/customers/getCustomersByUser';
			let postData = {
                userId,
                customerCui
			}
			if(includeUnassigned){
				postData['includeUnassigned'] = includeUnassigned
			}

			return $http.post(url, postData);
        }
        this.getMyTeam = function(userId) {
            let url = `api/users/getActiveFrontLineUsers`;
            return $http.post(url, {defaultCustomerHandler: userId});
        }

        this.getTicketHistory = function(ticket) {
            return $http.post('/api/tickets/history',{ticketId:ticket._id});
        }

        this.getTicketsPillsCount = function(extraParams) {
            var baseUrl = '/api/tickets/getTicketsPillsCounts';
            let postBody = {};
            if (extraParams.userId) {
                postBody.userId = extraParams.userId;
            }
            if (extraParams.cui) {
                postBody.cui = extraParams.cui;
            }
            return $http.post(baseUrl, postBody);

        };

        this.getDocumentsCache = function(context, workflowId) {
            return $http.post(`/api/documents/documentsCache`, {workflowId, context});
        };

        return this;
    }
]);
