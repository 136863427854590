"use strict";

angular.module("bitnudgeApp").controller("carouselComponentCtrl", function($scope, Auth, DocumentService,$timeout) {
	$scope.getCurrentUser = Auth.getCurrentUser;
	$scope.loadingThumbnail = false;
	$scope.slickConfig = {
		enabled: true,
		infinite: false,
		autoplay: false,
		draggable: false,
		slidesToShow: 6,
		centerMode: false,
		focusOnSelect: true,
		slidesToScroll: 1
	};

	$scope.slideClicked = function($event, documentTypeId,docthumbnailUid,docId) {
		if($scope.thumbnailselected !== documentTypeId){
			$scope.thumbnailselected = documentTypeId;
			$scope.$emit("dms:carousel:click",{documentTypeId,docthumbnailUid,docId});
		}
		//TODO: generate the event to load the selected document
	};	

	this.$onChanges = changes => {
		if (changes.thumbnails) {
			if(!changes.thumbnails.isFirstChange()){
				$scope.docthumbnails = changes.thumbnails.currentValue;
				$scope.slickReload = false;
				$scope.loadingThumbnail = true;
				reloadSlick();
			}else{
				$scope.loadingThumbnail = false;
			}
		}
		if (changes.thumbnailselected) {
			$scope.thumbnailselected = changes.thumbnailselected.currentValue;
		}
	};
	const reloadSlick = () => {
		$timeout( function(){
            $scope.slickReload = true;
        },0);
	}
});
