'use strict';
angular.module('bitnudgeApp')
    .controller('inputRendererCtrl', function ($rootScope, $scope, $state, moment){
        const parseDateFromString = function(dateString) {
            var parsedDate;
            const index1 = Math.max(dateString.indexOf('days'), dateString.indexOf('months'));
            const number = parseInt(dateString.substr(1, index1 - 1), 10);
            if (dateString[0] === '-') {
                parsedDate = new Date(moment().subtract(number, dateString.substr(index1)));
            }
            else if (dateString[0] === '+') {
                parsedDate = new Date(moment().add(number, dateString.substr(index1)));
            }
            else {
                // today case
                parsedDate = new Date();
            }
            return parsedDate;
        };

        if($scope.item && $scope.item.name) {
        	if($scope.item.name.startsWith('approve:')){
				$scope.item.checkerMode = true;
			} else if($scope.item.name.startsWith('upload:')){
				$scope.item.makerMode = true;
			}
		}

        if($scope.item && $scope.item.type && $scope.item.validation && $scope.item.type === 'date'){
            if($scope.item.validation.minDate &&
                typeof $scope.item.validation.minDate === 'string'){
                $scope.item.validation.minDate = parseDateFromString($scope.item.validation.minDate);
            }
            if($scope.item.validation.maxDate
                && typeof $scope.item.validation.maxDate === 'string'){
                $scope.item.validation.maxDate = parseDateFromString($scope.item.validation.maxDate);
            }
        }

        //Note: In case of workflow type prerequisites the values of the prerequisite is the workflowId present in the prerequisite.reference
        if($scope.item && $scope.item.type && $scope.item.type === 'workflow'){
        	const {reference, options} = $scope.item;
        	if(reference && reference.to && options && options.isFinalPositive){
				$scope.item.value = reference.to;
			}
		}

        $scope.onSelectChange = function (item) {
            if(!item.refreshOn || !item.refreshOn.length){
                $rootScope.$broadcast('parent:onChange', { parent:item.name, value:item.value })
            }
        };
        if($scope.item && $scope.item.refreshOn && $scope.item.refreshOn.length){
            $rootScope.$on('parent:onChange', function(event, opt) {
                if($scope.item.refreshOn[0] === opt.parent){
                    $scope.item.options = $scope.item.optionsSource[opt.value];
                }
            });
        }


        $scope.gotoWorkflow = function(item){
        	const {reference} = item;
			if(reference.type === 'ticket') {
				$state.go('userFlow.editTicket', {ticketId: reference.to})
			}
		};

		$scope.removeWorkflow = function(item){
			$scope.$emit(`workflow:${$scope.context}:removePrerequisite`, { item })
		};

		$scope.documentAction = function (item) {
			$scope.$emit(`workflow:${$scope.context}:document`, { item })
		};

		$scope.approveDirect = (item) => {
			$scope.$emit(`workflow:${$scope.context}:approveDirect`, { item })
		};

		$scope.rejectDirect = (item) => {
			$scope.$emit(`workflow:${$scope.context}:rejectDirect`, { item })
		};
	});
