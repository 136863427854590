const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/region/region.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/region/listRegion.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/region/editRegion.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/region/showRegion.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/region/addBulkRegion.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/region/updateBulkRegion.html');

'use strict';

angular.module('bitnudgeApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('lms.region', {
                url: '/region',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'RegionCtrl'
            })

            .state('lms.listRegion', {
                url: '/listRegion',
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: function ($http, $scope,  Auth, $location, $q, toaster) {
                    $http.post('/api/regions').then(function(regions){
                        regions=regions.data;
                        $scope.regions = regions;
                        //socket.syncUpdates('region', $scope.regions);
                    });
                }
            })

            .state('lms.addRegion', {
                url: '/addRegion/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                controller: function ($stateParams, $scope, $http, $location,  toaster, Auth) {
                    $scope.editForm = false;
                    if($stateParams.id){
                        $scope.editForm = true;
                        $http.post('/api/regions/show',{id:$stateParams.id}).then(function(region){
                            region=region.data;
                            $scope.currentRegion = region;
                            $scope.currentRegion.orgId = Auth.getCurrentUser().orgId;
                            $http.post('/api/users/getUserById',{userId:region.regionHeadId._id}).then(function (user) {
                                user=user.data;
                                $http.post('/api/users/getUsersOfJobRole',{jobRoleIds:[user.organizationalTag.jobRole._id]}).then(function (users) {
                                    users=users.data;
                                    $scope.users = users;
                                })
                            })
                        });
                    }
                    else{
                        $http.post('/api/users/usersList').then(function (users) {
                            users=users.data;
                            $scope.users = users;
                        })
                    }
                    $scope.updateRegion = function () {
                        var region = $.grep($scope.regions, function(region) {
                            return region.name == $scope.currentRegion.name;
                        });

                        if(region[0] == undefined) {
                            $http.post('/api/regions/' + $stateParams.id, { name: $scope.currentRegion.name} ).then(function () {
                                    $location.path('/region/listRegion');
                                    toaster.pop('success', "Region Update", "Region Details Updated.");
                                }).catch(function () {
                                });
                        } else {
                            toaster.pop('error', "Region Edition", "Region '"+$scope.currentRegion.name+"' Already Exists!");
                        }
                    };
                }
            })

            .state('lms.showRegion', {
                url: '/showRegion/:id',
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                controller: function ($stateParams, $scope, $http, Auth,toaster) {
                    $http.post('/api/regions/show',{id: $stateParams.id}).then(function (region) {
                        region=region.data;
                        $scope.region = region;
                        //socket.syncUpdates('region', $scope.region);
                    });
                }
            })
            .state('lms.addBulkRegion',{
                url: '/addBulkRegion',
                templateUrl: autoImportNgTemplateLoaderTemplate5,
                controller:function ($http, $scope, Auth, Upload, $location, toaster) {

                    $scope.onFileSelectLead = function () {
                        if ($scope.bulkLead == undefined) {
                            toaster.pop('error', "Submission.", "Select File to Upload.");

                        } else {
                            var fileName = $scope.bulkLead[0].name.split(".");
                            var fileType = fileName[fileName.length - 1];
                            if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                                Upload.upload({
                                    url: '/api/regions/import/excel',
                                    data: {userId: Auth.getCurrentUser()._id, orgId: Auth.getCurrentUser().orgId},
                                    file: $scope.bulkLead[0],
                                    progress: function (e) {
                                    }
                                }).then(function (data, status, headers, config) {
                                    // file is uploaded successfully
                                    toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                                });
                            } else {
                                toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                            }

                        }
                    }

                }
            })
            .state('region.updateBulkRegion',{
              url: '/updateBulkRegion',
              templateUrl: autoImportNgTemplateLoaderTemplate6,
              controller:function ($http, $scope, Auth, Upload, $location, toaster) {

                $scope.onFileSelectLead = function () {
                  if ($scope.bulkLead == undefined) {
                    toaster.pop('error', "Submission.", "Select File to Upload.");

                  } else {
                    var fileName = $scope.bulkLead[0].name.split(".");
                    var fileType = fileName[fileName.length - 1];
                    if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                      Upload.upload({
                        url: '/api/regions/importUpdate/excel',
                        data: {userId: Auth.getCurrentUser()._id, orgId: Auth.getCurrentUser().orgId},
                        file: $scope.bulkLead[0],
                        progress: function (e) {
                        }
                      }).then(function (data, status, headers, config) {
                        // file is uploaded successfully
                        toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                      });
                    } else {
                      toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                    }

                  }
                }

              }
            })


    });
