'use strict';
angular.module('bitnudgeApp')
	.controller('hotKeyModalCtrl', function ($scope, hotKey, onClose, p0, leadsService, Auth, toaster, HotKeyService, $rootScope, $window, callback) {
		const hotKeysEnum = HotKeyService.hotKeysEnum;
		const me = Auth.getCurrentUser();
		$scope.show = {
			showListing: true,
			showLeads: false,
			backButton: true,
			hotKey: hotKey,
			selectedListings: [],
			creatingLink: false,
			sentAsSMS: false,
			copiedToClipBoard: false,
			successMessage: '',
			linkCode: '',
			listingSearchParam: '',
			link:'',
			products: [],
			showLeadFilters: false,
			sortKey: 'updatedAt',
			sortOrder: 'desc',
			leadStatusKey:'active',
			searchParam: '',
			lastIds:['0#0']
		}
		//to search brochues
		const resetLastIds = () =>{
			$scope.show.lastIds = ['0#0']
		}
		const  [sortKeys, sortOrders] = leadsService.getSortKeysAndOrders();
		$scope.show.sortKeys = sortKeys;
		$scope.show.sortOrders = sortOrders;
		$scope.show.statusFilters = leadsService.getFirsterFilters();

		const updateDisplayNames = () =>{
			const sKey = sortKeys.find(e => e.key === $scope.show.sortKey);
			if (sKey) {
				 $scope.show.sortKeyName = sKey.name; 
			}
			const sOrder = sortOrders.find(e => e.key === $scope.show.sortOrder);
			if (sOrder) {
				$scope.show.sortOrderName = sOrder.name;
			}
			const statusObj = $scope.show.statusFilters.find(e => e.key === $scope.show.leadStatusKey);
			if(statusObj){
				if(statusObj.name.toLowerCase().includes('leads')){
					$scope.show.statusName = statusObj.name
				}
				else {
					$scope.show.statusName = `${statusObj.name} leads`
				}
			}

		}

		updateDisplayNames();

		$scope.showFilters =()=>{
			$scope.filters = {
				sortKey: $scope.show.sortKey,
				sortOrder: $scope.show.sortOrder,
				leadStatusKey: $scope.show.leadStatusKey,
			}
			$scope.show.showLeadFilters = true;
		}

		$scope.applyFilters = () =>{
			$scope.show.sortKey = $scope.filters.sortKey;
			$scope.show.sortOrder = $scope.filters.sortOrder;
			$scope.show.leadStatusKey = $scope.filters.leadStatusKey;
			$scope.pagination.offset = 0;
			updateDisplayNames()
			$scope.fetchLeads($scope.show.selectedProduct)
		}

		$scope.closeFilters =()=>{
			$scope.show.showLeadFilters = false;
		}

		if (hotKey.key === hotKeysEnum.jitsi || hotKey.key === hotKeysEnum.pubnub || hotKey.key === hotKeysEnum.securechat) {
			$scope.show.showListing = false;
			$scope.show.showLeads = true;
			$scope.show.backButton = false;
		}

		$scope.show.header = hotKey.name;
		$scope.show.headerIcon = `${hotKey.icon}-white`;

		$scope.goBackToListing = (hotKey) => {
			$scope.show.leads = null;
			$scope.show.showLeads = false;
			$scope.show.showListing = true;
			$scope.show.header = hotKey.name;
			$scope.show.headerIcon = `${hotKey.icon}-white`;
		}

		$scope.pagination = {
			offset: 0,
			limit: 50,
			from: 0,
			to: 0,
			total: 0,
		};

		$scope.listingPagination = {
			offset: 0,
			limit: 50,
			from: 0,
			to: 0,
			total: 0,
		}

		$scope.search = (product) => {
			if ($scope.show.searchParam) {
				$scope.pagination.offset = 0;
				$scope.fetchLeads(product);
				$scope.show.closeSearch = true;
			}
		}
		$scope.clearSearch = (product) => {
			$scope.show.searchParam = "";
			$scope.pagination.offset = 0;
			$scope.fetchLeads(product);
			$scope.show.closeSearch = false;
		}

		const extraParams = {
			currentMonthlyCycle: {
				startDate: p0.currentMonthlyCycle.startDate,
				endDate: p0.currentMonthlyCycle.endDate
			},
			selectedCycle: {
				startDate: p0.currentMonthlyCycle.startDate,
				endDate: p0.currentMonthlyCycle.endDate
			},
			userId: me._id
		}

		$scope.onClose = () => {
			if($scope.show.linkCode && $scope.show.link){
				callback($scope.show.linkCode, $scope.show.link)
			}
			onClose();
		}

		$scope.cancel = () => {
			if($scope.show.linkCode && $scope.show.link){
				callback($scope.show.linkCode, $scope.show.link)
			}
			onClose();
		}

		$scope.selectLead = (lead) => {
			if (!$scope.show.leads || !$scope.show.leads.length) {
				return 0;
			}
			const selectedLead = $scope.show.selectedLead;
			if (selectedLead && selectedLead.brn !== lead.brn) {
				toaster.pop('warning', 'Link can be shared with only 1 lead at a time.')
				return;
			}
			const findLead = $scope.show.leads.find(l => l.brn === lead.brn);
			if (findLead) {
				findLead.selected = !findLead.selected;
			}
			if (findLead.selected) {
				$scope.show.selectedLead = lead;
			} else {
				$scope.show.selectedLead = null;
			}
		}

		const getArtifacts = (items = [], hotKey, lead) => {
			const {
				contact
			} = lead;
			const artifacts = [];

			items.forEach(item => {
				switch (hotKey.key) {
                    case hotKeysEnum.eform:
                        const latest = item.versions.find(v => v.isLatest)
						artifacts.push({
							data: {
								eformId: item.id,
								eformVersionId: latest.id,
								description: item.description,
								header: item.header
							},
							type: hotKey.artifactType
						})
						break;
					case hotKeysEnum.brochure:
						artifacts.push({
							data: {
								documentId: item.documentId,
								unifiedDocId: item.unifiedDocId,
								source: item.source
							},
							type: hotKey.artifactType
						})
						break;
					case hotKeysEnum.docusign:
						artifacts.push({
							type: hotKey.artifactType,
							data: {
								signerEmail: contact.email,
								documentName: item.documentName,
								docusignTemplateId: item.id,
								documentType: item.documentType,
								signerName: contact.name
							}
						})
						break;
				}
			})

			if (hotKey.key === hotKeysEnum.jitsi) {
				artifacts.push({
					data: {},
					type: hotKey.artifactType,
				})
			} else if (hotKey.key === hotKeysEnum.pubnub || hotKey.key === hotKeysEnum.securechat) {
				artifacts.push({
					data: {},
					type: hotKey.artifactType,
				})
			}
			return artifacts;
		}

		function copyLinkToClipBoard(message) {
			try{
				navigator.clipboard.writeText(message)
			}catch(err){
				console.error(`Error in copying the message`, message)
			}
			$scope.show.copiedToClipBoard = true;
			$scope.show.successMessage = 'Invite link has been copied to clipboard'            
        }
        
        const getMessage = (key) =>{
			const message = {
				[hotKeysEnum.eform]:'Please fill the eForm below to apply for the Product. Click the link for details: ',
				[hotKeysEnum.brochure]: 'Please click on the link to view the document: ',
				[hotKeysEnum.docusign]: 'Please click on the link to sign the document: ',
				[hotKeysEnum.jitsi]: 'You are invited to join a video call. Please click on the link: ',
				[hotKeysEnum.pubnub]: 'You are invited to join a secure chat session. Please click on the link: ',
				[hotKeysEnum.securechat]: 'You are invited to join a secure chat session. Please click on the link: '
			}
            return message[key]
        }

		$scope.createLink = (sendAsSMS) => {
			const lead = $scope.show.selectedLead;
			$scope.show.creatingLink = true;
			const artifacts = getArtifacts($scope.show.selectedListings, hotKey, lead)
			const payload = {
				artifacts: artifacts,
				targetId: lead._id,
				targetType: 'lead'
			}
			HotKeyService.shareLink(payload).then(result => {
				if (result.status) {
					toaster.pop('success', 'Link created successfully.')
					const {
						link,
						linkCode
                    } = result.data;
                    let message = getMessage(hotKey.key)
					message = `${message} ${link}`;
					$rootScope.linkCode = linkCode;
					$rootScope.linkText = message;
					$scope.show.linkCode = linkCode;
					$scope.show.link = link;
					$scope.show.linkMessage = message;
					if (sendAsSMS) {
						const body = {
							message,
							targetId: lead._id,
							targetType: 'lead',
						}
						HotKeyService.sendSMS(body).then(smsResult => {
							if (smsResult.status) {
								$scope.show.successMessage = 'SMS Shared Successfully'
								$scope.show.sentAsSMS = true;
							} else {
								copyLinkToClipBoard(message)
							}
						})
					} else {
						copyLinkToClipBoard(message)
					}
				} else {
					toaster.pop('error', result.message)
				}
			})
		}

		$scope.fetchLeads = (product) => {
			if(product){
				extraParams.productId = product && product._id;
				$scope.show.selectedProduct = product;
			}
			$scope.show = {
				...$scope.show,
				header: 'Lead List',
				leadsLoading: true,
				showLeads: true,
				showListing: false,
				leads: null,
				infoMessage: 'Loading...'
			}
			const sortingObj = {
				sortKey: $scope.show.sortKey,
				sortOrder: $scope.show.sortOrder
			}
			const filters = {
				leadStatusKey: $scope.show.leadStatusKey,
				searchParam: $scope.show.searchParam
			}
			const promise = leadsService.getLeads(filters, extraParams, $scope.pagination, 'myleads', false, sortingObj);
			promise.then(result => {
				const data = result.data;
				if(data.status && data.data){
					const apiresult = data.data;
					if(!$scope.show.products.length){
						
						let products = apiresult.counts;
						const total = _.sumBy(products, 'total');
						products.unshift({
							name: "All",
							total
						})
						$scope.show.products = _.orderBy(products, 'total', 'desc');
						if (!product) {
							$scope.show.selectedProduct = $scope.show.products[0];
						} else {
							$scope.show.selectedProduct = product;
						}
					}
					
					$scope.show.leads = apiresult.leads && apiresult.leads.docs;
					$scope.pagination.total = apiresult.total;
					$scope.pagination.from = apiresult.from;
					$scope.pagination.to = apiresult.to;
					$scope.show.selectedLead = null;
					$scope.show.leadsLoading = false;
					$scope.show.infoMessage = '';
					if (!$scope.show.leads || !$scope.show.leads.length) {
						$scope.show.infoMessage = 'No Leads found';
					}
				}
			})
		}

		$scope.fetchNext = () =>{
			if($scope.pagination.total <= $scope.pagination.to){
				return;
			}
			$scope.pagination.offset = $scope.pagination.offset + $scope.pagination.limit;
			$scope.fetchLeads($scope.show.selectedProduct);
		}

		$scope.fetchPrevious = () =>{
			if(!$scope.pagination.offset){
				return
			}
			$scope.pagination.offset = $scope.pagination.offset - $scope.pagination.limit;
			$scope.fetchLeads($scope.show.selectedProduct);
		}

		$scope.fetchNextListing = () =>{
			if($scope.listingPagination.total <= $scope.listingPagination.to){
				return;
			}
			$scope.listingPagination.offset = $scope.listingPagination.offset + $scope.listingPagination.limit;
			$scope.getListing(hotKey);
		}

		$scope.fetchPreviousListing = () =>{
			if(!$scope.listingPagination.offset){
				return
			}
			if($scope.show.lastIds && $scope.show.lastIds.length){
				$scope.show.lastIds.splice(-1,1)
			}
			$scope.listingPagination.offset = $scope.listingPagination.offset - $scope.listingPagination.limit;
			$scope.getListing(hotKey);
		}

		$scope.searchListing = () => {
			if ($scope.show.listingSearchParam) {
				resetLastIds();
				$scope.listingPagination.offset = 0;
				$scope.getListing(hotKey);
				$scope.show.closeSearchListing = true;
			}
		}
		$scope.clearSearchListing = () => {
			$scope.show.listingSearchParam = "";
			resetLastIds();
			$scope.listingPagination.offset = 0;
			$scope.getListing(hotKey);
			$scope.show.closeSearchListing = false;
		}


		$scope.selectListing = (item) => {
			if (!$scope.show.listing || !$scope.show.listing.length) {
				return 0;
			}

			if (hotKey.key === hotKeysEnum.brochure) {
				const selectedDoc = $scope.show.listing.find(i => {
					return i.documentId === item.documentId && i.source === item.source;
				});
				selectedDoc.selected = !selectedDoc.selected;
			} else if (hotKey.key === hotKeysEnum.eform) {
				const selectedDoc = $scope.show.listing.find(i => i.id === item.id);
				selectedDoc.selected = !selectedDoc.selected;
			} else if (hotKey.key === hotKeysEnum.docusign) {
				const selectedDoc = $scope.show.listing.find(i => i.id === item.id);
				selectedDoc.selected = !selectedDoc.selected;
			}
			$scope.show.selectedListings = $scope.show.listing.filter(item => item.selected)
		}

		$scope.getTokenAndOpen = (linkCode, link) =>{
			HotKeyService.getReviewToken(linkCode).then(result => {
				if (result.status) {
					const token = result.data
					const url = `${link}?token=${token}`;
					console.log(url)
					$window.open(url, '_blank');
				}else{
					toaster.pop('error', result.message)
				}
			})
		}

		$scope.getListing = (hotKey) => {
			$scope.show.infoMessage = 'Loading...';
			$scope.show.listingsLoading = true;
			const lastIds = $scope.show.lastIds;
			const options = {
				skip:  $scope.listingPagination.offset || 0,
				limit: $scope.listingPagination.limit || 100,
				search: $scope.show.listingSearchParam,
				lastId: lastIds.length ? lastIds[lastIds.length - 1] : '0#0'
			}
			HotKeyService.getHotKeyListings(hotKey, options).then(result => {
				$scope.show.listingsLoading = false;
				if (result.status && result.data) {
					$scope.show.infoMessage = '';
					$scope.show.listing = result.data.data;
					const total = result.data.pagination.total;
					if(result.data.pagination.lastId){
						$scope.show.lastIds.push(result.data.pagination.lastId);
					}
					const {offset, limit} = $scope.listingPagination;
					$scope.listingPagination = {
						...$scope.listingPagination,
						total,
						from: total ? offset+1 : offset,
						to: (offset+limit > total ? total : (offset+limit))
					}
					if (!$scope.show.listing || !$scope.show.listing.length) {
						$scope.show.infoMessage = `No ${hotKey.name}s found`;
					}
				} else {
					$scope.show.infoMessage = result.message
				}
			})
		}
		if ($scope.show.showListing) {
			$scope.getListing(hotKey)
		} else {
			$scope.fetchLeads();
		}
	})