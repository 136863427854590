const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/leadRightCustomer.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/leadtimeline.html');

'use strict';
angular.module('bitnudgeApp')
    .component('leadRightCustomer', {
        bindings: {
            lead: '<',
            closeSelectedLead: '&',
            uploadFiles: '&',
            archiveLead: '&',
            editLead: '&',
            unassignedLeads: '<',
            isReferralsView: '<',
            isLostLeadView:'<'
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function ($scope, $rootScope,Auth, $mdDialog, leadsService, p0, toaster, $state) {
            console.log('I am heree in lead right customer')
            let ctrl = this;
            $scope.me = Auth.getCurrentUser();
            $scope.isAgentOnBoardingVerificationUser = $rootScope.isAgentOnBoardingVerificationUser;

            $scope.isSeniorManager = $rootScope.isSeniorManager;
            $scope.isFrontline = $rootScope.isFrontline;
            $scope.closeSelectedLead = function(){
                ctrl.closeSelectedLead();
            }
            this.$onChanges = (changes) => {
                if (changes.lead) {
                    $scope.selectedLead = changes.lead.currentValue;
                    showCustomerDetails($scope.selectedLead)
                }
                if (changes.unassignedLeads) {
                    $scope.unassignedLeads = changes.unassignedLeads.currentValue;
                }
                if (changes.isReferralsView) {
                    $scope.isReferralsView = changes.isReferralsView.currentValue;
                }
                if (changes.isLostLeadView) {
                    $scope.isLostLeadView = changes.isLostLeadView.currentValue;
                }
                let archiveAllowed = $rootScope.permissions.includes(p0.USER_PERMISSIONS.archiveLead)
                $scope.archiveLead = !$scope.isReferralsView && !$scope.unassignedLeads && !$scope.selectedLead.archived && archiveAllowed && !$scope.isAgentOnBoardingVerificationUser
            };
            this.openLeadDetails = function(lead) {
                console.log('lead: ', lead);
                console.log('$scope.selectedLead: ', $scope.selectedLead)
                const params = {leadId: lead._id, productId: lead.productId._id};
                $state.go('userFlow.editCustomer_v2', params);
            }
            this.showTimeline = function(lead){
                leadsService.getLeadHistory(lead._id).then(function(response){
                    let history = response.data.data;
                    $mdDialog.show({
                        templateUrl: autoImportNgTemplateLoaderTemplate2,
                        controller: 'leadsTimelineController',
                        parent: angular.element(document.body),
                        clickOutsideToClose: true,
                        fullscreen: true, // Only for -xs, -sm breakpoints.
                        resolve:{
                            lead: function(){
                                return lead;
                            },
                            history: function (){return history},
                            historiesLoaded: function (){return true}
                        }
                    });
                }).catch(function(err){
                    console.error('Lead get History API failed', err);
                    $scope.errors = err.data.errors;
                    toaster.pop('error',"Lead get History failed.");
                });
            }

            this.archiveLeadHelper = function(id, ev) {
                showConfirm(id, ev);
            };
            let showConfirm = function(id, ev) {
                ev.stopPropagation();
                let confirm = $mdDialog.confirm()
                    .title('Are you sure you want to archive this lead?')
                    .targetEvent(ev)
                    .ok('Yes')
                    .cancel('No');
                $mdDialog.show(confirm).then(function() {
                    leadsService.archiveLead(id).then(function(data){
                        toaster.pop('success', "Lead Archive", "Customer Archived.");
                        if(ctrl.archiveLead){
                            ctrl.archiveLead({id:id,ev: ev});
                        }
                    });
                });
            }
           
            const showCustomerDetails = (lead) => {
                $scope.contactInfo = lead.contact;
                if(!$scope.contactInfo.cui){
                    $scope.contactInfo.cui = lead.cui
                }
                if(lead.productId){
                    $scope.contactInfo.productName = lead.productId.name;
                    $scope.contactInfo.subProductName = lead.subProductName;
                }
                $scope.contactInfo.brn = lead.brn;
                const cuiLabel = p0.strings.CUI_LABEL && p0.strings.CUI_LABEL.value ? p0.strings.CUI_LABEL.value : 'CUI';
                $scope.contactKeys = [
                    {key:'brn', name:'BRN', type:'string'},
                    // {key:'cui', name:cuiLabel, type:'string'},
                    {key:'contactNumber', name:'Contact number', type:'contactNumber'},
                    {key:'company', name:'Company', type:'string'},
                    // {key:'homeContactNumbers', name:'Home contact number', type:'array'},
                    // {key:'officeContactNumbers', name:'Office contact number', type:'array'},
                    {key:'email', name:'Email', type:'string'},
                    // {key:'gender', name:'Gender', type:'string'},
                    // {key:'dateOfBirth', name:'Date of birth', type:'date'},
                    // {key:'customerAge', name:'Age', type:'string'},
                    // {key:'class', name:'Class', type:'string'},
                    // {key:'nationality', name:'Nationality', type:'string'},
                    // {key:'dnsStatus', name:'DNC status', type:'string'},
                    {key:'productName', name:'Product', type:'string'},
                    // {key:'subProductName', name:'Sub Product', type:'string'},
                    // {key:'city', name:'City', type:'string'},
                    // {key:'job', name:'Job', type:'string'},
                    // {key:'jobType', name:'Job Type', type:'string'},
                    // {key:'salaryTBN', name:'Salary Transfer Bank Name', type:'string'},
                    // {key:'productName', name:'Product', type:'string'},
                    // {key:'customerStatus', name:'Status of customer', type:'string'},
                    // {key:'type', name:'Type', type:'string'},
                    // {key:'subType', name:'Sub-Type', type:'string'},
                    {key:'leadStatus', name:'Lead Status', type:'string', context:'lead'},
                    // {key:'stage', name:'Stage', type:'string'},
                    // {key:'mainSalary', name:'Main Salary', type:'string'},
                    // {key:'housingAllowance', name:'Housing Allowance', type:'string'},
                    // {key:'deductions', name:'Deductions', type:'string'},
                    // {key:'totalSalary', name:'Total Salary', type:'string'},
                    // {key:'projectName', name:'Project Name', type:'string', context:'lead'},
                    // {key:'downPayment', name:'Down Payment', type:'string', context:'lead'},
                    // {key:'branch', name:'Branch', type:'string'},
                    // {key:'note', name:'Note', type:'string'},
                    // {key:'campaignName', name:'Campaign name', type:'string'},
                    // {key:'campaignCode', name:'Campaign code', type:'string'},
                    // {key:'source', name:'Source', type:'string'},        
                    // {key:'createdAt', name:'Creation date', type:'date'},
                    // {key:'expiryTime', name:'Expiry date', type:'date'},
                    // {key:'userId', name:'Employee ID', type:'string'},
                    // {key:'userName', name:'Name of sales employee', type:'string'},
                    // {key:'userContactNumber', name:'Phone number of sales employee', type:'string'},
                    // {key:'teamLeaderId', name:'Team Leader ID', type:'string'},
                    // {key:'teamName', name:'Team Leader Name', type:'string'},
                ];
            }

            $scope.openDMSViewer = function(lead, ev) {
                ev.stopPropagation();
                const documentTypes = []
                if(lead.attachedDocsCount){
                    const documents = lead.documents;
                    if(documents){
                        Object.keys(documents).forEach(item => {
                            documents[item].forEach( document =>{
                                if(!documentTypes.includes(document.uid)){
                                    documentTypes.push(document.uid)
                                }
                            })
                        })
                    }
                    if(documentTypes.length){
                        const dmsParameters = {
                            documentTypes, //In case of select mode
                            documents: [], //In case of approve mode send document Ids
                            isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                            isApproveMode: false, //for approve mode, open DMS with approve and reject option
                            isSelectMode: true, //for selection mode, open DMS with lead or ticket context i.e workflow
                            customer: {},
                            workflowId: lead.workflowId,
                            context: 'lead'
                        };
                        $rootScope.$broadcast('dms:openModalLMS', dmsParameters);
                    }
                }
            }
        }

    });
