"use strict";
const {USER_PERMISSIONS} = require('../types/permissions')
angular
	.module("bitnudgeApp")
	.service("Sidebar", function(Auth, $rootScope, p0) {

		this.menuItems = function() {
			const me = Auth.getCurrentUser();
			const PIPELINE_LABEL = p0.strings.PIPELINE_LABEL ? p0.strings.PIPELINE_LABEL.value : "Pipeline"
			var menuItems = [
				{
					name: "DASHBOARD",
					// source: '/menu_items/Dashboard_selected.svg',
					icon: "fa-tachometer s24",
					state: "userFlow.dashboard",
					params: {},
					permission_type: "DASHBOARD"
				}
				// { name: "CAMPAIGNS",      icon: "fa-calendar s24",   state: "campaigns",            params: { } }
			];
			if($rootScope.config.enableMGRDashboard){
				menuItems.push({
					name: "MGR DASHBOARD",
					icon: "fa-tachometer s24",
					state: "userFlow.mgrDashboard",
					params: {}
				})
			}
			//enabling leaderboard for demo
			if($rootScope.config.showLeaderBoards){
				menuItems.push({
					name: "LEADERBOARDS",
					icon: "fa-trophy s24",
					state: "userFlow.leaderboard",
					params: { player: me.roleType },
					permission_type: "LEADERBOARDS"
				})
			}
			if($rootScope.config.showAlerts){
				menuItems.push({
					name: "ALERTS",
					icon: "fa-bell s24",
					state: "userFlow.alerts",
					permission_type: "ALERTS"
				});
			}

			if ($rootScope.config.showNewsAndUpdates) {
				menuItems.push({
					name: "NEWS & UPDATES",
					icon: "fa-newspaper-o s24",
					state: "userFlow.newsAndUpdates",
					params: {},
					permission_type: "NEWS & UPDATES"
				});
			}

			if ($rootScope.config.showBadges) {
				menuItems.push({
					name: "BADGE CORNER",
					icon: "fa-star s24",
					state: "userFlow.badgeCorner",
					params: {},
					permission_type: "BADGE CORNER"
				});
			}
			if ($rootScope.config.customer360) {
				menuItems.push({
					name: "ACTIVITY",
					icon: "fa-calendar s24",
					state: "userFlow.activities",
					permission_type: "ACTIVITY"
				});
			}

			const viewLeadList = me.cloneUser ? $rootScope.permissions.includes(p0.USER_PERMISSIONS.viewLeadList): true

			if ($rootScope.config.lms) {
				if(!$rootScope.cloned || ($rootScope.cloned && viewLeadList)){
					menuItems.push({
						name: "LEADS",
						icon: "fa-list s24",
						state: "lms.listLead",
						params: { status: "active", quickMenu: true },
						permission_type: "LEADS"
					});
				}
				if ($rootScope.config.showPipeline) {
					menuItems.push({
						name: PIPELINE_LABEL.toUpperCase(),
						icon: "fa-bar-chart s24",
						state: "userFlow.pipeline",
						params: {},
						permission_type: PIPELINE_LABEL.toUpperCase()
					});
					// menuItems.push({name:"PIPELINE ANALYTICS",      icon:"fa-line-chart s24",   state:"userFlow.pipelineAnalytics",       params:{}});
				}
			}

			if (true) {
				menuItems.push({
					name: "CUSTOMERS",
					icon: "fa-user s24",
					state: "lms.listCustomerNew",
					params: { status: "active", quickMenu: true },
					permission_type: "CUSTOMERS"
				});
			}

			if(true) {
				menuItems.push({
					name: "Geo360",
					icon: "fa-solid fa-location-arrow s24",
					state: "lms.geo360",
					params: {},
					permission_type: "NEWS & UPDATES"
				});
			}

			if ($rootScope.config.tickets) {
				const ticket = p0.strings.TICKET
					? p0.strings.TICKET.value + "s"
					: "Tickets";
				menuItems.push({
					name: ticket,
					icon: "fa-ticket s24",
					state: "lms.tickets",
					params: {},
					permission_type: USER_PERMISSIONS.viewServiceRequestList
				});
			}

			if ($rootScope.config.showAnalytics) {
				menuItems.push({
					name: "ANALYTICS",
					icon: "fa-line-chart s24",
					state: "userFlow.analytics",
					params: { tabName: "otherAnalytics", myComparison: true },
					permission_type: "ANALYTICS"
				});
			}

			if ($rootScope.config.showGameplan) {
				//DEMO
				menuItems.push({
					name: "GAMEPLAN",
					icon: "fa-gamepad s24",
					state: "userFlow.gameplan",
					params: { selectedPlayerId: me._id, showGPNotMade: true },
					permission_type: "GAMEPLAN"
				});
			}

			if ($rootScope.isManager && $rootScope.config.showHuddleboard) {
				menuItems.push({
					name: "HUDDLEBOARD",
					icon: "fa-list s24",
					state: "lms.huddleBoard",
					permission_type: "HUDDLEBOARD",
					params: {
						kpiId: $rootScope.config.showOverallHuddleboard
							? "overall"
							: (p0.myKpis && p0.myKpis.length && p0.myKpis[0]._id || 'overall')
					}
				});
			}

			if ($rootScope.config.showHeatMapOnWeb) {
				menuItems.push({
					name: "HEATMAP",
					icon: "fa-sitemap s24",
					state: "lms.heatmap",
					params: { userId: me._id, selectedAction: "scorecard" },
					permission_type: "HEATMAP"
				});
			}

			if ($rootScope.isPlayer && $rootScope.config.showPlaybook) {
				menuItems.push({
					name: "PLAYBOOK",
					icon: "fa-book s24",
					state: "userFlow.myPlayBook",
					params: {},
					permission_type: "PLAYBOOK"
				});

				if ($rootScope.config.gofunnel) {
					menuItems.push({
						name: "GOFUNNEL",
						icon: "fa-graduation-cap s24",
						href: "http://bitnudge.com:8080/play_game",
						params: {},
						permission_type: "GOFUNNEL"
					});
				}
			}


			if ($rootScope.config.campaigns) {
				//TODO: Enable correct campaigns view
				menuItems.push({
					name: "CONTESTS",
					icon: "fa-calendar s24",
					state: "userFlow.campaignsnew",
					params: {},
					permission_type: "CONTESTS"
				});
			}

			if ($rootScope.config.showCompetitiveInfo) {
				menuItems.push({
					name: "Competitive Info",
					icon: "fa-line-chart s24",
					state: "userFlow.competitive",
					params: {},
					permission_type: "Competitive Info"
				});
			}
			if($rootScope.isAgentOnBoardingVerificationUser){
				menuItems = [{
					name: "LEADS",
					icon: "fa-list s24",
					state: "lms.listLead",
					params: { status: "active", quickMenu: true },
					permission_type: "LEADS"
				}]
			}
			// currently i have allowed all items in permissions but these can be controlled
			// if we get data from the backend in permissions we can then ignore these concat statements
			$rootScope.permissions = $rootScope.permissions || []
			$rootScope.permissions = $rootScope.permissions.concat([
				"DASHBOARD",
				"LEADERBOARDS",
				"ALERTS",
				"NEWS & UPDATES",
				"BADGE CORNER",
				"CUSTOMERS",
				"ACTIVITY",
				"LEADS", PIPELINE_LABEL.toUpperCase(),
				"ANALYTICS",
				"GAMEPLAN",
				"HUDDLEBOARD",
				"HEATMAP",
				"PLAYBOOK",
				"GOFUNNEL",
				"CONTESTS",
				"Competitive Info"
			])

			if($rootScope.cloned) {
				menuItems = menuItems.filter((menuItem)=>{
					let result = $rootScope["permissions"].includes(menuItem.permission_type)
					return !!result;
				})
			}
			return menuItems;
		};

		this.getAppUrl = function() {
			let appUrl = document.location.origin;
			let subfolder = p0.config.subfolder;
			if (subfolder) {
				if (appUrl[appUrl.length - 1] !== "/") {
					appUrl += "/";
				}
				if (subfolder[0] == "/") {
					subfolder = subfolder.slice(1);
				}
				appUrl += subfolder;
			}
			return appUrl;
		};
	});
