const autoImportNgTemplateLoaderTemplate1 = require('../html/conversionRatioInfo.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/pipelineInfoModal.html');

'use strict';
angular.module('bitnudgeApp')
	.controller('pipelineAnalyticsCtrl', function ($scope, p0, Auth, $q, $mdDialog, PipelineService, $stateParams, $state, $http) {

		let self = this;

		this.init = function () {
			$scope.isFrontline = false;
			$scope.selected = {};
			$scope.selectedIndex = 0;
			$scope.me = Auth.getCurrentUser();
			$scope.showReporteeTable = false;
			$scope.isLeadCountViewSelected = false;
			$scope.currentMonthlyCycleId = p0.currentMonthlyCycle._id;
			$scope.isCumulativeGraph = false;
			$scope.selectedStateIndex = 0;
			$scope.selectedAttribute;
			if ($stateParams.userId) {
				$http.post('api/users/getUserById',{userId: $stateParams.userId}).then(function (user) {
					user = user.data;
					$scope.selectedUser = user;
				})
			}
			if ($scope.me.systemRole == 'frontline') {
				$scope.isFrontline = true;
				$scope.selected.user = $scope.me;
				$scope.selected.jobRole = $scope.me.organizationalTag.jobRole;
				$scope.selected.division = p0.myDivisions[0];
				$scope.selected.playType = p0.myPlayTypes[0];
				$scope.selected.products = p0.myProducts;
				self.setKpiDropdown(p0.myLeadKpis);
			}
		}

		$scope.onReporteeChange = function (reportee) {
			$scope.selected.user = reportee;
			$scope.selected.jobRole = $scope.selected.user.organizationalTag.jobRole;
			$scope.isSeniorManager = reportee.roleType == 'seniorManager';
			if (self.selectedDivision._id == 'all' && self.selectedPlayType._id == 'all') {
				self.setKpiDropdown(p0.myLeadKpis);
			} else if (self.selectedDivision._id != 'all' && self.selectedPlayType._id == 'all') {
				var kpis = p0.getLeadKpisOfPlayTypes(self.selectedDivision.playTypes)
				self.setKpiDropdown(p0.myLeadKpis);
			} else {
				self.setKpiDropdown(p0.myLeadKpis);
			}
		};

		$scope.onDivisionChange = function (division) {
			self.selectedDivision = division;
		};

		$scope.onPlayTypeChange = function (playType) {
			self.selectedPlayType = playType;
		};

		$scope.incrementCounter = function () {
			var columnIndex = $scope.selectedIndex;
			var length = $scope.pipelineData.stages.length;
			columnIndex = (columnIndex + 1) % length;
			$scope.onColumnClick(columnIndex);
		}
		$scope.decrementCounter = function () {
			var columnIndex = $scope.selectedIndex;
			var length = $scope.pipelineData.stages.length;
			if (columnIndex === 0)
				columnIndex = $scope.pipelineData.stages.length;
			columnIndex = (columnIndex - 1) % length;
			$scope.onColumnClick(columnIndex);
		}

		$scope.onColumnClick = function (columnIndex) {
			$scope.selectedIndex = columnIndex;
			if (columnIndex === -1)
				return;
			if ($scope.stages) {
				$scope.selectedStage = $scope.stages[columnIndex];
			}
		}

		$scope.onKpiChange = function (selectedKpi) {
			$scope.selectedKpi = selectedKpi;
			self.getPipelineData($scope.selected.user._id, $scope.currentMonthlyCycleId, $scope.selectedKpi._id);
		}

		$scope.showConversionRatioInfo = function (index) {

			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: function ($scope, conversionRatio, timeSpent, firstStage, nextStage) {
					$scope.conversionRatio = conversionRatio;
					$scope.timeSpent = timeSpent;
					$scope.firstStage = firstStage;
					$scope.nextStage = nextStage;
					$scope.onClose = function () {
						$mdDialog.hide();
					}
				},
				clickOutsideToClose: true,
				resolve: {
					timeSpent: function () {
						return $scope.pipelineData.stages[index].timeToAct.value;
					},
					conversionRatio: function () {
						return $scope.pipelineData.stages[index].conversionRatioNext.value;
					},
					firstStage: function () {
						return $scope.pipelineData.stages[index].stage.name;
					},
					nextStage: function () {
						return $scope.pipelineData.stages[index + 1].stage.name;
					}
				}
			});

		}

		$scope.showPipelineInfo = function () {
			var startSubmissionStage = $scope.selectedKpi.submissionStates[0];
			var lastStage = $scope.selectedKpi.submissionStates[$scope.selectedKpi.submissionStates.length - 1];
			var netPipelineFormula = "";
			var statesLength = $scope.selectedKpi.submissionStates.length;
			$scope.selectedKpi.submissionStates.forEach(function (stage, index) {
				netPipelineFormula += "(Conversion Ratio of " + stage.name + " to " + lastStage.name + " stage * Its Pipeline Value)";
				if (index < statesLength - 1) {
					netPipelineFormula += " + ";
				}
			});

			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate2,
				controller: function ($scope, firstStage, lastStage, netPipelineFormula) {
					$scope.unit = p0.config.CURRENCY_UNIT || "IDR";
					$scope.PIPELINE_LABEL = p0.strings.PIPELINE_LABEL ? p0.strings.PIPELINE_LABEL.value : "Pipeline"
					$scope.firstStage = firstStage;
					$scope.lastStage = lastStage;
					$scope.netPipelineFormula = netPipelineFormula;
					$scope.onClose = function () {
						$mdDialog.hide();
					}
				},
				clickOutsideToClose: true,
				resolve: {
					firstStage: function () {
						return startSubmissionStage;
					},
					lastStage: function () {
						return lastStage;
					},
					netPipelineFormula: function () {
						return netPipelineFormula;
					},
				}
			});
		};


		function getUserKpis(playTypeIds) {
			var kpis = [];
			_.forEach(playTypeIds, function (playTypeId) {
				var playType = _.find(p0.allPlayTypes, {
					_id: playTypeId
				});
				if (playType) kpis = kpis.concat(playType.leadKpis);
			});
			kpis = _.uniqBy(kpis, '_id');
			return kpis;
		}

		this.setKpiDropdown = function (kpis) {
			$scope.kpis = _.cloneDeep(kpis);
			$scope.selectedKpi = $scope.kpis[0];
			$scope.showReporteeTable = false;
			self.getPipelineData($scope.selected.user._id, $scope.currentMonthlyCycleId, $scope.selectedKpi._id);
		}

		this.getPipelineData = function (userId, cycleId, kpiId) {
			if (!(userId && cycleId && kpiId)) {
				return;
			}
			if ($scope.selectedKpi.unit == '#') {
				$scope.isLeadCountViewSelected = true;
			}
			return PipelineService.getPipelineData(userId, cycleId, kpiId)
				.then(function (pipelineData) {
					$scope.pipelineData = pipelineData.data.data[kpiId];
					$scope.onColumnClick(0);
					$scope.createPipelineGraph($scope.isLeadCountViewSelected);
					if ($scope.me.systemRole == 'frontline') {
						$scope.showReporteeTable = false;
						return;
					}
					return $http.post('/api/users/me/children', {userId})
				})
				.then(function (reportees) {
					if (!reportees)
						return;
					reportees = reportees.data;
					if (reportees.length < 1) {
						$scope.showReporteeTable = false;
						return;
					}
					reportees = _.filter(reportees, function (reportee) {
						return _.includes(reportee.kpis, kpiId);
					});
					if (reportees.length < 1) {
						$scope.showReporteeTable = false;
						return;
					}
					$scope.showReporteeTable = true;
					var reporteeIds = _.map(reportees, '_id');
					var reporteePipelineData = [];
					var cycleId = $scope.currentMonthlyCycleId;

					_.each(reporteeIds, function (reporteeId) {
						if (!reporteeId) return;
						reporteePipelineData.push(PipelineService.getPipelineData(reporteeId, cycleId, kpiId));
					});
					$scope.reporteeData = {};
					return $q.all(reporteePipelineData)
						.then(function (pipelineData) {
							_.each(reporteeIds, function (reporteeId, index) {
								$scope.reporteeData[reporteeId] = pipelineData[index].data.data;
							});
							self.mapReporteeData(reportees, $scope.reporteeData);
							self.mapStages($scope.pipelineData);
						}, function (error) {
							console.error("Error in fetching pipeline scorecard data for reportees of a user", error);
						})
						.catch(function (error) {
							console.error("Erorr receiving reportee of user", error);
						})
				}).catch(function (err) {
					console.error(err, '--- I received error');
				})


		}

		this.mapStages = function (pipelineData) {
			$scope.stages = $scope.selectedKpi.submissionStates.map(function (state, index) {
				return {
					_id: index,
					name: state.name
				}
			});
			$scope.selectedStage = $scope.stages[0];
		}

		var createPipelineGraph = function (pipelineData) {
			if (!pipelineData || !pipelineData.stages) {
				$scope.totalConversionRatio = 0;
				$scope.totalPipelineValue = 0;
			}
			$scope.pipelineColumns = self.calculatePipelineColumnHeights(pipelineData.stages);
			$scope.totalConversionRatio = $scope.pipelineData.totalConversionRatio.toFixed(1) || '0';
			$scope.totalPipelineValue = $scope.pipelineData.totalPipelineValue || 0;
			$scope.conversionRatios = self.calculateFilterPercentage(pipelineData.stages);
			$scope.yAxisLabels = self.calculateYAxis(pipelineData.stages);
		}

		this.calculatePipelineColumnHeights = function (pipelineItems) {
			var pipelineItems = angular.copy(pipelineItems);
			var highestValue = self.getMaxCountValue(pipelineItems);
			var attribute = $scope.selectedAttribute;

			return _.map(pipelineItems, function (item, index) {
				var height = highestValue !== 0 ? self.getHeightValue(item[attribute].value, highestValue) : 0;
				var top = 100 - height;
				item.css = {
					height: height + '%',
					top: top + 40 + '%',
					bottom: '-10%'
				}

				item.shortName = item.stage.name.indexOf('(') !== -1 ? item.stage.name.substring(0, item.stage.name.indexOf('(')).trim() : item.stage.name;
				if (item.shortName.split(' ').length > 1) {
					var words = item.shortName.split(' ');
					item.names = [];
					item.names = words.length % 2 === 0 ?
						[words.slice(0, words.length / 2).join(' '), words.slice((words.length / 2), words.length).join(' ')] :
						[words.slice(0, words.length / 2 + 1).join(' '), words.slice((words.length / 2) + 1, words.length).join(' ')]
				} else {
					item.names = [item.shortName];
				}
				return item;
			});
		}

		this.getMaxCountValue = function (pipelineItems) {
			if (!pipelineItems || !pipelineItems.length) return 0;
			var attribute = $scope.selectedAttribute;
			if (!$scope.isCumulativeGraph) {
				var value = pipelineItems.reduce(function (first, second) {
					if (!second[attribute].value) {
						return first;
					} else {
						return Math.max(first, second[attribute].value);
					}
				}, pipelineItems[0][attribute].value || 0)
				return value;
			}
		}

		this.getHeightValue = function (value, highestValue) {
			var height = (parseFloat(value / highestValue)) * 100;
			height = Number(height.toFixed(1));
			return height;
		}

		this.calculateFilterPercentage = function (pipelineItems) {

			var result = pipelineItems.map(function (item, index) {
				return {
					percentage: item.conversionRatioNext.value,
					timeToAct: item.timeToAct.value
				}
			});
			result.pop();
			return result;
		}

		this.calculateYAxis = function (pipelineData) {
			var attribute = $scope.selectedAttribute;
			var highestValue = self.getMaxCountValue(pipelineData);
			var chartsMaxValue = highestValue / 0.8481012658227848;
			var yAxis = [6, 5, 4, 3, 2, 1, 0];
			var yAxisValues = _.map(yAxis, function (key) {
				var value = (chartsMaxValue * 57.67529074667116 * key) / 395;
				value = value.toFixed().length > 2 ? value.toFixed() : value.toFixed(1);
				return $scope.isLeadCountViewSelected ? value : value;
			});
			yAxisValues.pop();
			return yAxisValues;
		}

		this.mapUser = function (user, userReporteeData, stages) {
			var swotColor;
			if (userReporteeData.percentageCompleted < 50) {
				swotColor = '#f7e5de';
			} else if (userReporteeData.percentageCompleted >= 50 && userReporteeData.percentageCompleted < 100) {
				swotColor = '#fff5d9';
			} else {
				swotColor = "#e1e8df";
			}
			return {
				reportee: user,
				achievedValue: userReporteeData.achievedValue,
				percentageCompleted: userReporteeData.percentageCompleted,
				totalPipelineValue: (userReporteeData && userReporteeData.totalPipelineValue) || 0,
				totalConversionRatio: (userReporteeData && userReporteeData.totalConversionRatio) || 0,
				pipelinePercentageCompleted: userReporteeData.pipelinePercentageCompleted,
				swotColor: swotColor,
				states: _.map(stages, function (state) {
					var stateInReportee = _.find(userReporteeData.stages, {
						stage: state
					});
					if (stateInReportee && stateInReportee.pipelineValue) {
						stateInReportee.pipelineValue.value = stateInReportee.pipelineValue.value / $scope.selectedKpi.denom
					}
					return stateInReportee ? stateInReportee : {
						stage: state,
						leadCount: {
							value: 0
						},
						conversionRatioFinal: {
							value: 0
						},
						pipelineValue: {
							value: 0
						},
						percentageCompleted: state.percentageCompleted
					}
				})
			}
		}

		this.mapReporteeData = function (reportees, reporteeData) {
			var kpiId = $scope.selectedKpi._id;
			$scope.reporteePipelineData = [];
			var stages = $scope.selectedKpi.submissionStates.map(function (stage) {
				return stage.name;
			})
			_.each(reportees, function (reportee) {
				var userReporteeData = self.mapUser(reportee, $scope.reporteeData[reportee._id][kpiId], stages);
				$scope.reporteePipelineData.push(userReporteeData);
			});
		}


		$scope.createPipelineGraph = function (isLeadCountViewSelected) {
			$scope.selectedAttribute = isLeadCountViewSelected ? 'leadCount' : 'pipelineValue';
			createPipelineGraph($scope.pipelineData);
		}


        //new pipeline implementation

		$scope.averageLengthSalesAll = [{
				stage: "Created",
				data: 30,
				color: "#2574B2"
			},
			{
				stage: "Interaction",
				data: 100,
				color: "#6adcf1"
			},
			{
				stage: "Documenting",
				data: 10,
				color: "#a9cee1"
			},
			{
				stage: "Submission",
				data: 40,
				color: "#77bc01"
			},
			{
				stage: "Closed",
				data: 20,
				color: "#c74e24"
			},
		]
		$scope.averageLengthSalesUsers = [{
				name: "User 1",
				averageTotal: 105,
				role: "frontline",
				percentageRatio: 20,
				stages: [{
						stage: "Created",
						data: 10,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 20,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 30,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 40,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 5,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 2",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 3",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 4",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 5",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 6",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			},
			{
				name: "User 7",
				averageTotal: 170,
				role: "frontline",
				percentageRatio: 40,
				stages: [{
						stage: "Created",
						data: 40,
						color: "#2574B2"
					},
					{
						stage: "Interaction",
						data: 10,
						color: "#6adcf1"
					},
					{
						stage: "Documenting",
						data: 40,
						color: "#a9cee1"
					},
					{
						stage: "Submission",
						data: 20,
						color: "#77bc01"
					},
					{
						stage: "Closed",
						data: 60,
						color: "#c74e24"
					}
				]
			}
        ]
        
        $scope.averageLengthLineChart = [ {
            averageCount : 10,
            pipelineDate : "2018-01-01"
        },
            {
                averageCount : 50,
                pipelineDate : "2018-02-01"
            },
            {
                averageCount : 20,
                pipelineDate : "2018-03-01"
            },{
                averageCount : 50,
                pipelineDate : "2018-04-01"
            },
            {
                averageCount : 20,
                pipelineDate : "2018-05-01"
            },{
                averageCount : 10,
                pipelineDate : "2018-06-01"
            },
                {
                    averageCount : 30,
                    pipelineDate : "2018-07-01"
                },
                {
                    averageCount : 70,
                    pipelineDate : "2018-08-01"
                }
        ]

        $scope.funnelData = [
            {
                "sales_process" : "Leads",
                "value" : 70
            },
            {
                "sales_process" : "Phone Calls",
                "value" : 40
            },
            {
                "sales_process" : "Negotiations",
                "value" : 26
            },
            {
                "sales_process" : "Final Closure",
                "value" : 15
            },
        ]

        var data = [
            ['Created',      2000, '#2574B2'],
			['Interaction', 1800,  '#6adcf1'],
			['Documenting', 1000,  '#a9cee1'],
			['Submission',     500,  '#77bc01'],
			['Closed',     300,  '#c74e24'],

	];

		//TODO send this logic to the funnelChart 
		const options = {
			block: {
				dynamicHeight: true,
				minHeight: 50, 
				highlight: true,
				onItemClick: function(d, i) {
				alert('<' + d.label + '> selected.');
			}
			},
			chart:{
				width: 350,
				height: (window.innerHeight - 120)/2,
				animate: 500,
				curve:{
					enabled : true,
					height: 30
				},
				bottomPinch:1,
				bottomWidth : 1/3,
			},
			tooltip: {
				enabled: true,
			},
			label: {
				format: '{l}\n{f}',
			},
			events:{
				click:
				{
					block:{

						onItemClick: function(d, i) {
							alert('<' + d.label + '> selected.');
						}
					}
				}
			}
		};
		
		var chart = new D3Funnel('#funnelContainer');
		
		chart.draw(data, options);

	
	
	// var canvas = d3.select('#funnelContainer')
	// 					.append('svg')
	// 					.attr({'width':500,'height':450});
	// 	var area = d3.svg.area()
	// 					.interpolate('linear-closed')
	// 					.x1(function(d){ return d.x; })
	// 					.y1(function(d){ return d.y; });
	// 	var max1 = 400, max2 = 70, may = 200;
	// 	var colors = d3.scale.category10();
	// 	var yx = d3.scale.linear()
	// 					.domain([0,may])
	// 					.range([0,(may*1.15)]);
	// 	var px = function(d,i){ return d+(10*i); };
	// 	var nx = function(d,i){ return d-(10*i); };
	// 	var layers = d3.range(8).map(function(val,ind){
	// 	  return {"values":[
	// 	    {'x':px(max2,val),'y':yx(may)},
	// 	    {'x':nx(max1,val),'y':yx(may)},
	// 	    {'x':nx(px(max1,1),val),'y':may},
	// 	    {'x':px(nx(max2,1),val),'y':may}
	// 	  ]};
	// 	});
	// 	var layerTrans = d3.range(8).map(function(val,ind){
	// 	  return {"values":[
	// 	    {'x':px(0,val),'y':yx(0)},
	// 	    {'x':nx(0,val),'y':yx(0)},
	// 	    {'x':nx(px(0,1),val),'y':0},
	// 	    {'x':px(nx(0,1),val),'y':0}
	// 	  ]};
	// 	});
	// 	var stack = d3.layout.stack()
	// 					.offset('wiggle')
	// 					.values(function(d){ return d.values; });
	// 	var group = canvas.append('g')
	// 					.attr('transform','translate(3,-191)');

	// 	var path = group.selectAll('path')
	// 					.data(stack(layers))
	// 					.enter()
	// 					.append('path')
	// 					.attr('transform',function(d,i){ return 'translate(3,'+(i*42)+')'; })
	// 					.attr('d',function(d){ return area(d.values); })
	// 					.style({'fill':function(d,i){ return colors(i); },'stroke':'#fff','stroke-width':1});
    

		this.init();

	});
