const autoImportNgTemplateLoaderTemplate1 = require('../../html/rejectDocModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../../html/leads/interactions/addFollowUp.html');
const autoImportNgTemplateLoaderTemplate3 = require('../../html/leads/editLeadInfo.html');
const autoImportNgTemplateLoaderTemplate4 = require('../../html/leads/addLeadNotes.html');
const autoImportNgTemplateLoaderTemplate5 = require('../../html/lms/reassignLeadModal.html');
const autoImportNgTemplateLoaderTemplate6 = require('../../html/lms/leadAdditionalInfo.html');

'use strict';

angular.module('bitnudgeApp').controller('editLeadCustomerCtrl_v2', function($rootScope, $scope, Auth, p0, $state, $stateParams,
    leadsService, $timeout, toaster, $window, $filter, WorkflowService, DocumentService, $mdDialog, HotKeyService) {
    console.log('I am here 989898')
    let me = Auth.getCurrentUser();
    $scope.loggedInUser = me;
    let onCloseListener = null;
    let selectedRoleUsers = [];
    $scope.data = {};
    $scope.interactionService = { show: true, isRequired: false };
    $scope.params = {};
    $scope.mindate = new Date();
    let roleTypes = ["Loan Advisors"];
    $scope.showInfoRHS = false;
    $scope.secondTopTabs = {
        EFORMS: 'eForms',
        INTERACTIONS: 'interactions',
        ALL: 'all'
    }
    $scope.topTabs = {
        USERACTION: 'userAction',
        LEADHISTORY: 'leadHistory'
    }
    $scope.rhsTabs={
        EFORMTAB: 'eFormTab',
        CONTENTSHARED: 'contentSharedTab'
    }
    $scope.rhbActiveTab = null
    let allRoleTypes = []
    $scope.strings = p0.strings;
    const cuiLabel = p0.strings.CUI_LABEL && p0.strings.CUI_LABEL.value ? p0.strings.CUI_LABEL.value : 'CUI';
    $scope.topActiveTab = 'leadHistory';
    $scope.secondActiveTab = 'all'
    $scope.contactKeys = [
		{key:'brn', name:'BRN', type:'string'},
		{key:'cui', name:'Customer ID Number', type:'string'},
		{key:'name', name:'Name', type:'string'},
		{key:'contactNumber', name:'Contact number', type:'contactNumber'},
		// {key:'homeContactNumbers', name:'Home contact number', type:'array'},
		// {key:'officeContactNumbers', name:'Office contact number', type:'array'},
		{key:'email', name:'Email', type:'string'},
		{key:'gender', name:'Gender', type:'string'},
		{key:'dateOfBirth', name:'Date of birth', type:'date'},
		{key:'customerAge', name:'Age', type:'string'},
		{key:'city', name:'City', type:'string'},
		{key:'job', name:'Job', type:'string'},
        {key:'jobType', name:'Job Type', type:'string'},
        {key:'salaryTBN', name:'Salary Transfer Bank Name', type:'string'},
		{key:'productName', name:'Product', type:'string'},
		{key:'customerStatus', name:'Status of customer', type:'string'},
		// {key:'type', name:'Type', type:'string'},
		// {key:'subType', name:'Sub-Type', type:'string'},
		{key:'status', name:'Lead Status', type:'string'},
        {key:'stage', name:'Stage', type:'string'},
        {key:'mainSalary', name:'Main Salary', type:'string'},
        {key:'housingAllowance', name:'Housing Allowance', type:'string'},
        {key:'deductions', name:'Deductions', type:'string'},
        {key:'totalSalary', name:'Total Salary', type:'string'},
        {key:'projectName', name:'Project Name', type:'string', context:'lead'},
        {key:'downPayment', name:'Down Payment', type:'string', context:'lead'},
        {key:'branch', name:'Branch', type:'string'},
        {key:'note', name:'Note', type:'string'},
		// {key:'campaignName', name:'Campaign name', type:'string'},
		// {key:'campaignCode', name:'Campaign code', type:'string'},
		{key:'source', name:'Source', type:'string'},        
		{key:'createdAt', name:'Creation date', type:'date'},
		// {key:'expiryTime', name:'Expiry date', type:'date'},
		{key:'userId', name:'Employee ID', type:'string'},
        {key:'userName', name:'Name of sales employee', type:'string'},
        {key:'userContactNumber', name:'Phone number of sales employee', type:'string'},
		{key:'teamLeaderId', name:'Team Leader ID', type:'string'},
        {key:'teamName', name:'Team Leader Name', type:'string'},
	];

    const formatLeadHistories = (histories) => {
        $scope.formattedHistories= []
        let tempFormat = histories.reduce(function (r, a) {
            let temp = a.timestamp;
            temp = new Date(temp)
            temp = temp.setHours(0,0,0,0);
            temp = new Date(temp)
            r[temp] = r[temp] || [];
            r[temp].push(a);
            return r;
        }, Object.create(null));
        const historyTypes = ['interaction', 'statusChange']
        Object.keys(tempFormat).forEach( historyKey => {
            let obj = {
                timestamp: historyKey,
                histories : tempFormat[historyKey].filter(h => historyTypes.includes(h.historyType))
            }
            if(obj.histories && obj.histories.length){
                $scope.formattedHistories.push(obj)
            }
        })
    }

    let getContactInfo = function(lead) {
        let camelCase = $filter('camelCaseToHuman');
        $scope.contactInfo = lead.contact;
        if (lead.productId && lead.productId.name) {
            $scope.contactInfo.productName = lead.productId.name;
        }
        if (lead.subProduct) {
            $scope.contactInfo.subProduct = camelCase(lead.subProduct);
        }
        $scope.contactInfo.type = camelCase(lead.leadType);
        $scope.contactInfo.subType = camelCase(lead.leadSubType);
        $scope.contactInfo.status = lead.leadStatus;
        $scope.contactInfo.stage = lead.leadStage;
		$scope.contactInfo.campaignName = lead.campaignName;
        $scope.contactInfo.campaignCode = lead.campaignCode;
		$scope.contactInfo.createdAt = lead.createdAt;
		$scope.contactInfo.expiryTime = lead.expiryTime;
        $scope.contactInfo.brn = lead.brn;
		$scope.contactInfo.userId = lead.userId.email;
        $scope.contactInfo.userName = lead.userId.name;
        $scope.contactInfo.userContactNumber = lead.userId.contactNumber;
		$scope.contactInfo.teamLeaderId = lead.teamLeaderId;
		$scope.contactInfo.teamName = lead.teamName;
        $scope.contactInfo.source = lead.leadSourceLabel;
        $scope.contactInfo.downPayment = lead.downPayment;
        $scope.contactInfo.branch = lead.branch;
        $scope.contactInfo.note = lead.note;
        $scope.contactInfo.projectName = lead.projectName;
    };

    const registerForEvents = function() {
        $scope.$on('workflow:lead:addPrerequisite', function(event, opt) {
            const { existing, data } = opt;
            const {
                contact,
                workflowId,
                _id,
                userId,
                productId,
                subProduct,
                leadStatusId,
                leadStage
            } = $scope.data.lead;
			const { state } = $scope.data;
            const payload = {
                customer: { name: contact.name, contactNumber: contact.contactNumber.countryCode+''+contact.contactNumber.number },
                existing,
                docTypeId: (data && data._id) ? data._id : '',
                newPreqParams: data, // New document type params are saved here...
                userId: userId._id,
                workflowId,
                context: 'lead',
                productId: productId._id,
                subProduct,
                workflowConsumerId: _id,
                stateUid: leadStatusId,
                stageUid: leadStage,
				toStateUid: state.state,
            };
            WorkflowService.addPrerequisite(payload).then(function(response) {
                if (response.data.status) {
                    toaster.pop('success', "Lead", "Request for additional information is successful.");
                } else {
                    toaster.pop('error', "Lead", "Request for additional information is failed. Please ask administrator to recheck the workflow permissions.");
                }
            }, function(err) {
                const message = 'Add prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            })
        });

        $scope.$on('workflow:lead:removePrerequisite', function(event, opt) {
            const { item } = opt;
            const { workflowId } = $scope.data.lead;
            WorkflowService.removePrerequisite(workflowId, item._id, item.reference.to).then(function(response) {
                if (response.data.status) {
                    const prereq = $scope.data.state.prerequisites;
                    prereq.splice(prereq.findIndex(p => p._id === item._id), 1);
                    toaster.pop('success', "Lead", "Additional prerequisite is removed.");
                } else {
                    toaster.pop('error', "Lead", "Failed to remove additional prerequisite.");
                }
            }, function(err) {
                const message = 'Remove prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            });
        });

        $scope.$on('workflow:lead:document', function(event, opt) {
            const { item } = opt;
            const { workflowId, contact, cui } = $scope.data.lead;
            const isApproved = item.name.startsWith("approve");
            const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
            const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

            let documentTypes = otherPrerequisites.map(p => p.typeParam);
            documentTypes.unshift(item.typeParam);

            let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
            const refId = item.selectedDocId || item.rejectedDocId;
            if (refId) {
                documentIds.unshift(refId);
            }
            const dmsParameters = {
                documentTypes, //In case of select mode
                documents: documentIds, //In case of approve mode send document Ids
                isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
                isSelectMode: !isApproved, //for selection mode, open DMS with lead or ticket context i.e workflow
                customer: {},
                workflowId,
                //cui: cui || contact.cui,
                context: 'lead'
            };
            $rootScope.$broadcast('dms:openModal', dmsParameters);

            if (onCloseListener) onCloseListener();
            onCloseListener = $scope.$on("dms:onCloseModal", () => {
                getLeadHistoryFunc();
                refreshDocumentPrereqStatus();
            });

        });

        $scope.$on('workflow:lead:approveDirect', function(event, opt) {
            const { item } = opt;
            const { documentTypeId, selectedDocId, approved, rejected } = item;
            const { workflowId } = $scope.data.lead;
            if (rejected) {
                toaster.pop('info', "Rejected document can't be approved.");
                return;
            }
            if (approved) {
                toaster.pop('info', "Document is already approved.");
                return;
            }
            const data = { docId: selectedDocId, documentTypeId, workflowId };
            DocumentService.approve(data)
                .then(result => {
                    const { status, data, errors } = result;
                    if (status) {
                        toaster.pop("success", data.message);
                        getLeadHistoryFunc();
                        refreshDocumentPrereqStatus();
                    } else {
                        toaster.pop("error", "Document not approved", errors[0].message);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toaster.pop("error", "Error in document approval", err.message);
                });
        });

        $scope.$on('workflow:lead:rejectDirect', function(event, opt) {
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "RejectDocCtrl",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(result => {
                    if (result) {
                        const { item } = opt;
                        const { documentTypeId, selectedDocId, approved, rejected } = item;
                        const { workflowId } = $scope.data.lead;
                        let { comment, reason } = result;
                        if (!comment) {
                            comment = reason.title;
                        }
                        const data = {
                            docId: selectedDocId,
                            documentTypeId,
                            workflowId,
                            notes: comment,
                            label: reason.title
                        };
                        DocumentService.reject(data).then(result => {
                                const { status, data, errors } = result;
                                if (status) {
                                    toaster.pop("success", data.message);
                                    getLeadHistoryFunc();
                                    refreshDocumentPrereqStatus();
                                } else {
                                    toaster.pop("error", "Document not rejected", errors[0].message);
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                toaster.pop("error", "Error in document rejected", err.message);
                            });
                    }
                });
        });
    };

    const refreshDocumentPrereqStatus = () => {
        const { workflowId } = $scope.data.lead;
        leadsService.getDocumentsCache('lead', workflowId).then(function(response) {
            response = response.data;
            const { status, data, errors } = response;
            if (status) {
                DocumentService.updateDocumentPrerequisites($scope.data.state.prerequisites, data);
            }
        }).catch((err) => {
            console.error('Get documents cache failed', err);
        });
    };

    const getLeadHistoryFunc = () => {
        $scope.loadingHistoryInfo = true
        leadsService.getLeadHistory($stateParams.leadId).then(function(response) {
            const histories = response.data.data;
            histories.forEach(history => {
                if (history.thumbnail) {
                    history.thumbnail.dmsId = DocumentService.toBase64(history.thumbnail.dmsUrl)
                }
            });
            $scope.histories = histories
            formatLeadHistories($scope.histories)
            
            $scope.loadingHistoryInfo = false;
            $scope.loadingInfo = false;
        });
    };

    const init = function() {
        registerForEvents();
		$scope.attendanceLabel = leadsService.getAttendanceLabel($rootScope.isFrontline);
        if(me && me.organizationalTag && me.organizationalTag.jobRole){
        	const myRole = p0.allJobRoles.find(role => role._id === me.organizationalTag.jobRole._id);
			if(myRole){
				$scope.showAdviserAttendance = myRole.loanAdviserAttendanceTrack;
			}else{
				$scope.showAdviserAttendance = false;
			}
		}
        leadsService.getInteractions().then(function(response) {
            $scope.interactions = response.data.data;

            $scope.interactions = $scope.interactions.filter( interaction => {
                const {callInteraction, meetingInteraction, chatInteraction} = $scope.loggedInUser.organizationalTag.jobRole.permissions;
                if(interaction.internalId === "call" && callInteraction){
                    return true
                }else if(interaction.internalId === "meeting" && meetingInteraction){
                    return true
                }else if(interaction.internalId === "chat" && chatInteraction){
                    return true
                }else if(interaction.internalId === "email"){
                    //email interaction is added, there was no requirement for permission
                    return true
                }else if(interaction.internalId === "share"){
                    //share interaction will not visible on dropdown
                    return false
                }
            })

        });
        $scope.loadingInfo = true;
        leadsService.getLead($stateParams.leadId).then(function(data) {
            $scope.data.lead = data.data.data;
            console.log('$scope.data.lead: ', $scope.data.lead);
            if(!$scope.data.lead.customerCollectionTimeLine || !$scope.data.lead.customerCollectionTimeLine.length) {
                $scope.data.lead.customerCollectionTimeLine = [
                    'Customer record was created on 15-March-2023',
                    'Payment for July month was made 15 days after the due date',
                    'Last payment made on - 14-Sep-2023',
                    'Last payment missed on - 14-Oct-2023',
                    'First payment reminded was sent on - 15-Oct-2023; 1 day after the due date',
                    'First warning was sent on - 23-Oct-2023; 2 day after the promise to pay date',
                ]
            }
            $scope.interactionService.isRequired = data.data.data.leadStatusId.toLowerCase() === 'created';
            const {secondRecentInteraction, leadScores} = $scope.data.lead;
            if(secondRecentInteraction && secondRecentInteraction.status === 'missed'){
                $scope.data.lead.missedInteraction = secondRecentInteraction;
            }
            if(leadScores ){
                let documentShared = 0;
                leadScores.forEach(leadScore =>{
                    if(leadScore.scoreType === 'DocumentShared'){
                        documentShared = leadScore.value
                    }
                });
                $scope.data.lead.documentSharedCount = documentShared
            }
            leadsService.getNextStates($stateParams.leadId, me._id).then(function(response) {
                $scope.data.nextStates = response.data.data;
                $scope.workflowNoUpdateMessage = response.data.message
                if ($stateParams.statusUpdate === 'true') {
                    $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
                    $scope.data.stateSelected = true;
                    $scope.params.updateStatus = true;
                }
            }).catch(function(err) {
                console.error('Lead get next states API failed', err);
                toaster.pop('error', "Can't update lead now");
            });

            leadsService.getDocEformInfo($stateParams.leadId).then(function(response){
                $scope.data.lead.docEformInfo = response.data.data;
                if (!$scope.data.lead.docEformInfo) {
                    return;
                }
                Object.keys($scope.data.lead.docEformInfo).forEach( key => {
                    $scope.data.lead.docEformInfo[key].forEach(info => {
                        if(info.eform){
                            const {docsUploadedCount,docsRequiredCount} = info.eform.details
                            info.percentageCompleted = docsUploadedCount/docsRequiredCount
                            if(info.percentageCompleted){
                                info.percentageCompleted = info.percentageCompleted*100
                            }
                        }
                        if(info.document){
                            const {thumbnailUrl} = info.document
                            info.document.thumbnailUrl = `${p0.config.saleskeyDomain}/api${thumbnailUrl}`
                        }
                    })
                })
            }).catch(function(err){
                console.error('Lead documents eform info API failed', err);
                toaster.pop('error','error in fetching the doc info')
            })
            
            getContactInfo($scope.data.lead);
            if($scope.data.lead && $scope.data.lead.notes){
                $scope.data.lead.notes = $scope.data.lead.notes.filter(note => note.notes && note.notes.length)
            }
        }).catch(function(err) {
            console.error('Get lead API failed', err);
            toaster.pop('error', "Can't get the lead");
        });
        getLeadHistoryFunc();
        leadsService.getFutureFollowUps($stateParams.leadId).then(response => {
            if (response.data.status) {
                let followUps = response.data.followUps;
                let dayStart = new Date().setHours(0, 0, 0, 0);
                let dayEnd = new Date().setHours(23, 59, 59, 999);

                _.forEach(followUps, (followUp) => {
                    let date = new Date(followUp.scheduledAt);
                    if (dayStart <= date && date <= dayEnd) {
                        followUp.isToday = true;
                    }
                });
                $scope.followUps = _.sortBy(followUps, 'scheduledAt');
                
            }
        })
        leadsService.getActiveFrontlines().then(function(result){
            $scope.frontLineList = result;
        })
    };

    $scope.openSalesKeyLink = function(linkCode){
        HotKeyService.getReviewToken(linkCode).then(result => {
            if (result.status) {
                const token = result.data
                const url = `${p0.config.saleskeyDomainExternal}/c/${linkCode}?token=${token}`;
                console.log(url)
                $window.open(url, '_blank');
            }else{
                toaster.pop('error', result.message)
            }
        })
    }

    const updateWorkflow = function(prerequisites) {
        const params = {
            _id: $scope.data.lead._id,
            userId: me._id,
            workflowId: $scope.data.lead.workflowId,
            nextStateId: $scope.data.state.state,
            prerequisites: prerequisites
        };
        $scope.params.errors = [];
        return leadsService.gotoState(params);
    };

    const saveInteraction = function() {
        let attendees = []
        if($scope.interactionService.adviserAttendance){
            attendees = $scope.additionalList.map ( list => {
                if(list.roleUser && list.roleUser._id){
                    return list.roleUser._id
                }
            })
            if(attendees.length === 0){
                toaster.pop('error', "Meeting Interaction ", "Atleast on attendee is required");
                return;
            }
        }
        const interactionData = {
            _id: $scope.data.lead._id,
            workflowId: $scope.data.lead.workflowId,
            userId: me._id,
            prerequisites: [
                { name: 'interaction', value: $scope.interactionService.interaction.internalId },
                { name: 'interactionOutput', value: $scope.interactionService.interactionOutput.internalId },
                { name: 'notes', value: $scope.interactionService.notes ? $scope.interactionService.notes : "" },
				{ name: 'loanAdviserPresence', value: !!$scope.interactionService.adviserAttendance },
                { name: 'category', value: 'lead' }
            ],
            attendees
        };
        // /** if follow-up not set then set tomorrow as follow-up **/
        // if(!$scope.interactionService.followupDate){
        //     let tmrw = new Date();
        //     tmrw = new Date().setDate(new Date().getDate() + 1);
        //     $scope.interactionService.followupDate = new Date(tmrw);
        // }
        let followUp = $scope.interactionService.followupDate;
        if (followUp) {
            /** check if the user selected the follow-up from previously created list, if yes then do not create again **/
            let isFollowUpExists = _.find($scope.followUps, (date) => {
                return new Date(date.scheduledAt).getTime() === new Date(followUp).getTime();
            });

            if (!isFollowUpExists) {
                interactionData.prerequisites.push({ name: 'followUp', value: $scope.interactionService.followupDate });

                if ($scope.interactionService.followUpType) {
                    interactionData.prerequisites.push({ name: 'followupType', value: $scope.interactionService.followUpType.internalId })
                    if ($scope.interactionService.address) interactionData.prerequisites.push({ name: 'address', value: $scope.interactionService.address })
                }
                let followupEndDate = $scope.interactionService.followupEndDate;
                if(followupEndDate){
                    interactionData.prerequisites.push({ name: 'followUpEnd', value: $scope.interactionService.followupEndDate })
                }
            }
        }

        let completedAt = $scope.interactionService.completedAt;
        if (completedAt) {

            interactionData.prerequisites.push({ name: 'completedAt', value: $scope.interactionService.completedAt });
            
            let completionEndTime = $scope.interactionService.completionEndTime;
            if(completionEndTime){
                interactionData.prerequisites.push({ name: 'completionEndTime', value: $scope.interactionService.completionEndTime })
            }
        }

        if ($scope.data.lead.cui) {
            interactionData.cui = $scope.data.lead.cui
        }

        return leadsService.saveInteraction(interactionData);
    };

    const goBack = function() {
        $state.go('lms.listCustomerNew')
        // $window.history.back();
    };
    $scope.onCancel = function() {
        goBack();
    };

    $scope.onStateChange = function() {
        $scope.data.stateSelected = false;
        checkInteractionRequired();
        $scope.params.errors = [];
        $timeout(function() {
            $scope.data.stateSelected = true;
        });
    };

    function checkInteractionRequired() {
        $scope.interactionService.show = true;
        //Lead auto feature, do not show any interaciton fields from workflow. Keep the existing interaction form.
        // if ($scope.data.state && $scope.data.state.prerequisites && $scope.data.state.prerequisites.length) {
        //     if (_.find($scope.data.state.prerequisites, { name: 'interaction' })) {
        //         $scope.interactionService.show = false;
        //     }
        // }
    }

    $scope.updateCheckBox = function() {
        if ($scope.params.updateStatus) {
            $scope.data.state = $scope.data.nextStates[0];
            $scope.data.stateSelected = true;
        } else {
            $scope.data.state = null;
            $scope.data.stateSelected = false;
        }
        checkInteractionRequired();
    };

    function searchComponent(query, key, key2) {
        //var lowercaseQuery = query.toLowerCase();
        let ismatched = false;
        const re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    };

    $scope.selectInteraction = function() {
        $scope.interactionOutputs = $scope.interactionService.interaction ? $scope.interactionService.interaction.outcome : [];
        $scope.interactionService.interactionOutput = $scope.interactionOutputs[0];
    };


    $scope.onSubmit = function() {
        if ($scope.interactionService.show && $scope.interactionService.interaction) {
            return saveInteraction().then(function(result) {
                if (result.data.status) {
                    if ($scope.params.updateStatus) {
                        updateStatus();
                    } else {
                        //Only interaction update case
                        goBack();
                        toaster.pop('success', "Interaction", "Interaction successfully saved.");
                    }
                } else {
                    const message = result.data.errors[0].message || "Interaction update failed";
                    toaster.pop('error', "Interaction", message);
                }
            }, function(error) {
                console.log(error);
                toaster.pop('error', "Lead", "Update is failed.");
            });
        } else {
            if ($scope.params.updateStatus) {
                updateStatus();
            }
        }

        function updateStatus() {
            updateWorkflow($scope.data.state.prerequisites).then(function(response) {
                if (response.data.status) {
                    $scope.params.errors = [];
                    toaster.pop('success', "Lead", "Update is successful.");
                    goBack();
                } else {
                    toaster.pop('error', "Lead", "There were some errors in updating the lead. Please fix and try again.");
                    $scope.params.errors = response.data.errors;
                }
            }).catch(function(err) {
                console.error('Lead goto state API failed', err);
                $scope.params.errors = err.data.errors;
                toaster.pop('error', "Lead", "Update is failed.");
            });
        }
    };

    $scope.setFollowUp = function(date) {
        $scope.interactionService.followupDate = new Date(date);
        $scope.interactionService.followupEndDate = new Date(date);
    };

    $scope.gotoCustomer = function(cui) {
        if (cui) {
            $state.go('userFlow.customer', { customerId: cui });
        }
    };
    //#endregion
    $scope.additionalItem = {
        id:new Date().getTime(),
        isAdd: true,
        roleType: "",
        roleUser: "",
        roleUsers: [],
        roleTypes: []
    }
    $scope.additionalList = [];
    $scope.additionalList.push(angular.copy($scope.additionalItem));

    /**
     * additionalList : this list contains the dynamically added loan advisors from the ui
     */

    $scope.addAdditionalField = () => {
        $scope.additionalList.forEach( list => {
            list.isAdd = false;
            selectedRoleUsers.push(list.roleUser._id);
        })
		$scope.additionalItem.id = new Date().getTime();
        $scope.additionalItem.isAdd = true;
        
        const result = checkForItems()
        $scope.additionalItem.roleTypes = result.roleTypes;
        $scope.additionalItem.showMore = result.showMore;


        $scope.additionalList.push(angular.copy($scope.additionalItem));
    };

    const assignItemsToList = (users) => {
        let result = {}
        leadsService.getUserById(me.parent).then( function(user){
            $scope.managerUsers = [user]
            roleTypes.push('Manager')
            allRoleTypes = angular.copy(roleTypes)
            result = checkForItems()
            $scope.additionalList[0].roleUsers = users && users.length ? users : $scope.managerUsers;
            $scope.additionalList[0].roleTypes = result.roleTypes;
            $scope.additionalList[0].showMore = result.showMore
        })
    }
    
    const prepareRolesAndUsersData = () => {
        const {allPlayTypes, config} =  p0;
        const loanAdvisorPlayType = allPlayTypes.find( type => {
            if(type.playTypeCode === config.loanAdvisorPlaytype){
                return type
            }
        });

        //First level of check, if no loan advisory playtype found
        if(loanAdvisorPlayType){
            const getPlayTypeUsersPromise = leadsService.getPlayTypeUsers(loanAdvisorPlayType._id);
            getPlayTypeUsersPromise.then( function(playTypeUsers){
                $scope.loanAdvisors = playTypeUsers;
                let result = {}
                if(me.systemRole === 'frontline'){
                    assignItemsToList($scope.loanAdvisors)      
                }else{
                    allRoleTypes = angular.copy(roleTypes)
                    result = checkForItems()
                    //Second level of check, if no users found for the loan advisory playtype
                    //manager logs in case, if no loan advisor users found then make the additional list empty
                    if($scope.loanAdvisors && $scope.loanAdvisors.length){
                        $scope.additionalList[0].roleUsers = $scope.loanAdvisors;
                        $scope.additionalList[0].roleTypes = result.roleTypes;
                        $scope.additionalList[0].showMore = result.showMore
                    }else{
                        $scope.additionalList = []
                    }
                }  
            }).catch( function(error){
                const message = error && error.message ? error.message : 'Error while fetching Playtype users.';
                toaster.pop('error', "Playtype Users", message);
            })
        }else{
            $scope.loanAdvisors = []
            if(me.systemRole === 'frontline'){
                assignItemsToList([])       
            }else{
                //in case of manager/senior manager no frontline should be shown
                $scope.additionalList = []
            }    
        }
    }
    //#endregion
    $scope.changeRoleUserlist = (role, id) => {
        let filteredField = $scope.additionalList.find( field => {
            if(field.id === id){
                return field
            }
        })
        if(role === 'Manager'){
            filteredField.roleUsers = $scope.managerUsers.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
            
        }else{
            filteredField.roleUsers = $scope.loanAdvisors.filter( manager => {
                if(selectedRoleUsers.includes(manager._id.toString())){
                    return false;
                }else{
                    return true;
                }
            });
        }
    }

    prepareRolesAndUsersData();

    $scope.removeAdditionalField = (item) => {
        selectedRoleUsers = selectedRoleUsers.filter( id => {
            if(item.roleUser && item.roleUser._id === id){
                return false
            }else{
                return true
            }
        })
        $scope.additionalList = $scope.additionalList.filter(ele => ele.id !== item.id);

        let removedManager= null;
        let removedAdvisor = null;
        if($scope.managerUsers){
            removedManager = $scope.managerUsers.find( manager => {
                if(item.roleUser && manager._id === item.roleUser._id){
                    return true
                }
            })
        }
        if($scope.loanAdvisors){
            removedAdvisor = $scope.loanAdvisors.find( advisor => {
                if(item.roleUser && advisor._id === item.roleUser._id){
                    return true
                }
            })
        }
        $scope.additionalList.forEach( list => {
            if(list.isAdd){
                if(list.roleType === 'Manager' && removedManager){ 
                    list.roleUsers.push(removedManager)
                }else if(removedAdvisor && list.roleType !== 'Manager'){
                    list.roleUsers.push(removedAdvisor)
                }
                if(!list.roleTypes.includes(item.roleType)){
                    list.roleTypes.push(item.roleType)
                    list.roleUser = ""
                    list.roleType = ""
                }
                list.showMore = true
            }
        })
    };
    
    const checkForItems = () => {
        let tempTypes = angular.copy(roleTypes);
        let users = []
        let managerLength = 0;
        let laLength = 0
        const result ={
            roleTypes : "",
            showMore : false
        }
        allRoleTypes.forEach( role => {
            if(role === 'Manager'){
                users = $scope.managerUsers.filter( manager => {
                    return !selectedRoleUsers.includes(manager._id.toString())
                });
                managerLength = users.length
                if(!users.length){
                    const index = tempTypes.indexOf(role)
                    if(index >= 0)
                    tempTypes.splice(index,1)
                }
            }else{  
                users = $scope.loanAdvisors.filter( advisor => {
                    return !selectedRoleUsers.includes(advisor._id.toString())
                });
                laLength = users.length
                if(!users.length){
                    const index = tempTypes.indexOf(role)
                    if(index >= 0)
                    tempTypes.splice(index,1)
                }
            }
            
        })

        if((!managerLength && laLength === 1) || (managerLength ===1 && !laLength)){
            result.showMore = false
        }else{
            result.showMore = true
        }
        result.roleTypes = tempTypes
        return result
    }

    $scope.onFollowupDateChange = (fDate) => {
        $scope.minFollowUpEndDate = new Date(fDate);
    }

    $scope.onInteractionDateChange = (intrDate) => {
        $scope.minInteractionEndTime = new Date(intrDate);
    }

    $scope.openAddFollowUpModal = (isNextFollowUp, interaction) => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'addFollowUpCtrl',
            clickOutsideToClose: false,
            resolve: {
                lead: () => {
                    return $scope.data.lead
                },
                interactionTypes: () => {
                    return $scope.interactions;
                },
                isNextFollowUp: () => {
                    return isNextFollowUp;
                },
                interaction: () => {
                    return interaction;
                },
                reloadLeadInfoPage: () => {
                    return init;
                }
            }
        });
    },

    $scope.openEditLeadInfoModal = () => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'editLeadInfoCtrl',
            clickOutsideToClose: false,
            resolve: {
                lead: () => {
                    return $scope.data.lead
                },
                reloadLeadInfoPage: () => {
                    return init;
                }
            }
        });
    },

    $scope.openAddNotesModal = () => {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: 'addLeadNotesCtrl',
            clickOutsideToClose: false,
            resolve: {
                lead: () => {
                    return $scope.data.lead
                },
                reloadLeadInfoPage: () => {
                    return init;
                }
            }
        });
    }
    
    $scope.showMoreNotesHandler = () => {
        $scope.showMoreNotes =  !$scope.showMoreNotes
    }

    $scope.changeSecondTopTab = (tabName) => {
        $scope.secondActiveTab = tabName;
        if($scope.secondActiveTab === $scope.secondTopTabs.EFORMS){
            const histories = $scope.histories.filter(history=> history.historyType === 'interaction' && history.type === 'share')
            formatLeadHistories(histories)
        }else if($scope.secondActiveTab === $scope.secondTopTabs.INTERACTIONS){
            const histories = $scope.histories.filter(history=> history.historyType === 'interaction')
            formatLeadHistories(histories)
        }else{
            formatLeadHistories($scope.histories)
        }
    }
    $scope.changeTopTab = (tabName) => {
        $scope.topActiveTab = tabName
    }

    $scope.showAttachedDocuments = (lead, ev) => {
            ev.stopPropagation();
            const documentTypes = []
            lead.attachedDocsCount= lead.documents.selected.length+lead.documents.approved.length
            if(lead.attachedDocsCount){
                const documents = lead.documents;
                if(documents){
                    Object.keys(documents).forEach(item => {
                        documents[item].forEach( document =>{
                            if(!documentTypes.includes(document.uid)){
                                documentTypes.push(document.uid)
                            }
                        })
                    })
                }
                if(documentTypes.length){
                    const dmsParameters = {
                        documentTypes, //In case of select mode
                        documents: [], //In case of approve mode send document Ids
                        isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                        isApproveMode: false, //for approve mode, open DMS with approve and reject option
                        isSelectMode: true, //for selection mode, open DMS with lead or ticket context i.e workflow
                        customer: {},
                        workflowId: lead.workflowId,
                        context: 'lead'
                    };
                    $rootScope.$broadcast('dms:openModal', dmsParameters);
                }
            }
    }

    $scope.reassignLead = function(lead, ev){
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller: 'reassignLeadModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                lead: function(){
                    return lead;
                },
                frontLineList: function () {
                    return $scope.frontLineList;
                },
                fetchLeads: function () {
                    return init;
                }
            }
        })
        .then(function(answer) {
        }, function() {
        });
    };

    $scope.updateLeadStatus = (lead) => {
        $state.go('userFlow.updateLead', {leadId: lead._id, productId: lead.productId._id});
    }

    $scope.showSharedContent = (tabName) =>{
        $scope.rhbActiveTab = tabName;
        filterRHSData()
    }

    $scope.showInfoRHSHandler = (tabName) => {
        $scope.showInfoRHS = true;
        $scope.rhbActiveTab = tabName;
        filterRHSData()
    }

    $scope.closeInfoRHSHandler = () => {
        $scope.showInfoRHS = false;
    }

    const filterRHSData = () => {
        if($scope.showInfoRHS && $scope.rhbActiveTab === $scope.rhsTabs.EFORMTAB){
            $scope.contentInfoList = $scope.data.lead.docEformInfo.wipEforms
        }else if($scope.showInfoRHS && $scope.rhbActiveTab === $scope.rhsTabs.CONTENTSHARED){
            $scope.contentInfoList = $scope.data.lead.docEformInfo.docsShared
        }

    }

    $scope.showAdditionalInfo = function(lead, ev){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate6,
            controller: function($scope, lead, $mdDialog){
                const { contact, secondaryFields } = lead;
                $scope.contact = contact;
                $scope.secondaryFieldsData = secondaryFields;
                $scope.contactKeys = [
                    {key:'name', name:'Name', type:'string'},
                    {key:'contactNumber', name:'Contact number', type:'contactNumber'},
                ];
                const currency = p0.config.CURRENCY_UNIT || "IDR";
                $scope.secondaryFields = [
                    {key:'address', name:'Address', type:'string'},
                    {key:'residentOwnership', name:'Ownership of Resident', type:'string'},
                    {key:'workingStatus', name:'Working Status', type:'string'},
                    {key:'fieldWork', name:'Field of work', type:'string'},
                    {key:'loanNeed', name:'Do you have any need for loan ?', type:'string'},
                    {key:'loanAmount', name:`How much do you need (${currency}) ?`, type:'string'},
                    {key:'loanExpectedTime', name:'When you need?', type:'string', camelCase: true},
                    {key:'brand', name:'Brand', type:'string'},
                    {key:'type', name:'Type', type:'string'},
                    {key:'ownership', name:'Ownership', type: 'string', camelCase:true},
                    {key:'year', name:'Year', type:'string'},
                    {key:'licenseNumber', name:'License Number', type:'string'},
                    {key:'nameOnCitizenId', name:'Name on citizen card', type:'string'},
                    {key:'nameOnFamilyCard', name:'Name on family card', type:'string'},
                    {key:'nameOnAddressProof', name:'Name on address proof', type:'string'},
                    {key:'nameOnPayslip', name:'Name on payslip', type:'string'},
                ];
                $scope.onClose = () => {
                    $mdDialog.hide();
                }
            },
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                lead: function(){
                    return lead;
                }
            }
        });
    };

    init();
});
