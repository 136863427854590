'use strict';
angular.module('bitnudgeApp')
    .controller('loginPrivilegesCtrl', function($http, $scope,  Auth, $location, $q, toaster,$mdDialog, ngXlsx, $filter) {
        var init = $http.post('/api/users/privileges').then(function(users) {
            users=users.data;
            $scope.userlist = users;
        });

        $scope.toggleUserPriviliges = function(user){
            user.isAltered = true;
        }

        $scope.saveLoginPriviliges = function(){
            var alteredUsers = _.filter($scope.userlist, function(user){
                return user.isAltered;
            });
            alteredUsers = _.map(alteredUsers, function(user){
                return {
                    _id : user._id,
                    mobileLogin : user.mobileLogin,
                    webLogin: user.webLogin,
                    resetDevices : user.resetDevices
                }
            })
            return $http.post('/api/users/alterPrivileges', {
                users : alteredUsers
            }).then(function(result){
                result=result.data;
                toaster.pop('success', "Privileges updated");
            }).catch(function(err){
                toaster.pop('error', "Failed to update privileges");
            })
        }
        return init
    });
