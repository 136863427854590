const autoImportNgTemplateLoaderTemplate1 = require('../html/alertsTable.html');

'use strict';
angular.module('bitnudgeApp')
    .component('alertsTable',{
        bindings: {
            alerts: '<',
            alertsIcons: '<',
            pagination: '<',
            apiLoaded: '<',
            previousPage: '&',
            nextPage: '&',
            fetchAlerts: '&',
            readAlert:'&',
            clickAlert:'&',
            timelineAlert:'&',
            redirectAlert:'&',
            customerView: '<',
            sortingObj: '<',
            isAggregatedView: '&'
        },
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: function($scope, p0){
            $scope.strings = p0.strings;
            $scope.alerts = [];
            this.$onChanges = (changes) => {
                if (changes.alerts) {
                    $scope.alerts = changes.alerts.currentValue;
                }
                if (changes.alertsIcons) {
                    $scope.alertsIcons = changes.alertsIcons.currentValue;
                }
                if (changes.apiLoaded) {
                    $scope.apiLoaded = changes.apiLoaded.currentValue;
                }
                if (changes.pagination) {
                    $scope.pagination = changes.pagination.currentValue;
                }
                if (changes.previousPage) {
                    $scope.previousPage = changes.previousPage.currentValue;
                }
                if (changes.nextPage) {
                    $scope.nextPage = changes.nextPage.currentValue;
                }
                // if(changes.deleteNotification){
                //     $scope.deleteNotification = changes.deleteNotification.currentValue;
                // }
                if(changes.redirectAlert){
                    $scope.redirectAlert = changes.redirectAlert
                }
                if(changes.customerView){
                    $scope.customerView = changes.customerView.currentValue
                }
                if(changes.sortingObj){
                    $scope.sortingObj = changes.sortingObj.currentValue
                }
                if(changes.isAggregatedView){
                    $scope.isAggregatedView = changes.isAggregatedView.currentValue
                }
                if(changes.fetchAlerts){
                    $scope.fetchAlerts = changes.fetchAlerts.currentValue
                }
                if($scope.alerts && $scope.alerts.length){
                    $scope.alerts.forEach(element => {
                        element.content = decodeURIComponent((element.content).replace(/\&#x2F;/g, '/'));
                    });
                }
            };
            $scope.onHeaderClick = (sortKey) => {
                $scope.sortingObj.sortKey = sortKey;
                if ($scope.sortingObj.sortOrder === 'asc') {
                    $scope.sortingObj.sortOrder = 'desc';
                } else {
                    $scope.sortingObj.sortOrder = 'asc';
                }
                this.fetchAlerts()
            }
        }
    });
