'use strict';
angular.module('bitnudgeApp')
    .service('fetchLeagues', function (p0, $rootScope) {
        this.getAllLeagues = function (currentUser, isOwn, division) {
            var allLeagues = [];
            var userTeams = [];
            if(isOwn){
                userTeams = currentUser.teams;
            }
            else{
                if(currentUser.roleType == 'frontline'){
                    userTeams = _.cloneDeep(currentUser.teams);
                    userTeams.shift();
                } else{
                    userTeams = currentUser.teamsUnder.concat(currentUser.teams);
                    userTeams=_.uniqBy(userTeams,'teamId');
                    if(division) {
                        _.remove(userTeams, function (userTeam) {
                            var team = _.find(p0.allTeams, function (team) {
                                return team._id.toString() == userTeam.teamId.toString();
                            });
                            return team.division && team.division.toString() != division._id.toString();
                        })
                    }
                }
            }
            userTeams.forEach(function(team){
                allLeagues.push({value:team.teamType, _id:team.teamId, name:team.teamTypeName + ' League'});
            });
            allLeagues = _.reverse(allLeagues);
            if(isOwn && !$rootScope.config.showBasicScorecard) {
                allLeagues.push({value:"peer", _id:"Peer", name:"Peer League"});
            }

            return allLeagues;
        };
    });
