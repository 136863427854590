const autoImportNgTemplateLoaderTemplate1 = require('../html/gpNotMadeModal.html');

'use strict';
angular.module('bitnudgeApp')
    .controller('goalSummaryCtrl', function ($scope, $http, $rootScope, $state, Auth, getGPService, toaster, p0, $mdDialog, $q, CurrentCycle, gpSimulation) {
    $scope.strings = $rootScope.strings;
    $scope.config = p0.config;
    $scope.importantActivities = getGPService.getImportantActivities();
    $scope.isReqEntirePeriod = false;
    $scope.maxArray = [];
    for(var i = 0; i < Math.min($scope.importantActivities.length,3);i++) {
        $scope.maxArray[i] = i;
    }
    $scope.me = Auth.getCurrentUser();
    $scope.commentFilter = 'All';
    $scope.kpis = p0.allKpis;
    if($state.params.selectedPlayerId)
        $scope.selectedPlayerId = $state.params.selectedPlayerId;
    else
        $scope.selectedPlayerId = $scope.me._id;

    $scope.jobRole = $scope.me.organizationalTag.jobRole;
    var selectedCycle;
    $scope.cycles =[];
    $http.post('api/users/getUserById', {userId:$scope.selectedPlayerId}).then(function(user){
        user= user.data && user.data.data;
        $scope.selectedPlayer = user;
        $scope.jobRole = user.organizationalTag.jobRole;
        $scope.players = [$scope.selectedPlayer];
        $http.post('api/users/me/children',{userId: $scope.selectedPlayerId}).then(function(reportees){
            reportees=reportees.data;
            $scope.players = $scope.players.concat(reportees);
        });
        if($state.params.cycleId && _.find(p0.allAdminCycles, {_id:$state.params.cycleId})){
            $scope.selectedCycle = _.find(p0.allAdminCycles, {_id:$state.params.cycleId});
        }
    });

    $http.post('api/users/getParentTrail',{playerId: $scope.selectedPlayerId}).then(function(user) {
        user=user.data;
        $scope.trailList = user;
        $scope.trailList.forEach(function (item) {
            item.redictUrl =  getNavigateObj(item._id);
        })
    });
    $scope.activityIndex = 0;

    $scope.activityLeft = function(){
        if($scope.activityIndex > 0){
            $scope.activityIndex--;
        }
    };
    $scope.activityRight = function(){
        if($scope.activityIndex < $scope.importantActivities.length - 3){
            $scope.activityIndex++;
        }
    };

    $scope.getGP = function(playerId){
        if(playerId == $scope.me._id)
            $scope.isReportee = false;
        else
            $scope.isReportee = true;

        if($scope.isWeekly){
            var currentCycle = $scope.weeklyCycle;
        }
        else{
            var currentCycle = $scope.selectedCycle;
        }
        getGPService.getGoal(playerId,currentCycle, true).then(function(result){
            // sort goals based on kpi sequence
            var kpisByCode = {};
            $scope.kpis.forEach(
              function (kpi) {
                kpisByCode[kpi.kpiCode] = kpi;
              }
            );

            result.forEach(
              function (goal) {
                var kpi = kpisByCode[goal.kpiCode];
                if (kpi)
                  goal.kpiSequence =  kpi.sequence;
              }
            );

            result = _.sortBy(result, ['kpiSequence']);
            $scope.goals=result;
            var updateWeeklyPromiseArr = [];
            for (var i = 0; i < $scope.goals.length; i++) {
                $scope.goals[i].enableSlide = false;
                if($scope.config.weeklyCycle){
                    updateWeeklyPromiseArr.push(upDateWeeklyGoals(i));
                }
            }
            if(updateWeeklyPromiseArr.length){
                $q.all(updateWeeklyPromiseArr).then(function(){
                    if($scope.selectedPlayerId == $scope.me._id && $rootScope.isPlayer){
                        $http.post('/api/gamePlans/getSimulationValidity').then(function(result) {
                            result=result.data;
                            if($state.params.showGPNotMade)
                                showAlertIfGPNotSet($scope.activeWeekIndex, result);
                        });
                    }
                });
            }

        });
    };

    $scope.isPlayer = $rootScope.isPlayer;
    $scope.isManager = $rootScope.isManager;
    $scope.weeklyCycle = p0.currentWeeklyCycle;

    $scope.isWeekly = false;
    $scope.toggleWeekly = function(){
        $scope.isWeekly = !$scope.isWeekly
    }
    $scope.isRate = true;
    $scope.selectWeek = function(index){
        $scope.selectedWeekIndex = index;
    };
    var upDateWeeklyGoals = function(index){
        var deferred = $q.defer();
        getGPService.getWeeklyGoals($scope.selectedPlayerId, $scope.goals[index].kpiId,$scope.selectedCycle._id,$scope.goals[index].activityConversionRatios,$scope.goals[index].averageTicketSize.value).then(function(weeks){
            $scope.goals[index].weeks = weeks;
            $scope.activeWeekIndex = _.findIndex(weeks,{activeWeek:true});
            var editableWeek = _.find(weeks,{isEditable:true});
            $scope.selectedWeekIndex = $scope.activeWeekIndex;
            if(editableWeek) {
                $scope.editableWeekCycleId = editableWeek.cycleId;
            }
            deferred.resolve("Success");
        });
        return deferred.promise;
    };

    var showAlertIfGPNotSet = function (activeWeekIndex, simulation) {
        var isWeekSet = true, isMonthSet = true;
        var KPIsForWeek = [];
        var KPIsForMonth = [];
        var cycleName = $scope.goals[0].weeks[activeWeekIndex].cycleName;
        var weekName;
        if(cycleName.indexOf(',') > 0){
            weekName = cycleName.split(',')[1].trim();
        }else{
            weekName = cycleName;
        }
        if($scope.config.weeklyCycle){
            _.forEach($scope.goals, function (goal) {
                isWeekSet = isWeekSet && goal.weeks[activeWeekIndex].gameplanTarget.value > 0;
                isMonthSet = isMonthSet && goal.gameplanTarget.value > 0;
                if(goal.weeks && goal.weeks[activeWeekIndex].gameplanTarget.value <= 0 && KPIsForWeek.indexOf(goal.name) == -1)
                    KPIsForWeek.push(goal.name);
                if(goal.gameplanTarget.value <= 0 && KPIsForMonth.indexOf(goal.name) == -1)
                    KPIsForMonth.push(goal.name);
            });
        }
        if(!isMonthSet || !isWeekSet && !p0.config.hideWeeklyGamePlan || !simulation.status){
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'gpNotMadeModalCtrl',
                resolve: {
                    KPIsForMonth: function(){
                        return KPIsForMonth;
                    },
                    KPIsForWeek: function(){
                        return KPIsForWeek;
                    },
                    weekName: function () {
                        return weekName;
                    },
                    simulation: function () {
                        return simulation;
                    }
                }
            });
        }
    };


    $scope.goalSummarySwot=function(goal){
        if(!goal)   return ;
        $scope.swotCategory = goal.swot;
        if ($scope.swotCategory=="t") {
            return "t-swot";
        }else if ($scope.swotCategory=="s") {
            return "s-swot";
        }else if ($scope.swotCategory=="w") {
            return "w-swot";
        }else if ($scope.swotCategory=="o") {
            return "o-swot";
        }
    };
    $scope.showInfoModal = function(selectedGoal, type){
        $scope.selectedGoal=selectedGoal;
        getGPService.showModals($scope.selectedPlayer,$scope.selectedGoal, type);
    };

    $scope.showLegendInfoModal = function(){
        getGPService.showLegendModal();
    };

    $scope.goToSimulate = function(){
        $state.go('userFlow.gameplanSimulation');
    };
    $scope.goToAnalyze = function(goal){
        $state.go('userFlow.gameplanAnalyze',  {selectedKPIId: goal.kpiId});
    };
    $scope.goToPlan = function(goal){
        $state.go('userFlow.gameplanPlan',  {selectedPlayerId: $scope.me._id, selectedKPIId: goal.kpiId, cycleId:$scope.selectedCycle._id});
    };
    $scope.getGoalSummaryOfPlayer = function (user) {
        if($state.includes('userFlow.gameplan')) {
            $state.go('userFlow.gameplan', {selectedPlayerId: user._id, cycleId:$scope.selectedCycle._id});
        }
        else{
            $state.go('lms.lmsHuddleBoard', {selectedPlayerId: user._id});
        }
    }
    $scope.$watch('selectedPlayer', function(newValue, oldValue){
        if(newValue){
            if(newValue._id != $scope.me._id){
                $scope.getGoalSummaryOfPlayer(newValue);
            }
        }
    });

    $scope.saveWeeklyGP = function(goal){
        $http.post('/api/gamePlans/week', {cycleId:goal.weeks[$scope.activeWeekIndex+1].cycleId, kpiId:goal.kpiId, gameplanTarget:{value: goal.weeks[$scope.activeWeekIndex+1].gameplanTarget.value*goal.denom}}).then(function(){
            $scope.getGP($scope.selectedPlayerId);
        });
    };

    $http.post('/api/users/gpCommentsForUser', {userId:$scope.selectedPlayerId}).then(function(result) {
        result=result.data;
        $scope.allComments = result;
        filterComments();
    });
    $scope.startComment = function(){
        $scope.commenting = true;
    };

    $scope.addComment= function(commentText,users,kpis){
        var comment= {};
        comment.date= new Date();
        comment.content=commentText;
        comment.commentorName=$scope.me.name;
        comment.commentor=$scope.me;
        comment.kpis = kpis;
        comment['userId'] = $scope.selectedPlayerId;
        $http.post('/api/users/addGpCommentForUser', comment).then(function(result) {
            result=result.data;
            $scope.allComments = result;
            filterComments();
            toaster.pop('success', "Save", "Comment Successfully Added.");
            $scope.commenting = false;
        });
        $http.post('/api/gamePlans/addPointsForGPComment').then(function(result) {
            result=result.data;
            toaster.pop('success', "Congrats", "You just received Activity "+$scope.strings.POINTS.value+".");
        });
    };

    $scope.addReply= function(commentText, commentId){
        if(commentText==undefined|| commentText.trim()=="")
            toaster.pop('Failure', "Please enter a reply.");
        else{
            var reply={};
            reply.date= new Date();
            reply.content=commentText;
            reply.commentorName=$scope.me.name;
            reply.commentor=$scope.me._id;
            reply['userId'] = $scope.selectedPlayerId;
            reply.commentId = commentId;
            $http.post('/api/users/addGpReplyForComment', reply).then(function(result) {
                result=result.data;
                var index = _.findIndex($scope.comments,function(comment){
                    return comment._id.toString() == commentId.toString();
                });
                reply.commentor = $scope.me;
                $scope.comments[index].replies.push(reply);

                toaster.pop('success', "Save", "Reply Successfully Added.");
            });

            $scope.gpComment="";
        }
    };

    $scope.formatReplierName = function(name){
        if(name)
            return name.substring(0,name.indexOf(' '))
    };

    $scope.formatDate = function(date,type){
        var Months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        var newDate = new Date(date);
        if(type=="comment")
            var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()]+"-"+newDate.getFullYear();
        else
            var reqDate = newDate.getDate()+"-"+Months[newDate.getMonth()];
        return reqDate;
    };

    $scope.onSlide = function(goal){
        getGPService.reCalcPlan(goal, $scope.isReqEntirePeriod);
    };

    $scope.reporteePlanButton = function(goal){
        if(goal.enableSlide){
            $scope.savePlan(goal);
        }else{
            goal.enableSlide = true;
        }
    };
    $scope.savePlan = function(goal){
        if($scope.selectedPlayerId != $scope.me._id) {
            var currentGoal = _.find($scope.goals, {kpiId: goal.kpiId});
            if (currentGoal) {
                currentGoal.managerValue = goal.newGpValue;
                currentGoal.managerPercentage = (currentGoal.managerValue / currentGoal.target.value * 100).toFixed(2) ;
            }
        }
        $http.post('/api/gamePlans/setGPOfReportee', {userId: $scope.selectedPlayerId, cycleId: $scope.selectedCycle._id, kpiId: goal.kpiId,gameplanTarget:{value: goal.newGpValue*goal.denom}}).then(function(){
            toaster.pop('success', "Gameplan", "You have suggested gameplan to "+$scope.selectedPlayer.name);
        });
    };
    $scope.cancelGamePlanForGoal= function(goal){
        if(parseFloat(goal.gameplanTarget.value) == 0){
            goal.newGpValue= 0
        }
        goal.gameplanPercentage = parseFloat(goal.gameplanTarget.value)/goal.target.value*100;
    };

    $scope.changeCommentFilter = function(filter){
        $scope.commentFilter = filter;
        filterComments();
    };
    function filterComments(){
        switch ($scope.commentFilter){
            case 'All':
                $scope.comments = $scope.allComments;
                break;
            case 'Manager':
                $scope.comments = _.filter($scope.allComments,function(comment){
                    return comment.commentor == $scope.selectedPlayer.parent;
                });
                break;
            case 'Others':
                $scope.comments = _.filter($scope.allComments,function(comment){
                    return comment.commentor != $scope.selectedPlayer.parent;
                });
                break;

        }
    }

    $scope.imagePosition = function(myId, commentorId){
        return myId === commentorId ? 'chat-img pull-left' : 'chat-img pull-right';
    };

    if($state.params.modalMessage){
        $mdDialog.show(
            $mdDialog.alert()
                .title('Message')
                .textContent($state.params.modalMessage)
                .ok('Ok')
        );
    }
    $scope.cycleChange = function (cycle) {
        $scope.selectedCycle = cycle;
        selectedCycle = $scope.selectedCycle;
        $scope.getGP($scope.selectedPlayerId);
        CurrentCycle.setCycle(cycle);
    };
    var getNavigateObj = function (playerId) {

        if(playerId){
            var obj ={
                state: 'userFlow.gameplan',
                params: {
                    selectedPlayerId: playerId
                }
            };
            return obj;
        }
    };

    $scope.reCalcReq = function (isReqEntirePeriod) {
        $scope.goals.forEach(function(goal){
            getGPService.reCalcPlan(goal, isReqEntirePeriod)
            if($scope.config.weeklyCycle){
                goal.weeks.forEach(function(week){
                    getGPService.reCalcPlan(week, isReqEntirePeriod)
                });
            }
        });
    };
});
