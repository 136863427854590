const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/lostLeadModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/uploadFile.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/lms/docUploadModal.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/lms/exportLeads.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/lms/leadTabInfo.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/filterInfoModal.html');

'use strict';

angular.module('bitnudgeApp').controller('listLeadCtrl', function ($scope, $rootScope, $mdDialog, $state, $stateParams, leadsService, Auth, toaster, p0, $timeout, $filter, $http, CurrentCycle, $interval, WorkflowService) {
    $scope.me = Auth.getCurrentUser();
    $scope.config = p0.config;
    $scope.isFrontline = $rootScope.isFrontline;
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.isSeniorManager = $rootScope.isSeniorManager;
    $scope.isCloneUser = $rootScope.cloned
    $scope.permissionsAvailable = {}
    let PERMISSIONS_STORE = {}
    $rootScope.permissions.forEach( permission => {
        $scope.permissionsAvailable[permission] = true
    })
    PERMISSIONS_STORE = Object.assign({},$scope.permissionsAvailable)
               
    const setPermissions = () => {
        /**
         * First check that referral tab should be visible or not and then check for jobrole specific permission
         */
        let showReferralButton =  $scope.isReferralsView && !$scope.showLostLeads ? true : false
        if($scope.isCloneUser){
            $scope.permissionsAvailable.createReferralLead = showReferralButton && PERMISSIONS_STORE.createReferralLead
        }else{
            $scope.permissionsAvailable.createReferralLead = showReferralButton
        }
        $scope.permissionsAvailable.exportLeads = PERMISSIONS_STORE.exportLeads
        $scope.permissionsAvailable.createLead = PERMISSIONS_STORE.createLead && !$scope.isReferralsView
        
         /**
         * First check that referral tab should be invisible or not and then check for jobrole specific permission
         */
        let showUploadButton =  !$scope.isReferralsView
        $scope.permissionsAvailable.uploadLeads = showUploadButton && PERMISSIONS_STORE.uploadLeads
}

    leadsService.setCurrentUser($scope.me._id);
    $scope.sortArray = [];
    $scope.pageName = "My Leads";
    $scope.selectedLeadShow = false;
    $scope.leadList = [];
    $scope.seniorManagersList = [];
    $scope.filterObj = {};
    $scope.fromHB = $stateParams.fromHB;
    var originalLeadList = [];
    $scope.filtered = [];
    $scope.filtered = [];
    var filteredLists = [];
    $scope.selectedLead = {};
    $scope.selected = {
        cycle: {}
    };
    const allProductTab = {
        "name": "All",
        "new": 0,
        "productIcon": "",
        "today" : 0,
        "total" : 0,
        "_id": null
    }
    var currMonth = $scope.selected.cycle;
    $scope.openQuickMenu = true;
    var hideQuickMenu = false;
    $scope.filteredCount = 0;
    $scope.totalLeads = 0;
    $scope.leadCount = {};
    $scope.posLeadsCount = {};
    $scope.otherLeadsCount = {};
    $scope.apiLoaded = false;
    $scope.dataLoaded = false;
    $scope.sortingObj = {
        sortKey: 'followUpDate',
        sortOrder: 'asc'
    };
    $scope.leadsFilters = {
        noFilter: false,
        allFilter: true,
        quickFilter: false
    };
    $scope.pagination = {
        offset: 0,
        limit: 50,
        total: 0,
        disableNext: true,
        disablePrevious: true
    };

    const {strings} = p0;
    $scope.CUI_LABEL = strings && strings.CUI_LABEL && strings.CUI_LABEL.value || 'NRIC'

    var disableAllFilters = function () {
        Object.keys($scope.leadsFilters).forEach(function (key) {
            $scope.leadsFilters[key] = false;
        })
    }

    $scope.workflowColorCodes = []
    $scope.workflowStagesCodes = []

    //get the color codes from the workflow service
    WorkflowService.getAllWorkflowDefinition('lead').then( function(workflowDefinitions){
        var workflowColorCodes = []
        var workflowStagesCodes = []
        workflowDefinitions.forEach(function(workflowDefinition){
            if(workflowDefinition.type != 'ticket'){
                workflowDefinition.stages.forEach( function(stage){

                    if(stage.colorCode != 'none' && stage.colorCode){
                        var colorObj = {
                            color : stage.colorCode,
                            stageUid : [stage.uid]
                        }
                        var index = _.findIndex(workflowColorCodes, {color : stage.colorCode})
                        if(index < 0){
                            workflowColorCodes.push(colorObj)
                        }else{
                            if(workflowColorCodes[index].stageUid.indexOf(stage.uid) < 0)
                                workflowColorCodes[index].stageUid.push(stage.uid)
                        }
                    }
                    if(stage.name){
                        var stageObj = {
                            name : stage.name,
                            stageUid : [stage.uid]
                        }
                        var index = _.findIndex(workflowStagesCodes, {name : stage.name})
                        if(index < 0){
                            workflowStagesCodes.push(stageObj)
                        }
                    }
                })
            }
        })
        $scope.workflowColorCodes = workflowColorCodes
        $scope.workflowStagesCodes = workflowStagesCodes
    }, function(err){
        console.log(err.message)
    })

    var checkAndRestoreStateParams = function () {
        //set tab related info
        if ($stateParams.tabName !== undefined && $stateParams.tabName !== '') {
            if ($stateParams.tabName === 'referrals') {
                $scope.isReferralsView = true;
                $scope.showLostLeads = false;
                $scope.unassignedLeads = false;
                $scope.tabName = 'referrals';
            } else if ($stateParams.tabName === 'lostLeads') {
                $scope.isReferralsView = true;
                $scope.showLostLeads = true;
                $scope.unassignedLeads = false;
                $scope.tabName = 'lostLeads';
            } else if ($stateParams.tabName === 'leads') {
                $scope.isReferralsView = false;
                $scope.showLostLeads = false;
                $scope.unassignedLeads = false;
                $scope.tabName = 'leads';
            }else if ($stateParams.tabName === 'unassigned') {
                $scope.isReferralsView = false;
                $scope.showLostLeads = false;
                $scope.unassignedLeads = true;
                $scope.tabName = 'unassigned';
            }
        } else {
            $scope.isReferralsView = false;
            $scope.showLostLeads = false;
            $scope.unassignedLeads = false;
            $scope.tabName = 'leads';
            $state.go('lms.listLead', { tabName: 'leads' }, { notify: false });
        }

        //set all the products that are to be shown on the tabs
        let myProducts = p0.myProducts;
        if($rootScope.isFrontline && p0.config.agentOnBoardingProduct){
            myProducts = myProducts.filter(p => p.productCode.toLowerCase() !== p0.config.agentOnBoardingProduct.toLowerCase())
        }
        if($rootScope.isAgentOnBoardingVerificationUser){
            myProducts = p0.allProducts.filter(p => p.productCode.toLowerCase() === p0.config.agentOnBoardingProduct.toLowerCase())
        }

        if ($scope.isReferralsView) {
            if ($scope.showLostLeads) {
                $scope.myProducts = [];
                myProducts.forEach(function (product) {
                    $scope.myProducts.push({
                        productCode: product.productCode,
                        _id: product._id,
                        name: product.shortName
                    });
                })
            } else {
                $scope.myProducts = [];
                p0.allProducts.forEach(function (product) {
                    $scope.myProducts.push({
                        productCode: product.productCode,
                        _id: product._id,
                        name: product.shortName
                    });
                })
            }
        } else {
            $scope.myProducts = [];
            myProducts.forEach(function (product) {
                $scope.myProducts.push({
                    productCode: product.productCode,
                    _id: product._id,
                    name: product.shortName
                });
            })
        }

        $scope.myProducts.unshift(allProductTab);
        if ($stateParams.lostReassign) {
            $scope.isReferralsView = true;
            $scope.showLostLeads = true;
            $scope.unassignedLeads = false;
            $scope.tabName = 'lostLeads';
        }
        if ($stateParams.unassignedLeads) {
            $scope.isReferralsView = false;
            $scope.showLostLeads = false;
            $scope.unassignedLeads = true;
            $scope.tabName = 'unassigned';
        }

        if ($stateParams.dateField === 'failed' && !$stateParams.lostReassign) {
            showLostCount = true;
            $scope.isReferralsView = true;
            $scope.showLostLeads = true;
            $scope.unassignedLeads = false;
            $scope.tabName = 'lostLeads';
        }

        if ($stateParams.userId) {
            $http.post('api/users/getUserById',{userId: $stateParams.userId}).then(function (user) {
                user = user.data;
                $scope.selectedUser = user;
            })
        }
        if($stateParams.productId){
            $scope.selectedProductFilter = $stateParams.productId;
        }
        if($stateParams.leadId){
            disableAllFilters();
            $scope.leadsFilters.filterOnLead = true;
        }
        //restore filters if present in the state params
        if ($stateParams && Object.keys($stateParams) !== 0) {
            $scope.filterObj = JSON.parse(JSON.stringify($stateParams));

            if ($scope.filterObj.status) {
               $scope.filterObj.leadStatusKey = $scope.filterObj.status;
            }
            if ($scope.filterObj.dateField) {
                $scope.filterObj.leadDateKey = $scope.filterObj.dateField;
            }
            if ($stateParams.productId) {
                $scope.selectedProductFilter = $stateParams.productId;
            } else {
                let product = null
                if($stateParams.kpiId){
                    product = p0.allProducts.find( product => product.mainKpi.toString() === $stateParams.kpiId)
                }
                $scope.selectedProductFilter = product ?product._id : $scope.myProducts[0]._id;
                $scope.filterObj.productId = product ? product._id : $scope.myProducts[0]._id;
            }
            if ($stateParams.workflowIds) {
                $scope.filterObj.workflowIds = $stateParams.workflowIds;
            }
            if ($stateParams.startDate) {
                $scope.selected.cycle.startDate = new Date(parseInt($stateParams.startDate));
            } else {
                $scope.selected.cycle.startDate = p0.currentMonthlyCycle.startDate;
            }
            $scope.filterObj.startDate = $scope.selected.cycle.startDate;
            
            if ($stateParams.endDate) {
                $scope.selected.cycle.endDate = new Date(parseInt($stateParams.endDate));
            } else {
                $scope.selected.cycle.endDate = p0.currentMonthlyCycle.endDate;
            }
            $scope.filterObj.endDate = $scope.selected.cycle.endDate;
            if($stateParams.customerObj){
                $scope.filterObj.customerObj = atob($stateParams.customerObj);
            }
            if ($scope.filterObj.colorCode) {
                $scope.filterObj.colorCode = $scope.filterObj.colorCode;
            }
            if ($scope.filterObj.stageCode || $stateParams.stageCode) {
                $scope.filterObj.stageCode = $scope.filterObj.stageCode || $stateParams.stageCode;
            }
            if($stateParams.campaignCode){
                $scope.filterObj.campaignCode = $stateParams.campaignCode;
            }
        } else {
            setInitialFilters();
        }
        setPermissions()
    }

    var setExtraInfoOnLeadsList = function (leads) {
        var leadList = JSON.parse(JSON.stringify(leads));
        //set the currently selected lead to the first one in the list
        $scope.selectedLead = leadList[0];
        //this sets whether to show the details of this lead on the RHS
        $scope.selectedLeadShow = false;
        if ($stateParams.fromHB) {
            var startDate = new Date(parseInt($stateParams.startDate));
            var endDate = new Date(parseInt($stateParams.endDate));
            $scope.startDate = $filter('date')(startDate, 'd MMM');
            $scope.endDate = $filter('date')(endDate, 'd MMM');
        }

        $scope.leadList = leadsService.setExtrasInfo(leadList, $stateParams.fromHB, startDate, endDate, currMonth)
        $scope.leadList.forEach(lead => {
            if (!lead.userId){
                lead.isUnassigned = true;
            }
        })
    };

    var setCounts = function (result) {
		result.counts = _.orderBy(result.counts, 'total', 'desc');
        var productFromCustomerPage = leadsService.getProductFromCustomerPage();

        if (!$scope.selectedProductFilter) {
            if (productFromCustomerPage) {
                $scope.selectedProductFilter = productFromCustomerPage._id;
            } else {
                if ($stateParams.productId) {
                    $scope.selectedProductFilter = $stateParams.productId;
                    $scope.filterObj.productId = $stateParams.productId;
                } else {
                    $scope.selectedProductFilter = $scope.myProducts[0]._id;
                    $scope.filterObj.productId = $scope.myProducts[0]._id;
                }
            }
        }
        $scope.setSelectedProduct($scope.selectedProductFilter);
        //set the filtered count
        $scope.filteredCount = result.leads.docs.length;
        $scope.myProducts.forEach( product => {
            if(product.name === 'All'){
                product.new = 0
                product.total = 0
                product.today = 0        
            }
        })
        //set the total count
		result.counts.forEach(function (product) {
            

            $scope.myProducts.forEach( myP => {

                if(myP._id && myP._id.toString() === product._id.toString() ){
                    myP.new = product.new
                    myP.productIcon = product.productIcon
                    myP.today = product.today
                    myP.total = product.total
                }else if(myP.name === "All"){
                    myP.new = myP.new + product.new;
                    myP.today = myP.today + product.today;
                    myP.total = myP.total + product.total;
                }
            })

            if ($scope.selectedProductFilter && product._id.toString() == $scope.selectedProductFilter.toString()) {
                $scope.totalCount = product.total;
            }else{
                const product = $scope.myProducts.find(product => product.name === "All");
                $scope.totalCount = product.total;
            }
           

        })
    }

    $scope.fetchLeads = function (user, pagination) {
        if (!user) {
            user = $scope.selected.user ? $scope.selected.user : $scope.me;
        }
        if (pagination) {
            $scope.pagination = pagination;
        }
        //reset the variable storing the currently fetched leads
        $scope.leadList = [];
        originalLeadList = [];
        filteredLists = [];
        //call the API to fetch leads
        // var deferred = $q.defer();
        // $scope.promise = deferred.promise;
        var promise;
        var extraParams = {
            currentMonthlyCycle: {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            }
        };
        if ($scope.selected.cycle != undefined) {
            extraParams.selectedCycle = JSON.parse(JSON.stringify($scope.selected.cycle));
        } else {
            extraParams.selectedCycle = {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            };
        }
        extraParams.productId = $scope.selectedProductFilter;
        extraParams.products = JSON.parse(JSON.stringify($scope.myProducts));
        //set the user
        //dont send userId for the case of manager or seniorManager as the API fetches it from the authentication middleware and fethces all its frontlines' leads
        if ($scope.me.systemRole == 'frontline') {
            extraParams.userId = $scope.me._id;
        } else if (user) {
            extraParams.userId = user._id;
        }
        $scope.filterObj.unassignedView = false;
        if ($scope.isReferralsView) {
            if ($scope.showLostLeads) {
                promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'lost', false, $scope.sortingObj);
            } else {
                promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'referrals', false, $scope.sortingObj);
            }
        } else if($scope.unassignedLeads){
            $scope.filterObj.unassignedView = true;
            promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'unassigned', false, $scope.sortingObj);
        }else {
            promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'myleads', false, $scope.sortingObj);
        }

        //set data loaded as false
        $scope.dataLoaded = false;
        $scope.apiLoaded = false;

        //make the API call
        promise
            .then(function (data) {
                $scope.filterObj.workflowIds = null
                //set the list of leads to the scope variable
				if(data.data && data.data.status){
					var result = data.data.data;
					//set extra information on leads
					setExtraInfoOnLeadsList(result.leads.docs);
					//set product wise counts
					setCounts(result);
					$scope.setPaginationData(result);
					originalLeadList = JSON.parse(JSON.stringify($scope.leadList));
					filteredLists = JSON.parse(JSON.stringify($scope.leadList));
					//set data loaded to true
					$scope.dataLoaded = true;
					$scope.apiLoaded = true;
				}else{
					$scope.dataLoaded = true;
					$scope.apiLoaded = true;
					toaster.pop('failed', "Leads list", "Failed to get the the leads list");
				}
            })
    }

    //initialization of the page
    checkAndRestoreStateParams();
    $scope.mgrDashboardRedirect = false;
    if ($stateParams.externalParams && !_.isEmpty($stateParams.externalParams)) {
        $scope.filterObj.externalParams = $stateParams.externalParams;
        $scope.selectedKpiId = $stateParams.externalParams.kpiId;
        $scope.mgrDashboardRedirect = true;
    }
    if ($scope.isFrontline) {
        $scope.selected.user = $scope.me;
        $scope.selected.role = $scope.me.organizationalTag.jobRole;
        $scope.fetchLeads($scope.me);
    }

    $scope.setPaginationData = function (result) {
        $scope.pagination.total = result.total;
        if (result.leads.docs.length == 0) {
            $scope.filteredCount = 0;
        } else {
            if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
                $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
            } else {
                $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
            }
        }
        if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
            $scope.pagination.disableNext = true;
        } else {
            $scope.pagination.disableNext = false;
        }
        if ($scope.pagination.offset == 0) {
            $scope.pagination.disablePrevious = true;
        } else {
            $scope.pagination.disablePrevious = false;
        }
    }

    $scope.nextPage = function () {
        $scope.pagination.offset += $scope.pagination.limit;
        $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
    }

    $scope.previousPage = function () {
        $scope.pagination.offset -= $scope.pagination.limit;
        $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
    }

    $scope.onApplyFilterCall = function () {
        //set the filters on to the state params
        $scope.pagination.offset = 0;
        setStateParams();
        if ($scope.selected.user) {
            $scope.fetchLeads($scope.selected.user);
        } else {
            $scope.fetchLeads($scope.me);
        }
    }

    var resetFilters = function () {
        $scope.filterObj = {
            productId: $scope.selectedProductFilter,
            leadStatus: "",
            leadStatusKey: "",
            leadDate: "",
            leadDateKey: "",
            leadTypeKey: "",
            searchParam: '',
            userId: '',
            referrals: $scope.isReferralsView,
            applyNOT: false,
            customerObj: {},
            colorCode: '',
            campaignCode:null
        };
    }

    var setInitialFilters = function () {
        $scope.filterObj = {
            productId: $scope.myProducts[0]._id,
            leadStatus: "",
            leadStatusKey: "",
            leadDate: "",
            leadDateKey: "",
            leadTypeKey: "",
            searchParam: '',
            userId: '',
            referrals: $scope.isReferralsView,
            applyNOT: false,
            customerObj: {},
            colorCode: '',
            campaignCode: null
        };
        $scope.selectedProductFilter = $scope.myProducts[0]._id
        let cycle = _.cloneDeep(p0.currentMonthlyCycle)
        $scope.selected.cycle.startDate = cycle.startDate;
        $scope.filterObj.startDate = cycle.startDate;
        $scope.selected.cycle.endDate = cycle.endDate;
        $scope.filterObj.endDate = cycle.endDate;
    }

    var updateFollowUpDate = function () {
        var now = new Date();
        $scope.leadList.forEach(function (lead) {
            if (new Date(lead.followUpDate) < now) {
                lead.followUpMissed = true;
                lead.followUpDateStatus = {
                    class: "swot-red",
                    text: 'Missed '
                };
            }
        })
    };

    updateFollowUpDate();

    $scope.$on('$destroy', function () {
        if (angular.isDefined(usageInterval)) {
            $interval.cancel(usageInterval);
            usageInterval = undefined;
        }
    });

    $scope.showAllLeads = function () {
        //unset all the buttons which show filters
        disableAllFilters();
        //set the button which shows sll leads
        $scope.leadsFilters.noFilter = true;
        //reset filters
        resetFilters();
        //call API and fetch leads
        if ($scope.selected.user) {
            $scope.fetchLeads($scope.selected.user);
        } else {
            $scope.fetchLeads($scope.me)
        }
    };


    $scope.showReferals = function (selection) {
        //if the same referrals tab is clicked then do nothing
        if ($scope.isReferralsView === selection && !$scope.showLostLeads && !$scope.unassignedLeads) {
            return;
        }
        //set which tab was selected
        $scope.isReferralsView = selection;
        $scope.showLostLeads = false;
        $scope.unassignedLeads = false;
        $scope.tabName = selection ? 'referrals' : 'leads';
        $scope.pagination.offset = 0;
        //set the tabname to stateParams
        if (selection) {
            $state.go('lms.listLead', { tabName: $scope.tabName }, { notify: false });
        } else {
            $state.go('lms.listLead', { tabName: $scope.tabName }, { notify: false });
        }

        //new requirements, fetch all the leads when tab changes
        if ($scope.isReferralsView) {
            $scope.filterObj.applyNOT = false;
            $scope.filterObj.referrals = true;
            // $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
        } else {
            $scope.filterObj.productId = $scope.myProducts[0]._id;
            $scope.selectedProductFilter = $scope.myProducts[0]._id;
            // $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
        }
        setPermissions()
        $scope.showAllLeads();
    };

    $scope.showLostLeadsTab = function () {
        //if the same tab is selected then do nothing
        if ($scope.isReferralsView && $scope.showLostLeads) {
            return;
        }
        //new requirements, fetch all the leads when tab changes
        //set the selected tab information
        $scope.isReferralsView = true;
        $scope.showLostLeads = true;
        $scope.unassignedLeads = false;
        $scope.tabName = 'lostLeads'
        $scope.pagination.offset = 0;
        $scope.filterObj.productId = $scope.myProducts[0]._id;
        $scope.selectedProductFilter = $scope.myProducts[0]._id;
        //set the tabname onto the stateParams
        $state.go('lms.listLead', { tabName: $scope.tabName }, { notify: false });

        $scope.filterObj.applyNOT = false;
        $scope.filterObj.referrals = true;
        // $scope.fetchLeads($scope.me);
        setPermissions()
        $scope.showAllLeads();
    };
    $scope.showUnassignedLeadsTab = function () {
        //if the same tab is selected then do nothing
        if ($scope.unassignedLeads) {
            return;
        }

        //set the selected tab information
        $scope.isReferralsView = false;
        $scope.showLostLeads = false;
        $scope.unassignedLeads = true;
        $scope.tabName = 'unassigned'
        $scope.pagination.offset = 0;
        $scope.filterObj.productId = $scope.myProducts[0]._id;
        $scope.selectedProductFilter = $scope.myProducts[0]._id;
        //set the tabname onto the stateParams
        $state.go('lms.listLead', { tabName: $scope.tabName }, { notify: false });

        $scope.filterObj.applyNOT = false;
        $scope.filterObj.referrals = false;
        $scope.filterObj.unassignedLeads = true;
        // $scope.fetchLeads($scope.me);
        setPermissions()
        $scope.showAllLeads();
    };

    var lostDueToReassign = 0;
    var lostDueToNotAcceptance = 0;
    var showLostCount = false;

    var showLostModal = function () {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: function ($scope, $mdDialog, showCountMessage, lostDueToReassign, lostDueToNotAcceptance, $rootScope) {
                $scope.showCountMessage = showCountMessage;
                $scope.lostDueToReassign = lostDueToReassign;
                $scope.lostDueToNotAcceptance = lostDueToNotAcceptance;
                $scope.strings = $rootScope.strings;
                $scope.cancel = function () {
                    $mdDialog.cancel();
                }
            },
            resolve: {
                showCountMessage: function () {
                    return showLostCount;
                },
                lostDueToReassign: function () {
                    return lostDueToReassign;
                },
                lostDueToNotAcceptance: function () {
                    return lostDueToNotAcceptance;
                }
            }
        });
    };

    $scope.showQuickFilter = function () {
        //unset all the buttons which show filter modals
        disableAllFilters();
        //set the button which shows quick filter as selected
        $scope.leadsFilters.quickFilter = true;
        var filters = $scope.filterObj
        $scope.setSelectedProduct(filters.productId)
        leadsService.showQuickFilterModal(filters, originalLeadList, originalLeadList, function (filters) {
            if (filters) {
                $scope.filterObj = filters;
                $scope.selectedProductFilter = filters.productId;
                applyFilters();
                $scope.setSelectedProduct(filters.productId)
                $scope.selected.cycle = { startDate: $scope.filterObj.startDate, endDate: $scope.filterObj.endDate };
                $scope.pagination.offset = 0;
                $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
            }
        });
    }

    $scope.setSelectedProduct = function (productId) {
        if (productId) {
            $scope.selectedProduct = _.find(p0.allProducts, { _id: productId });
            $scope.secondListFilter = leadsService.setSecondFilters($scope.selectedProduct.productCode, $scope.isReferralsView);
        }
    };

    $scope.firstListFilter = leadsService.getFirsterFilters();
    $scope.thirdListFilter = leadsService.getThirdFilters($scope.isReferralsView);

    $scope.uploadFiles = function (name, url, ev, id) {
        ev.stopPropagation();
        if (id) {
            leadsService.setCurrentUser(id);
        } else {
            leadsService.setCurrentUser($scope.me._id);
        }
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'leadUploadCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: { employee: "userName" },
            fullscreen: true,// Only for -xs, -sm breakpoints.
            resolve: {
                parameters: function () {
                    return { modalName: name, url: url };
                }
            }
        })
            .then(function (answer) {
                leadsService.setCurrentUser($scope.me._id);
                $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
            }, function () {
                leadsService.setCurrentUser($scope.me._id);
            });
    };


    $scope.addLead = function (isReferral, ev) {
        const params = { tab: $scope.selectedProductFilter, unassignedView: $scope.unassignedLeads, isReferral};
        $state.go(p0.config.leadUI_v1 ? 'userFlow.addLead_v1' : 'userFlow.addLead_v2', params);
    };

    $scope.filterProduct = function (product) {
        $scope.filterObj.productId = product._id;
        $scope.selectedProductFilter = product._id;
        $scope.pagination.offset = 0;
        $scope.setSelectedProduct(product._id);
        $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
    };

    var setStateParams = function () {
        var stateParams = {
            status: $scope.filterObj.leadStatusKey,
            dateField: $scope.filterObj.leadDateKey,
            leadTypeKey: $scope.filterObj.leadTypeKey,
            productId: $scope.filterObj.productId,
            userId: $scope.filterObj.userId,
            leadId: $stateParams.leadId,
            startDate: moment($scope.selected.cycle.startDate).valueOf(),
            endDate: moment($scope.selected.cycle.endDate).valueOf(),
            systemSource: $scope.filterObj.systemSource,
            quickMenu: false,
            colorCode: $scope.filterObj.colorCode,
            tabName: $scope.tabName,
            customerObj: btoa($scope.filterObj.customerObj)
        };
        $state.go('lms.listLead', stateParams, { notify: false });
    };

    $scope.archiveLead = function (id, ev) {
        $scope.closeSelectedLead();
        var archivedLead = _.find(originalLeadList, { _id: id });
        archivedLead.archived = true;
        //archivedLead.leadStatus = 'Archived';
        archivedLead.showMissed = false;
        archivedLead.lastUpdationDate = new Date();
        $scope.fetchLeads();
    };

    $scope.showSelectedLead = function (lead) {
        $scope.selectedLeadShow = true;
        _.each($scope.leadList, function (val) {
            val.selectedLead = lead._id === val._id;
        });
        $scope.selectedLead = JSON.parse(JSON.stringify(lead));

    };

    $scope.showDocModal = function (lead, ev) {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'docUploadModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            locals: {
                lead: lead
            },
            resolve: {
                docImage: function () {
                    return addImage;
                }
            }

        });
    };
    var addImage = function (lead, image) {
        lead.verificationDoc.push(image);
    };
    $scope.closeSelectedLead = function () {
        $scope.selectedLeadShow = false;
        _.each($scope.leadList, function (val) {
            val.selectedLead = false;
        });
        $scope.selectedLead = {};
    };

    $scope.editLead = function (lead, shouldReOpen, ev) {
        $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1' : 'userFlow.editLead_v2', { leadId: lead._id });
    };
//
    if ($scope.isMiddleManager) {
        var myDivision = $scope.me.divisions[0];
        var reporteeJobRoleId = _.find(p0.allDivisions, { _id: myDivision }).frontline;
        $http.post('/api/users/playersOfJobRoles', {userId: $scope.me._id, jobRoles:JSON.stringify(reporteeJobRoleId)}).then(function (reportees) {
            reportees = reportees.data;
            $scope.allReportees = [];
            $scope.allReportees = reportees;
            $scope.allReportees.unshift({ _id: 'all', name: 'All' });
            $scope.selectedReportee = $scope.allReportees[0];
        });
    }
    $scope.onReporteeChange = function (reportee) {
        //if the same reportee is selected then do nothing
        if ($scope.selected.user && reportee._id == $scope.selected.user._id) {
            return;
        }
        $scope.pagination.offset = 0;
        $scope.selected.user = reportee;
        $scope.selected.role = reportee.organizationalTag.jobRole;
        $scope.fetchLeads(reportee);
    };

    var fetchLostLeadsCount = function () {
        var slippedLeads = leadsService.slippedLeads;
        var widthdrawnLeads = leadsService.widthdrawnLeads;
        lostDueToReassign = widthdrawnLeads.value.value;
        lostDueToNotAcceptance = (slippedLeads.value.value) - lostDueToReassign;
        showLostModal();
    };

    function applyFilters() {
        $scope.leadTypeKey = $scope.filterObj.leadTypeKey;
        $scope.leadStatusKey = $scope.filterObj.leadStatusKey;
        $scope.leadDateKey = $scope.filterObj.leadDateKey;
        $scope.startDate = new Date($scope.filterObj.startDate);
        $scope.endDate = new Date($scope.filterObj.endDate);
    }
    $scope.applicationDuplicate = function (lead) {
        if (lead.isDuplicate) {
            lead.isDuplicate = false;
        }
    };
    $scope.verifyDuplicateAndSubmit = function (lead) {
        if (lead.applicationId.length == 0) {
            lead.isDuplicate = false;
            $scope.editApplication(lead);
        } else {
            lead.leadId = lead._id;
            leadsService.validateApplicationId(lead).then(function (response) {
                if (!response.isUnique) {
                    lead.isDuplicate = true;
                } else {
                    lead.isDuplicate = false;
                    $scope.editApplication(lead);
                }
            });
        }
    };
    $scope.editApplication = function (lead) {
        $scope.leadId = lead._id;
        $scope.applicationId = lead.applicationId;
        var userId = $scope.me._id;
        $http.post('/api/leads/updatelead', { leadId: $scope.leadId, applicationId: $scope.applicationId, userId: userId, leadStatus: lead.leadStatus }).then(function (response) {
            response = response.data;
            if (response.error) {
                toaster.pop('error', "lead update", response.message);
            } else {
                $scope.hide(true);
            }
        }).catch(function (err) {
            $scope.hide();
        });
    };

    $scope.exportLeads = function (ev) {
        var extraParams = {
            currentMonthlyCycle: {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            }
        };
        if ($scope.selected.cycle != undefined) {
            extraParams.selectedCycle = JSON.parse(JSON.stringify($scope.selected.cycle));
        } else {
            extraParams.selectedCycle = {
                startDate: p0.currentMonthlyCycle.startDate,
                endDate: p0.currentMonthlyCycle.endDate
            };
        }
        extraParams.productId = $scope.selectedProductFilter;
        extraParams.products = JSON.parse(JSON.stringify($scope.myProducts));


        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: 'leadExportCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true,// Only for -xs, -sm breakpoints.
            resolve: {
                parameters: function () {
                    var userId, category = 'myLeads', tab = 'leads';
                    if ($scope.selected.user) {
                        userId = $scope.selected.user._id;
                    } else {
                        userId = $scope.me._id;
                    }
					if ($scope.isReferralsView) {
						if ($scope.showLostLeads) {
                            category = 'lost';
                            tab = 'lostLeads';
                        } else {
                            category = 'referrals';
                            tab = 'referrals'
						}
					}else if($scope.unassignedLeads){
                        category = 'unassigned';
                        tab = 'unassigned'
                    }
                    return {
                        filterObj: $scope.filterObj,
                        extraParams: extraParams,
                        sortingObj: $scope.sortingObj,
                        view: tab,
                        userId: userId,
						category:category
                    }
                }
            }
        })
    };

    $scope.searchImplemented = false

    $scope.removeSearch = function () {
        $scope.filterObj.searchParam = ''
        $scope.searchImplemented = false;
        $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me)
    }

    $scope.search = function () {
        $scope.searchImplemented = true
        $scope.pagination.offset = 0;
        $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
    }
    $scope.search_enter = function (keyEvent) {
        if (keyEvent.which === 13) {
            $scope.search()
        }
    }
    $scope.showAllFilters = function () {
        //unset all the buttons which show filter modals
        disableAllFilters();
        //set the button which shows all filters as selected
        $scope.leadsFilters.allFilter = true;

        var filterObj = _.cloneDeep($scope.filterObj);
        filterObj.startDate = new Date($scope.selected.cycle.startDate);
        filterObj.endDate = new Date($scope.selected.cycle.endDate);

        leadsService.showAllFilterModal(filterObj, $scope.selected.role, $scope.isReferralsView, $scope.onApplyFilterCall, $scope.workflowColorCodes, $scope.workflowStagesCodes, function (filters) {
            if (filters) {
                Object.keys(filters).forEach(function (key) {
                    $scope.filterObj[key] = filters[key];
                });
                //applyFilters();
                //$scope.selectedProductFilter = filters.productId;
                if (filters.productId) {
                    $scope.selectedProductFilter = filters.productId;
                    $scope.filterObj.productId = filters.productId;
                    $scope.setSelectedProduct(filters.productId)
                }
                $scope.selected.cycle = { startDate: $scope.filterObj.startDate, endDate: $scope.filterObj.endDate };
                $scope.onApplyFilterCall();
            }
        });
    };

    $scope.gotoHuddleBoard = function () {
        $state.go('lms.huddleBoard', { kpiId: $stateParams.kpiId });
    };

    $scope.showTabsInfo = function () {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller: function ($scope, $rootScope, $mdDialog) {
                $scope.userText = $rootScope.isManager ? 'my team' : 'me';
                $scope.leadUI_v1 = p0.config.leadUI_v1;
                $scope.onClose = function () {
                    $mdDialog.hide();
                };
            }
        });
    }

    $scope.showInfoModal = function () {
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate6,
            controller: 'filterInfoModalCtrl',
        });
    };


});
