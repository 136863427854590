const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/reassignLeadModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/reassignReferralLeadModal.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/lms/leadNotes.html');

'use strict';
angular.module('bitnudgeApp').controller('leadTableCustomerCtrl', function ($scope, $rootScope,p0, $state, $mdDialog, Auth, leadsService, toaster) {
    console.log('Hi I am lead Table Customer ctrl')
    $scope.me = Auth.getCurrentUser();
    $scope.config = p0.config;
    $scope.strings = $rootScope.strings;
    $scope.isFrontline = $rootScope.isFrontline;
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.isSeniorManager = $rootScope.isSeniorManager;

    $scope.isCloneUser = $rootScope.cloned
    $scope.permissionsAvailable = {
        reassignLead: false
    }

    $scope.leadList.forEach(lead => {
        if (!lead.userId){
            lead.isUnassigned = true;
        }
    })

    /**
     * Watch is required to detect the tab changes, and make permissions accordingly.
     * Can not change to onChanges, as the existing component is directive
     */
    $scope.$watch('leadList', () => {
            if($scope.isCloneUser){
                let permissionType = p0.USER_PERMISSIONS.reassignLead
                if($scope.isReferralsView){
                    permissionType = p0.USER_PERMISSIONS.assignReferralLead
                }
                $scope.permissionsAvailable.reassignLead = $rootScope.permissions.includes(permissionType);
                $scope.permissionsAvailable.archiveLead = $rootScope.permissions.includes(p0.USER_PERMISSIONS.archiveLead);
                 
            } else{
                if (!$scope.isCustomerView) {
                    $scope.permissionsAvailable.reassignLead = (!$scope.isFrontline && !$scope.isReferralsView ) || ($scope.isFrontline && $scope.unassignedLeads)
                } else {
                    $scope.permissionsAvailable.reassignLead = false;
                }
                $scope.permissionsAvailable.archiveLead = !$scope.isReferralsView && !$scope.unassignedLeads && !$scope.isFrontline
            }
    });
		

    $scope.isAgentOnBoardingVerificationUser = $rootScope.isAgentOnBoardingVerificationUser;
    $scope.formatPhoneNumber = function(tel){
        return tel.slice(0,4)+' '+tel.slice(4,6)+' '+tel.slice(6,9)+' '+tel.slice(9);
    };
    $scope.isLeadFollowed = function (lead) {
        return _.find(lead.followers, {userId:$scope.me._id});
    };

    $scope.changeLeadFollowStatus = function (lead, event) {
        event.stopPropagation();
        if($scope.isLeadFollowed(lead)){
            leadsService.unFollowLead(lead._id).then(function(responce){
                if(responce.status) {
                    _.remove(lead.followers, {userId:$scope.me._id});
                    lead.followedByMe = false;
                    toaster.pop('success', "Lead Unfollowed", "You unfollowed the lead");
                }else{
                    toaster.pop('error', "Error occured", "Error in unfollowing the lead");
                }
            });
        }else{
            leadsService.followLead(lead._id).then(function(responce){
				if(responce.status) {
                    lead.followers.push({date:new Date, userId:$scope.me._id});
                    lead.followedByMe = true;
                    toaster.pop('success', "Lead Followed", "You are now following the lead");
                }else{
                    toaster.pop('error', "Error occured", "Error in following the lead");
                }
            });
        }
    };

    $scope.followColor = function (lead) {
        if($scope.isLeadFollowed(lead)) return 'blue';
        else return 'grey';
    };

    leadsService.getActiveFrontlines().then(function(result){
            $scope.frontLineList = result;
    })


    if($scope.me.roleType == 'seniorManager'){
        leadsService.getAllSeniorManagers($scope.me._id.toString()).then(function(result){
            $scope.seniorManagersList = result;
        })
    }


    $scope.reassignLead = function(lead, ev){
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'reassignLeadModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                lead: function(){
                    return lead;
                },
                frontLineList: function () {
                    return $scope.frontLineList;
                },
                fetchLeads: function () {
                    return $scope.fetchLeads;
                }
            }
        })
        .then(function(answer) {
        }, function() {
        });
    };

    $scope.reassignReferralLead = function(lead, ev){
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'reassignReferralLeadModalCtrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                lead: function(){
                    return lead;
                },
                seniorManagersList: function () {
                    return $scope.seniorManagersList;
                },
                fetchLeads: function () {
                    return $scope.fetchLeads;
                }
            }
        })
            .then(function(answer) {
            }, function() {
            });
    };

    $scope.editLead = function(lead,shouldReOpen,ev){
        const params = {leadId: lead._id, productId: lead.productId._id};
        $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1' : 'userFlow.editLead_v2', params);
    }


    $scope.showNotes = function(lead, ev){
        ev.stopPropagation();
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: 'leadNoteCtrl',
            targetEvent: ev,
            parent: angular.element(document.body),
            clickOutsideToClose:true,
            fullscreen: true ,// Only for -xs, -sm breakpoints.
            resolve:{
                lead: function () {
                    return lead;
                },
                allowedAddNotes: function() {
                    return !$scope.isReferralsView
                }
            }
        })
    }

    $scope.archiveLeadHelper = function(id,ev) {
        showConfirm(id, ev);
    };

    var showConfirm = function(id, ev) {
        ev.stopPropagation();
        var confirm = $mdDialog.confirm()
            .title('Are you sure you want to archive this lead?')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('No');
        $mdDialog.show(confirm).then(function() {
            leadsService.archiveLead(id).then(function(data){
                var archivedLead = _.find($scope.leadList, {_id:id});
                archivedLead.archived = true;
                //archivedLead.leadStatus = 'Archived';
                archivedLead.showMissed = false;
                archivedLead.lastUpdationDate = new Date();
                toaster.pop('success', "Lead Delete", "Customer Deleted.");
                if($scope.archiveLead){
                    $scope.archiveLead(id,ev);
                }
            });
        });
    }

    $scope.openDMSViewer = function(lead, ev) {
        ev.stopPropagation();
        const documentTypes = []
        if(lead.attachedDocsCount){
            const documents = lead.documents;
            if(documents){
                Object.keys(documents).forEach(item => {
                    documents[item].forEach( document =>{
                        if(!documentTypes.includes(document.uid)){
                            documentTypes.push(document.uid)
                        }
                    })
                })
            }
            if(documentTypes.length){
                const dmsParameters = {
                    documentTypes, //In case of select mode
                    documents: [], //In case of approve mode send document Ids
                    isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                    isApproveMode: false, //for approve mode, open DMS with approve and reject option
                    isSelectMode: true, //for selection mode, open DMS with lead or ticket context i.e workflow
                    customer: {},
                    workflowId: lead.workflowId,
                    context: 'lead'
                };
                $rootScope.$broadcast('dms:openModalLMS', dmsParameters);
            }
        }
    }

    $scope.onHeaderClick = (sortKey) => {
        $scope.sortingObj.sortKey = sortKey;
        if ($scope.sortingObj.sortOrder === 'asc') {
            $scope.sortingObj.sortOrder = 'desc';
        } else {
            $scope.sortingObj.sortOrder = 'asc';
        }
        $scope.pagination = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: true,
            disablePrevious: true
        };
        $scope.fetchLeads(null, $scope.pagination);
    }
});
