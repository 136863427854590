'use strict';

angular.module('bitnudgeApp')
    .controller('TeamCtrl', function ($scope, $http,  p0, ngXlsx, $filter) {
        var divisionMap = {};
        p0.tempAllDivisions.forEach(function(div){
          divisionMap[div._id] = div.name;
        });
        $http.post('/api/teams').then(function(teams){
            teams=teams.data;
            $scope.teams = teams;
            $scope.teams.forEach(function(team){
                if(team.division)
                  team.divisionName = divisionMap[team.division];
            });
        });

        $scope.downloadTeams = function(){
            $http.post('/api/teams/download').then(function(teams){
                teams=teams.data;
                var fields = ['name', 'teamCode', 'teamType', 'teamTypeName', 'divisionCode', 'adoptionReport',
                    'parent', 'remarks'];
                var data = [];
                teams.forEach(function(team){
                    var rowValue = {};
                    fields.forEach(function(field){
                        rowValue[field] = team[field];
                    });
                    data.push(rowValue);
                });
                var columDefs = [];
                // generate headers
                fields.forEach(function(field){
                    columDefs.push({field:field, displayName: field});
                });
                var sheetData = {
                    sheetName: 'Teams',
                    columnDefs: columDefs,
                    data: data
                };
                var result = ngXlsx.writeXlsx([sheetData]);
                var date = $filter('date')(new Date(), 'dd-MM-yyyy');
                // TODO: move excel download to server side
                saveAs(new Blob([s2ab(result)],{type:"application/octet-stream"}), "Teams-"+date+".xlsx");
            });
        };
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
  });

