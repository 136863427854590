const autoImportNgTemplateLoaderTemplate1 = require('../html/tickets/ticketTimeline.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/tickets/reassignTicket.html');

'use strict';
const {USER_PERMISSIONS} = require('../types/permissions');
angular.module('bitnudgeApp')
.controller('ticketTableCtrl', function ($scope, Auth, $rootScope, $mdDialog, $state, Tickets, $http, toaster) {

    $scope.me = Auth.getCurrentUser();
    $scope.isFrontline = $rootScope.isFrontline;
    $scope.frontlineList = [];
    $scope.strings = $rootScope.strings;

	$scope.assignServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.assignServiceRequest);
	$scope.viewServiceRequest = $rootScope.permissions.includes(USER_PERMISSIONS.viewServiceRequest);

    _.each($scope.ticketsList,function(ticket){
        ticket.isUnassigned = ticket.userId && !_.isEmpty(ticket.userId) ? false : true;
    });

    $scope.archiveTicket = function(ticket){
        $http.post('/api/tickets/archiveTicket', {ticketId: ticket._id}).then(function(response){
            response=response.data;
            if(response.err) {
                //toaster.failed
                toaster.pop('failed', "Ticket Archive", "Ticket could not be archived.");
            } else {
                //toaster.then
                //ticketsListBackup.delete(ticket)
                toaster.pop('success', "Ticket Archive", "Ticket successfully archived.");
                $scope.fetchTickets($scope.me)
            }
        });
    };


    $scope.editTicket = function(ticket) {
        $state.go('userFlow.editTicket',{ticketId:ticket._id})
    };

    $scope.showTimeline = function(ticket){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'timelineController',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                ticket: function(){
                    return ticket;
                }
            }
        });
    };
    $scope.shareCSReFormHandler = function(ticket){
        Tickets.shareCSReForm(ticket).then( (result) => {
            if(result.data.status)
                toaster.pop('success', "CSR e-Form", "Customer Service Request e-Form successfully shared.");
            else
                toaster.pop('failed', "CSR e-Form", "Error in sharing Service Request e-Form, "+result.data.errors[0].message);
        },() => {
            toaster.pop('failed', "CSR e-Form", "Error in sharing Service Request e-Form");
        })
    };
    $scope.formatPhoneNumber = function(tel){
        if(!tel){
            return ;
        }
        return tel.slice(0,4)+' '+tel.slice(4,6)+' '+tel.slice(6,9)+' '+tel.slice(9);
    };

    $http.post('/api/users/getAllLeaf', {userId: $scope.me._id}).then(function(response){
        response=response.data;
        if(response.err) {
            console.log('Error in fetching frontlines', response.err)
        } else {
            $scope.frontlineList = response.data
        }
    });

    $scope.showSelectedTicket = function(ticket){
        $scope.selectedTicket = [ticket];
        _.each($scope.ticketsList,function(val){
            val.selectedTicket = (ticket._id===val._id);
        });
    };

    $scope.reassignTicket = function(ticket, userId){
        $mdDialog.show({
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: 'reassignTicketCtrl',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            resolve:{
                ticket: function(){
                    return ticket;
                },
                frontlineList: function () {
                    return $scope.frontlineList;
                },
                callback: function(){
                    return $scope.fetchTickets;
                }
            }
        });
    }

    $scope.onHeaderClick = (sortKey) => {
        $scope.sortingObj.sortKey = sortKey;
        if ($scope.sortingObj.sortOrder === 'asc') {
            $scope.sortingObj.sortOrder = 'desc';
        } else {
            $scope.sortingObj.sortOrder = 'asc';
        }
        $scope.pagination = Tickets.getPaginationObj();
        $scope.fetchTickets(null, $scope.pagination);
    }

});
