const autoImportNgTemplateLoaderTemplate1 = require('../html/rejectDocModal.html');

angular.module('bitnudgeApp').controller('editTicketController', function($scope, Auth, $state, $stateParams,
    Tickets, $timeout, toaster, $window, p0,
    WorkflowService, $rootScope, DocumentService, $mdDialog,moment) {


    let me = Auth.getCurrentUser();
    let onCloseListener = null;
    $scope.strings = p0.strings;
    $scope.isStateUpdate = false;
    $scope.mindate = new Date();
    $scope.pageHeader = ""
    const cuiLabel = $scope.strings.CUI_LABEL && $scope.strings.CUI_LABEL.value ? $scope.strings.CUI_LABEL.value : 'CUI';
    $scope.contactKeys = {
        'cui': cuiLabel,
        'customerName': 'Name',
        'contactNumber': 'Contact Number',
        'productName': 'Product',
        'subProductName': 'Sub Product',
        'type': 'Type',
        'subType': 'Sub Type',
        'subProduct': 'Sub Product',
        'type': 'SR Type',
        'ticketStatus': 'State',
        'createdAt': 'Created On',
        'updatedAt': 'Update On',
        'formattedP2PDueDate':'Payment Expected on'
    };
    // num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

    $scope.data = {};
    $scope.interactionService = { show: true, isRequired: false };
    $scope.params = {};

    const registerForEvents = function() {

        $scope.$on('workflow:ticket:addPrerequisite', function(event, opt) {
            const { existing, data } = opt;
            const {
                contactNumber,
                customerName,
                workflowId,
                _id,
                userId,
                productId,
                subProduct,
                ticketStatusId,
                ticketStage
            } = $scope.data.ticket;
            const { state } = $scope.data;
            const payload = {
                customer: { name: customerName, contactNumber },
                existing,
                docTypeId: (data && data._id) ? data._id : '',
                newPreqParams: data, // New document type params are saved here...
                userId: userId._id,
                workflowId,
                context: 'ticket',
                productId: productId._id,
                subProduct,
                workflowConsumerId: _id,
                stateUid: ticketStatusId,
                stageUid: ticketStage,
                toStateUid: state.state,
            };
            WorkflowService.addPrerequisite(payload).then(function(response) {
                if (response.data.status) {
                    toaster.pop('success', "Ticket", "Request for additional information is successful.");
                } else {
                    toaster.pop('error', "Ticket", "Request for additional information is failed. Please ask administrator to recheck the workflow permissions.");
                }
            }, function(err) {
                const message = 'Add prerequisite failed.';
                console.error(`${message}`, err);
                toaster.pop('error', `${message} Please try after sometime.`);
            })
        });

        $scope.$on('workflow:ticket:document', function(event, opt) {
            const { item } = opt;
            const { workflowId, contact, cui } = $scope.data.ticket;
            const isApproved = item.name.startsWith("approve");
            const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
            const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

            let documentTypes = otherPrerequisites.map(p => p.typeParam);
            documentTypes.unshift(item.typeParam);

            let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
            const refId = item.selectedDocId || item.rejectedDocId;
            if (refId) {
                documentIds.unshift(refId);
            }
            const dmsParameters = {
                documentTypes, //In case of select mode
                documents: documentIds, //In case of approve mode send document Ids
                isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
                isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
                isSelectMode: !isApproved, //for selection mode, open DMS with lead or ticket context i.e workflow
                customer: {},
                workflowId,
                //cui: cui || contact.cui,
                context: 'ticket'
            };
            $rootScope.$broadcast('dms:openModal', dmsParameters);

            if (onCloseListener) onCloseListener();
            onCloseListener = $scope.$on("dms:onCloseModal", () => {
                fetchTicketHistory();
                refreshDocumentPrereqStatus();
            });

        });

        $scope.$on('workflow:ticket:approveDirect', function(event, opt) {
            const { item } = opt;
            const { documentTypeId, selectedDocId, approved, rejected } = item;
            const { workflowId } = $scope.data.ticket;
            if (rejected) {
                toaster.pop('info', "Rejected document can't be approved.");
                return;
            }
            if (approved) {
                toaster.pop('info', "Document is already approved.");
                return;
            }
            const data = { docId: selectedDocId, documentTypeId, workflowId };
            DocumentService.approve(data)
                .then(result => {
                    const { status, data, errors } = result;
                    if (status) {
                        toaster.pop("success", data.message);
                        fetchTicketHistory();
                        refreshDocumentPrereqStatus();
                    } else {
                        toaster.pop("error", "Document not approved", errors[0].message);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toaster.pop("error", "Error in document approval", err.message);
                });
        });

        $scope.$on('workflow:ticket:rejectDirect', function(event, opt) {
            $mdDialog
                .show({
                    templateUrl: autoImportNgTemplateLoaderTemplate1,
                    controller: "RejectDocCtrl",
                    clickOutsideToClose: true,
                    preserveScope: true,
                    autoWrap: true,
                    resolve: {}
                })
                .then(result => {
                    if (result) {
                        const { item } = opt;
                        const { documentTypeId, selectedDocId, approved, rejected } = item;
                        const { workflowId } = $scope.data.ticket;
                        let { comment, reason } = result;
                        if (!comment) {
                            comment = reason.title;
                        }
                        const data = {
                            docId: selectedDocId,
                            documentTypeId,
                            workflowId,
                            notes: comment,
                            label: reason.title
                        };
                        DocumentService.reject(data).then(result => {
                                const { status, data, errors } = result;
                                if (status) {
                                    toaster.pop("success", data.message);
                                    fetchTicketHistory();
                                    refreshDocumentPrereqStatus();
                                } else {
                                    toaster.pop("error", "Document not rejected", errors[0].message);
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                toaster.pop("error", "Error in document rejected", err.message);
                            });
                    }
                });
        });
    };

    const fetchTicketHistory = () => {
        $scope.historiesLoaded = false;
        Tickets.getTicketHistory($scope.data.ticket).then(function(result) {
            const histories = result.data.data.formattedHistory || [];
            updateThumbnail(histories)
        })
    };

    const updateThumbnail = (histories) => {
        histories.forEach(history => {
            if (history.thumbnail) {
                history.thumbnail.content = DocumentService.toBase64(history.thumbnail.content.data)
            }
            if(history.notes && history.eventParams){
                let tempNotes = history.notes.split('#')
                tempNotes.forEach((iter,i) => {
                    if(history.eventParams[iter]){
                        tempNotes[i] = moment(history.eventParams[iter]).format("MMM DD, YYYY")
                    }
                })
                tempNotes = tempNotes.join(' ')
                history.notes = tempNotes
            }
        });
        $scope.historiesLoaded = true;
        $scope.histories = histories;
    };

    const refreshDocumentPrereqStatus = () => {
        const { workflowId } = $scope.data.ticket;
        Tickets.getDocumentsCache('ticket', workflowId).then(function(response) {
            response = response.data;
            const { status, data, errors } = response;
            if (status) {
                DocumentService.updateDocumentPrerequisites($scope.data.state.prerequisites, data);
            }
        }).catch((err) => {
            console.error('Get documents cache failed', err);
        });
    };

    var init = function() {
        registerForEvents();
        Tickets.getInteractions().then(function(response) {
            $scope.interactions = response.data.data;
        });
        Tickets.getTicket($stateParams.ticketId).then(function(data) {
            if (data.data.productId && data.data.productId.name) {
                data.data.productName = data.data.productId.name;
            }
            $scope.data.ticket = data.data.data;
            $scope.pageHeader = $scope.data.ticket['type']
            /**
             * Change the format of p2pDueDate for UI view
             */
            if($scope.data.ticket['p2pDueDate']){
                $scope.data.ticket['formattedP2PDueDate'] = moment($scope.data.ticket['p2pDueDate']).format("MMM DD, YYYY")
            }
            $scope.data.ticket.createdAt = moment($scope.data.ticket['createdAt']).format("MMM DD, YYYY")
            $scope.data.ticket.updatedAt = moment($scope.data.ticket['updatedAt']).format("MMM DD, YYYY")
            Tickets.getNextStates($stateParams.ticketId, me._id).then(function(data) {
                Tickets.getTicketHistory($scope.data.ticket).then(function(result) {
                    const histories = result.data.data.formattedHistory || [];

                    updateThumbnail(histories);
                    $scope.data.nextStates = data.data.data;
                    if ($stateParams.statusUpdate === 'true') {
                        $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
                        $scope.data.stateSelected = true;
                        $scope.params.updateStatus = true;
                    }
                })
            }).catch(function(err) {
                console.error('Ticket get next states API failed', err);
                toaster.pop('error', "Can't update ticket now");
            })
        }).catch(function(err) {
            console.error('Get ticket API failed', err);
            toaster.pop('error', "Can't get the ticket");
        });

        Tickets.getFutureFollowUps($stateParams.ticketId).then(response => {
            if (response.data.status) {
                let followUps = response.data.followUps;
                let dayStart = new Date().setHours(0, 0, 0, 0);
                let dayEnd = new Date().setHours(23, 59, 59, 999);

                _.forEach(followUps, (followUp) => {
                    let date = new Date(followUp.scheduledAt);
                    if (dayStart <= date && date <= dayEnd) {
                        followUp.isToday = true;
                    }
                });
                $scope.followUps = _.sortBy(followUps, 'scheduledAt');
            }
        })
    };

    var updateWorkflow = function(prerequisites) {
        var params = {
            _id: $scope.data.ticket._id,
            userId: me._id,
            workflowId: $scope.data.ticket.workflowId,
            nextStateId: $scope.data.state.state,
            prerequisites: prerequisites
        };
        $scope.errors = [];
        return Tickets.gotoState(params);
    };

    $scope.onStateChange = function() {
        $scope.data.stateSelected = false;
        $scope.errors = [];
        checkInteractionRequired();
        $timeout(function() {
            $scope.data.stateSelected = true;
        });
    };

    function checkInteractionRequired() {
        $scope.interactionService.show = true;
        if ($scope.data.state && $scope.data.state.prerequisites && $scope.data.state.prerequisites.length) {
            if (_.find($scope.data.state.prerequisites, { name: 'interaction' })) {
                $scope.interactionService.show = false;
            }
        }
    }

    $scope.updateCheckBox = function() {
        if ($scope.params.updateStatus) {
            $scope.data.state = $scope.data.nextStates[0];
            $scope.data.stateSelected = true;
        } else {
            $scope.data.state = null;
            $scope.data.stateSelected = false;
        }
        checkInteractionRequired();
    };

    var saveInteraction = function() {
        var interactionData = {
            _id: $scope.data.ticket._id,
            workflowId: $scope.data.ticket.workflowId,
            userId: me._id,
            prerequisites: [
                { name: 'notes', value: $scope.interactionService.notes ? $scope.interactionService.notes : "" },
                { name: 'category', value: 'ticket' }
            ]
        };
        if($scope.interactionService.interaction){
            interactionData.prerequisites.push({ name: 'interaction', value: $scope.interactionService.interaction.internalId })
            interactionData.prerequisites.push({ name: 'interactionOutput', value: $scope.interactionService.interactionOutput.internalId });
        }
        /** if follow-up not set then set tomorrow as follow-up **/
        // if(!$scope.interactionService.followupDate){
        //     let tmrw = new Date();
        //     tmrw = new Date().setDate(new Date().getDate() + 1);
        //     $scope.interactionService.followupDate = new Date(tmrw);
        // }
        let followUp = $scope.interactionService.followupDate;

        if (followUp) {
            /** check if the user selected the follow-up from previously created list, if yes then do not create again **/
            let isFollowUpExists = _.find($scope.followUps, (date) => {
                return new Date(date.scheduledAt).getTime() === new Date(followUp).getTime();
            });

            if (!isFollowUpExists) {
                interactionData.prerequisites.push({ name: 'followUp', value: new Date($scope.interactionService.followupDate) });

                if ($scope.interactionService.followUpType) {
                    interactionData.prerequisites.push({ name: 'followupType', value: $scope.interactionService.followUpType.internalId })
                    if ($scope.interactionService.address) interactionData.prerequisites.push({ name: 'address', value: $scope.interactionService.address })
                }
            }
        }
        interactionData.cui = $scope.data.ticket.cui

        return Tickets.saveInteraction(interactionData);
    };

    $scope.onSubmit = function() {
        if ($scope.interactionService.show && ($scope.interactionService.interaction || $scope.interactionService.followupDate)) {
            return saveInteraction().then(function(result) {
                if (result.data.status) {
                    if ($scope.params.updateStatus) {
                        updateStatus();
                    } else {
                        //Only interaction update case
                        $scope.onCancel();
                        toaster.pop('success', "Interaction", "Interaction successfully saved.");
                    }
                } else {
                    toaster.pop('error', "Interaction", "Interaction update failed.");
                }
            }, function(error) {
                console.log(error);
                toaster.pop('error', "Ticket", "Update is failed.");
            });
        } else {
            if ($scope.params.updateStatus) {
                updateStatus();
            }
        }

        function updateStatus() {
            updateWorkflow($scope.data.state.prerequisites).then(function(response) {
                if (response.data.status) {
                    $scope.errors = [];
                    toaster.pop('success', "Ticket", "Update is successful.");
                    $scope.onCancel();
                } else {
                    toaster.pop('error', "Ticket", "There were some errors in updating the ticket. Please fix and try again.");
                    $scope.errors = response.data.errors;
                }
            }).catch(function(err) {
                console.error('Ticket goto state API failed', err);
                $scope.errors = err.data.errors;
                toaster.pop('error', "Ticket", "Update is failed.");
            });
        }
    };

    function searchComponent(query, key, key2) {
        //var lowercaseQuery = query.toLowerCase();
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if (key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if (ismatched)
                return true;
            else
                return false;
        };
    }

    //---angular material -----
    $scope.searchList = function(query, list, key, key2) {
        var results = query ? list.filter(searchComponent(query, key, key2)) : list;
        return results;
    }

    $scope.selectInteraction = function() {
        $scope.interactionOutputs = $scope.interactionService.interaction ? $scope.interactionService.interaction.outcome : [];
        $scope.interactionService.interactionOutput = $scope.interactionOutputs[0];
    }

    $scope.onCancel = function() {
        var ticketParams = {
            tab: $scope.data.ticket.productId._id,
            userId: me._id
        };
        $state.go('lms.tickets', ticketParams);
        // $window.history.back()
    };

    $scope.setFollowUp = function(date) {
        $scope.interactionService.followupDate = new Date(date);
    }

    $scope.gotoCustomer = function(cui) {
        if (cui) {
            $state.go('userFlow.customer', { customerId: cui });
        }
    };
    init();
});