const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/leadTableCustomer.html');

'use strict';

angular.module('bitnudgeApp')
    .directive('leadTableCustomer', function () {
        return {
            name: 'leadTableCustomer',
            scope: {
                leadList:     '=',
                fetchLeads: '=',
                selectedLead:  '=',
                pagination: '=',
                apiLoaded: '=',
                previousPage: '=',
                nextPage: '=',
                archiveLead: '=',
                isReferralsView: '=',
                unassignedLeads: '=',
                selectedProductFilter: '=',
                filterObj: '=',
                showSelectedLead: '=',
                isCustomerView: '=',
                showLostLeads: '=',
                sortingObj: '='
            },
            controller: 'leadTableCustomerCtrl',
            restrict: 'EA',
            templateUrl: autoImportNgTemplateLoaderTemplate1
        };
    });
