'use strict';

angular.module('bitnudgeApp')
	.service('HotKeyService', function ($http, $q, $rootScope) {
		this.hotKeysEnum = {
			brochure:'brochure',
			eform:'eform',
			docusign:'docusign',
			pubnub:'pubnub',
			securechat:'securechat',
			jitsi:'jitsi'
		}
		this.hotKeys = [];
		if($rootScope.config.brochureHotKey){
			this.hotKeys.push({
				key: 'brochure',
				name: 'Document',
				icon: 'brochure',
				artifactType: 'document'
			})
		}
		if($rootScope.config.eformHotKey){
			this.hotKeys.push({
				key: 'eform',
				name: 'E-Form',
				icon: 'eform',
				artifactType:'eform'
			})
		}
		if($rootScope.config.docusignHotKey){
			this.hotKeys.push({
				key: 'docusign',
				name: 'E-Sign',
				icon: 'docusign',
				artifactType:'docusigntemplate'
			})
		}
		if($rootScope.config.chatHotKey){
			this.hotKeys.push({
				key: 'pubnub',
				name: 'Chat',
				icon: 'chat',
				artifactType:'pubnub-chat'
			})
		}
		if($rootScope.config.securechatHotKey){
			this.hotKeys.push({
				key: 'securechat',
				name: 'Chat',
				icon: 'chat',
				artifactType:'secure-chat'
			})
		}
		if($rootScope.config.skavHotKey){
			this.hotKeys.push({
				key: 'jitsi',
				name: 'Video Meeting',
				icon: 'jitsi',
				artifactType:'jit-si'
			})
		}
		this.getHotKeyListings = (hotKey, options) => {
			const deferred = $q.defer();
			let url = `/api/hotKeys/list`
			$http.post(url, {
				hotKey:hotKey.key,
				...options
			}).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.shareLink = (data)=>{
            const deferred = $q.defer();
			let url = `/api/hotKeys/shareLink`
			$http.post(url, data).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
        }
        
        this.sendSMS = (data) => {
            const deferred = $q.defer();
			let url = `/api/hotKeys/sendSms`
			$http.post(url,data).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
		
		this.getReviewToken = (linkCode)=>{
			const deferred = $q.defer();
			let url = `/api/hotKeys/reviewToken`
			$http.post(url,{linkCode}).then(
				result => {
					deferred.resolve(result.data);
				},
				error => {
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
	})