'use strict';

angular.module('bitnudgeApp').controller('addLeadCtrl_v2', function ($scope, $rootScope, $state, $mdDialog, Auth,
    leadsService, toaster, p0, countryList, $window, $stateParams, $http) {
    const me = Auth.getCurrentUser();
    $scope.isMiddleManager = $rootScope.isMiddleManager;
    $scope.strings = p0.strings;
    $scope.isNotDropDown = false;
    $scope.data = {};
    $scope.currentUser = me;
    $scope.number;
    $scope.initPageLoad = true;
    $scope.isEditable = false;
    $scope.leadData = {}
    $scope.allRoles = [];
    $scope.countryCodeList = leadsService.leadCountryCodeList;
    $scope.config = p0.config;
    var workflowDefinitionParams = {};
    if (me.roleType === 'manager') {
        $scope.allRoles = [$scope.strings.MANAGER.value.toUpperCase(), $scope.strings.FRONTLINE.value.toUpperCase()];
    }else if (me.roleType === 'seniorManager'){
        $scope.allRoles = [$scope.strings.SENIORMANAGER.value.toUpperCase(), $scope.strings.MANAGER.value.toUpperCase(), $scope.strings.FRONTLINE.value.toUpperCase() ];
    } else {
        $scope.allRoles = [$scope.strings.FRONTLINE.value.toUpperCase()]
    }
    $scope.selectedRole = $scope.allRoles[0];

    function fetchUsersList(roleType) {
        $scope.dataLoaded = false;
        if (me.roleType === 'seniorManager') {
            fetchUsersListForSeniorManager(roleType)
        }
    }

    function fetchUsersListForSeniorManager(roleType) {
        $scope.userToggleSwitch = true;
        if (roleType === $scope.strings.MANAGER.value.toUpperCase()) {
            populateProducts(false)
            //get all the managers of the loggedin seniormanager
            let myPlayTypes = p0.myPlayTypes;
            let managerIds = []
            myPlayTypes.forEach(function (playType) {
                managerIds = _.concat(managerIds, playType.managers)
            });
            managerIds  = _.map(managerIds, function(id){
                return id.toString();
            });

            /*Show only managers who has fronline under him. do not show dummy  managers in the list*/
            let managerRoles = _.filter(p0.allJobRoles, function(role){
                return role.hasFrontline && _.includes(managerIds, role._id.toString());
            });
            managerIds = _.map(managerRoles, function(role){
                return role._id.toString();
            });

            $http.post('/api/users/getAllLeafJobRoles', {
                reporteeJobRoles: managerIds,
                userId: me._id
            }).then(function (result) {
                result=result.data.data;
                $scope.dataLoaded = true;
                $scope.myTeam = result;
                $scope.data.user = $scope.myTeam[0]
                $scope.selectedUser = $scope.data.user
            })
        } else if (roleType === $scope.strings.SENIORMANAGER.value.toUpperCase()) {
            populateProducts(false)
            $http.post('api/users/getAllSeniorManagers/',{userId:me._id}).then(function(result){
                result = result.data;
                if(true || p0.config.leadReassignToSelf){
                    result.unshift(me);
                }
                $scope.dataLoaded = true;
                $scope.myTeam = result;
                $scope.data.user = $scope.myTeam[0]
                $scope.selectedUser = $scope.data.user
            })
        }
        else {
            populateProducts(true)
            //get all the frontlines of the loggedin seniormanager
            $http.get('/api/users/getAllLeaf/' + me._id).then(function (result) {
                result = result.data.data;
                
                 //get all the frontline roles of the loggedin seniormanager
                let myPlayTypes = p0.myPlayTypes;
                let frontlineRoles = []
                myPlayTypes.forEach(function (playType) {
                    frontlineRoles = _.concat(frontlineRoles, playType.frontline)
                });
                frontlineRoles  = _.map(frontlineRoles, function(id){
                    return id.toString();
                });
                
                result = result.filter(function (user) {
                    return _.includes(frontlineRoles, user.organizationalTag.jobRole._id.toString());
                })
                $scope.dataLoaded = true;
                $scope.myTeam = result;
                $scope.data.user = $scope.myTeam[0]
                $scope.selectedUser = $scope.data.user
            })
        }
    }

    $scope.changeHandler = function (selectedUser) {
        $scope.selectedUser = selectedUser;
    }
    //on user toggle change update the userlist
    $scope.changeUsers = function (roleType) {
        roleType = roleType.toUpperCase();
        if (me.roleType === 'frontline') {
            return;
        } else if (me.roleType === 'manager') {
            if (roleType === $scope.strings.MANAGER.value.toUpperCase()) {
                populateProducts(false)
                $scope.myTeam = [me];
                $scope.data.user = $scope.myTeam[0]
                $scope.selectedUser = $scope.myTeam[0];
                return;
            } else {
                populateProducts(true)
                leadsService.getMyTeam().then(function (response) {
                    $scope.myTeam = response.data;
                    $scope.data.user = $scope.myTeam[0]
                    $scope.selectedUser = $scope.data.user
                }, function (err) {
                    console.log(err)
                })
            }
        } else {
            fetchUsersListForSeniorManager(roleType)
        }
    }

    var createLead = function (leadData) {

        let postPayload = {
            contact: leadData.customer,
            product: { internalId: leadData.product._id },
            subProduct: { internalId: leadData.subProduct ? leadData.subProduct.internalId : "Random"},
            //DEMO: new client doesnt have lead type and lead sub type
            // leadType: { internalId: leadData.type.internalId },
            // leadSubType: { internalId: leadData.subType.internalId },
            amount: leadData.amount,
            leadSource: leadData.leadSource
        };
        if ($rootScope.isFrontline) {
            postPayload.userId = me._id;
        } else {
            postPayload.userId = $scope.selectedUser._id;
        }
        if(leadData.type && leadData.type.internalId == 'campaign'){
            postPayload.campaignCode = leadData.campaignCode;
        }
        
        //for demo purposes
        postPayload.downPayment = leadData.downPayment;
        postPayload.branch = leadData.branch;
        postPayload.amount = leadData.amount;
        postPayload.note = leadData.note;
        postPayload.projectName = leadData.projectName;

        leadsService.createLead(postPayload).then(function (response) {
            let responseData = response.data;
            if (responseData.status) {
                $scope.errors = [];
                toaster.pop('success', 'Lead creation successful.');
                const params = {}
                params.leadId = responseData.data.lead._id;
                if ($state.params.tab) {
                    params.productId = $state.params.tab;
                }
                $state.go(p0.config.leadUI_v1 ? 'userFlow.editLead_v1':'userFlow.editLead_v2', params)
            } else {
                $scope.errors = responseData.errors;
                toaster.pop('error', 'Lead creation failed.');
            }
        }).catch(function (err) {
            console.error('CreateLead API failed ', err);
            toaster.pop('error', 'Lead creation failed.');
        });
    }
    const populateProducts = function(isFrontline){
        let prds = p0.myProducts;
        const leadProducts = [];
        if(isFrontline && p0.config.agentOnBoardingProduct){
            prds = prds.filter(p => p.productCode.toLowerCase() !== p0.config.agentOnBoardingProduct.toLowerCase())
        }
        prds.forEach(function (product) {
            //DEMO: only deposits and loans will be visible
            // if(product.name === 'Loans' || product.name === 'Deposits'){
            leadProducts.push({ name: product.shortName, _id: product._id, subProducts: product.subProducts })
            // }
        });
        $scope.leadProducts = leadProducts;
        if(leadProducts.length === 1){
            $scope.lead.product = $scope.leadProducts[0]
        }
    }
    var init = function () {
        $scope.lead = {};
        $scope.nationList = countryList.countries;
        fetchLeadSourcesList();
        populateProducts($rootScope.isFrontline)

        leadsService.getCampaigns().then((result)=>{
            result = result.data;

            let now = new Date();
            let campaigns = _.filter(result, (campaign) =>{
                return new Date(campaign.startDate) <= now && new Date(campaign.endDate) >= now;
            });
            $scope.campaigns = campaigns;

            let leadTypes = [];
            p0.leadTypes.forEach(function (type) {
                if((type.internalId == 'campaign' && campaigns.length) || type.internalId !== 'campaign'){
                    leadTypes.push({ name: type.shortName, internalId: type.internalId, subTypes: type.subTypes })
                }
            })
            $scope.leadTypes = leadTypes;

            if ($rootScope.isMiddleManager) {
                leadsService.getActiveFrontlines().then(function (result) {
                    $scope.frontLineList = result;
                    if(!result.length){
                        $scope.allRoles = [$scope.strings.MANAGER.value.toUpperCase()];
                    }
                })
            }

            if (me.roleType == "frontline") {
                $scope.myTeam = [me];
                $scope.data.user = $scope.myTeam[0]
                $scope.onSelectUser()
            } else {
                $scope.changeUsers($scope.selectedRole);
            }

        }).catch((error) =>{
            console.log(error)
            toaster.pop('Error', 'Error in fetching campaigns details')
        })
    };

    $scope.onSelectUser = function () {
        $scope.selectedUser = $scope.data.user
    }

    $scope.onSubmit = function (form) {
        if (form.$valid) {
            let formData = $scope.lead
            if(!formData.customer){
                formData.customer = {};
            }
            if($scope.leadData.customer){
                const { firstName, middleName, lastName } = $scope.leadData.customer
                if(firstName){
                    $scope.leadData.customer.name = firstName;
                    formData.customer.firstName = firstName;
                }
                if(middleName){
                    $scope.leadData.customer.name += ' '+middleName;
                    formData.customer.middleName = middleName;
                }
                if(lastName){
                    $scope.leadData.customer.name += ' '+lastName;
                    formData.customer.lastName = lastName;
                }
                formData.customer.name = $scope.leadData.customer.name
            }
            formData.customer.contactNumber = $scope.contactNumber
            const { cui, email } = $scope.leadData.customer;
            if(cui){
                formData.customer.cui = cui;
            }
            if(email){
                formData.customer.email = email;
            }

            //for demo purpose
            formData.customer.customerAge = $scope.leadData.customer.age;
            formData.customer.city = $scope.leadData.customer.city;
            formData.customer.job = $scope.leadData.customer.job;
            formData.customer.jobType = $scope.leadData.customer.jobType;
            formData.customer.customerStatus = $scope.leadData.customer.customerStatus
            formData.customer.mainSalary = $scope.leadData.customer.mainSalary;
            formData.customer.housingAllowance = $scope.leadData.customer.housingAllowance;
            formData.customer.deductions = $scope.leadData.customer.deductions;
            formData.customer.totalSalary = $scope.leadData.customer.totalSalary;
            formData.customer.salaryTBN = $scope.leadData.customer.salaryTBN;
            formData.customer.company = $scope.leadData.customer.company;
            formData.downPayment = $scope.leadData.downPayment;
            formData.branch = $scope.leadData.branch;
            formData.note = $scope.leadData.note;
            formData.projectName = $scope.leadData.projectName;
            
            formData.amount = $scope.leadData.amount;
            formData.leadSource = $scope.leadData.source;
            createLead(formData)
        }
    }
    $scope.onCancel = function () {
        // if ($state.params.tab)
        //     $state.go('lms.listLead', { productId: $state.params.tab });
        // else {
        //     $state.go('lms.listLead')
        // }
        $window.history.back()
    }
    $scope.numberChange = (contact, fromState) => {
        if(contact.$valid || fromState) {
            $scope.initPageLoad = false;
            // const number = contact.$modelValue ? contact.$modelValue : fromState;
            let number = $scope.countryCode;
                number += ''+ contact.$modelValue ? contact.$modelValue : $scope.phoneNumber;
            // $scope.phoneNumber = number;
            leadsService.getCustomerByPhoneNumber(number).then((response) => {
                if (response.data.allow) {
                    $scope.errors = null;
                    $scope.isEditable = false;
                    $scope.isNotDropDown = true;
                    if (response.data.details) {
                        $scope.leadData.customer = response.data.details
                        const names = $scope.leadData.customer.name.split(" ")
                        $scope.leadData.customer.name = names[0]
                        $scope.leadData.customer.middleName = names[1] || ""
                        $scope.leadData.customer.lastName = names[2] || ""
                    }else{
                       // $scope.leadData = {}
                        $scope.isEditable = true;
                    }

                } else {
                    toaster.pop('success', 'success', 'You can\'t create a lead with this number This number is being used as a contact number for multiple people. Continue and update number to a unique number or choose an existing customer' );
                    // $mdDialog.show(confirm).then(function() {
                    //     $scope.isExistingCustomer = false;
                    //     $scope.isEditable = true;
                    // }, function(err) {
                    //     $scope.isExistingCustomer = false;
                    // });
                    $scope.lead = {}
                    $scope.isExistingCustomer = false;
                    $scope.isEditable = true;
                }
            }).catch(err => {
                console.log(err);
                $scope.errors = [err.data];
                $scope.initPageLoad = true;
                $scope.leadData = {}
            })
        }
    }
    function searchComponent(query,key, key2) {
        var ismatched = false;
        var re = new RegExp(query, 'gi');
        return function filterFn(list) {
            if(key2)
                ismatched = list[key].match(re) || list[key2].match(re);
            else
                ismatched = list[key].match(re);

            if(ismatched)
                return true;
            else
                return false;
        };
      }

    //---angular material -----
    $scope.searchList = function(query,list,key, key2) {
        var results = query ? list.filter( searchComponent(query, key, key2) ) : list;
        return results;
    }

    $scope.updateNumber = function(customer) {
        if(customer)
        {
            const {countryCode, number } = customer.primaryContactNumber;
            $scope.countryCode = countryCode
            $scope.phoneNumber = number;
                
        } else
            $scope.phoneNumber = "";
    }

    leadsService.getCustomerNames(me._id).then(function (response) {
        $scope.customersList = response.data.data;
        $scope.data.customer = $scope.customersList[0]
    }, function (error) {
        console.log(error)
    });
    $scope.existingCustomer = function(){
        if(!$scope.isExistingCustomer){
            $scope.initPageLoad = false
            $scope.isNotDropDown = false;
            if($scope.leadData.customer && !$scope.leadData.customer.name.length){
                delete $scope.leadData.customer
            }
        }else{
            $scope.initPageLoad = true;

        }
    }

    function fetchLeadSourcesList() {
        leadsService.fetchLeadSourceList().then(function (response) {
            const allLeadSources = response.data && response.data.data;
            const defaultLeadSource = allLeadSources.find(source => {
                return source.defaultConfig && source.defaultConfig.toLowerCase().replace(/\s/g,'') === $scope.currentUser.roleType.toLowerCase()
            });
            const referralLeadSource = allLeadSources.find(source => source.internalId === 'Referral');
            const selfGeneratedSource = allLeadSources.find(source => source.internalId === 'SelfGenerated');
            if (me.roleType !== 'frontline') {
                $scope.leadSourceList = [defaultLeadSource, selfGeneratedSource, referralLeadSource];
            } else {
                $scope.leadSourceList = [defaultLeadSource, referralLeadSource];
            }
            $scope.leadData.source = defaultLeadSource && defaultLeadSource._id;
        }, function (err) {
            console.log(err)
        })
    }

    if($stateParams.number){
        $scope.numberChange('',$stateParams.number)
    }

    $scope.viewDocument = (lead) => {
        $scope.data = {}
        if(lead){
            leadsService.getNextStates(lead._id, me._id).then(function(response) {
                $scope.data.nextStates = response.data.data;
                $scope.workflowNoUpdateMessage = response.data.message
                $scope.data.state = $scope.data.nextStates.find(state => state.isNextPositiveState === true) || $scope.data.nextStates[0];
            }).catch(function(err) {
                console.error('Lead get next states API failed', err);
                toaster.pop('error', "Can't find the lead");
            });
            $scope.$emit(`workflow:lead-list:removePrerequisite`, { item })
        }
    }

    $scope.$on('workflow:lead-list:document', function(event, opt) {
        const { item } = opt;
        const { workflowId, contact, cui } = $scope.data.lead;
        const isApproved = item.name.startsWith("approve");
        const docPrerequisites = $scope.data.state.prerequisites.filter(p => p.type === 'document');
        const otherPrerequisites = docPrerequisites.filter(type => type !== item.typeParam);

        let documentTypes = otherPrerequisites.map(p => p.typeParam);
        documentTypes.unshift(item.typeParam);

        let documentIds = otherPrerequisites.filter(p => (p.selectedDocId || p.rejectedDocId)).map(p => p.selectedDocId || p.rejectedDocId);
        const refId = item.selectedDocId || item.rejectedDocId;
        if (refId) {
            documentIds.unshift(refId);
        }
        const dmsParameters = {
            documentTypes, //In case of select mode
            documents: documentIds, //In case of approve mode send document Ids
            isCustomerMode: false, //for customer mode, open DMS with edit option without selection or approve feature
            isApproveMode: isApproved, //for approve mode, open DMS with approve and reject option
            isSelectMode: !isApproved, //for selection mode, open DMS with lead or ticket context i.e workflow
            customer: {},
            workflowId,
            //cui: cui || contact.cui,
            context: 'lead'
        };
        $rootScope.$broadcast('dms:openModal', dmsParameters);

        if (onCloseListener) onCloseListener();
        onCloseListener = $scope.$on("dms:onCloseModal", () => {
            getLeadHistoryFunc();
            refreshDocumentPrereqStatus();
        });

    });

    $scope.myIntlTelInputCtrl;


    init();
});
