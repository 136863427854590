const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/team/listTeam.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/team/showTeam.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/team/addTeam.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/team/addBulkTeam.html');

'use strict';

angular.module('bitnudgeApp')
  .config(function ($stateProvider) {
    $stateProvider
        .state('lms.listTeam',{
            url: '/listTeam',
            templateUrl: autoImportNgTemplateLoaderTemplate1,
            controller: 'TeamCtrl'
        })
        .state('lms.showTeam', {
            url: '/showTeam/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: function ($stateParams, $scope, $http) {
                $http.post('/api/teams/',{id: $stateParams.id}).then(function (team) {
                    team=team.data;
                    $scope.teams = team;
                    //socket.syncUpdates('team', $scope.team);
                });
            }
        })
        .state('lms.addTeam', {
            url: '/addTeam/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: function ($stateParams, $scope, $http, $location,  toaster, Auth) {
                $scope.editForm = false;
                if($stateParams.id){
                    $scope.editForm = true;
                    $http.post('/api/teams',{id:$stateParams.id}).then(function (team) {
                        team=team.data;
                        $scope.currentTeam = team;
                        $scope.currentTeam.orgId = Auth.getCurrentUser().orgId;
                        $http.post('/api/users/getUserById',{userId:team.teamLeaderId._id}).then(function (user) {
                            user=user.data;
                            $http.post('/api/users/getUsersOfJobRole',{jobRoleIds: [user.organizationalTag.jobRole._id]}).then(function (users) {
                                users=users.data;
                                $scope.users = users;
                            })
                        })
                    });
                }
                else{
                    $http.post('/api/teams').then(function (team) {
                        team=team.data;
                        $scope.currentTeam = team;});
                    $http.post('/api/users/').then(function (user) {
                            user=user.data;
                            $scope.users = user;
                        })
                }
                $http.post('/api/regions/getByOrgId',{orgId: Auth.getCurrentUser().orgId}).then(function (regions) {
                    regions=regions.data;
                    $scope.regions = regions;
                });
                $scope.updateTeam = function () {
                    var name = $("#team__name").val();
                    $http.post('/api/teams/' + $stateParams.id, { name: name}).then(function () {
                            $location.path('/team');
                            toaster.pop('success', "Team Update", "Team Details Updated.");

                        }).catch(function () {
                        });
                };


            },
            authenticate: true

        })
        .state('lms.addBulkTeam',{
            url: '/addBulkTeam',
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller:function ($http, $scope, Auth, Upload, $location, toaster) {
                $scope.fileType = "teams";
                $scope.onFileSelectTeam = function () {
                    if ($scope.bulkTeam == undefined) {
                        toaster.pop('error', "Submission.", "Select File to Upload.");

                    } else {
                        var fileName = $scope.bulkTeam[0].name.split(".");
                        var fileType = fileName[fileName.length - 1];
                        if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                            Upload.upload({
                                url: '/api/upload',
                                data: {userId: Auth.getCurrentUser()._id, orgId: Auth.getCurrentUser().orgId,cronTag:'uploadTeams',invocationMethod:"manual"},
                                file: $scope.bulkTeam[0],
                                progress: function (e) {
                                }
                            }).then(function (data, status, headers, config) {
                                // file is uploaded successfully
                                toaster.pop('success', "Excel uploaded", "Team creation successfully finished.");
                            });
                        } else {
                            toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                        }

                    }
                }

            }
        })
    });
