'use strict';
angular.module('bitnudgeApp')
    .service('DashboardService', function ($http,p0, $q, leadsService) {
        
        this.getTodaysFollowupActions = ({userId, timeRange, startDate, endDate, only}) => {
            const deferred = $q.defer();
            let url = `/api/actionboard`
            
            $http.post(url,{userId, timeRange, only,startDate, endDate }).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }
        this.getRatedLeads = ({userId, limit}) => {
            const deferred = $q.defer();
            let url = `/api/actionboard/leadsByRating`;
            $http.post(url,{userId, limit}).then(
                result => {
                    deferred.resolve(result.data);
                },
                error => {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }
        this.getLeads = async (filterObj, category, sortingObj, extraParams) => {
            try{
                const { data } = await leadsService.getLeads(filterObj, extraParams, { offset: 0, limit: 50 }, category, false, sortingObj)
                if (data.status) {
                    const { leads } = data.data;
                    return leads.docs
                }
                return []
            }catch(err){
                console.error(`Error in fetching leads`)
                return []
            }
        }
    });

