'use strict';

angular.module('bitnudgeApp')
    .controller('editEmployeeController', function ($scope, $mdDialog, $http, user, toaster, p0) {
        $scope.genders = [{ _id: 'm', name: 'Male' }, { _id: 'f', name: 'Female' }];
        $scope.countryCodes = [
            '+971',
            '+973',
            '+968',
            '+974',
            '+966'
        ];
        $scope.parents = [];
        $scope.roleTypes = [{ _id: 'FRONTLINE' }, { _id: 'MANAGER' }];
        $scope.roleTypes.forEach(function (roletType) {
            roletType.name = p0.strings[roletType._id].value;
            roletType.value = roletType.name.toLowerCase();
        });

        $scope.employee = {
            joinedAt: new Date(),
            countryCode: $scope.countryCodes[0],
            gender: $scope.genders[0]._id,
            organizationalTag: {}
        };

        setDefaultValuesForUser(user, $scope)


        $scope.processEmployee = function (employee, isValid) {
            if (isValid) {
                var middleName = employee.middleName ? employee.middleName : '';
                employee.fullName = employee.firstName + ' ' + middleName + ' ' + employee.lastName;
                employee.name = employee.firstName + ' ' + employee.lastName;
                employee.gender = employee.genderObj._id;
                employee.contactNumber = employee.countryCode + employee.mobileNumber;
                employee.updatedAt = new Date();

                if (employee.gender.toLowerCase() === 'm') employee.image = require('../images/avatarMale.jpg');
                else if (employee.gender.toLowerCase() === 'f') employee.image = require('../images/avatarFemale.jpg');
                else employee.image = require('../images/avatarMale.jpg');

                $http.post('/api/users/' + employee._id, employee).then(function (responce) {
                    //responce = responce.data;
                    toaster.pop('success', "User edit", "User edit successfuly");
                }).catch(function (err) {
                    if (err.errorCode == '409')$scope.employee.duplicate = true;
                    else console.log('editEmployeeController', err);
                });
            }
        };


        function setDefaultValuesForUser(user, $scope) {
            $scope.employee = user;
            $scope.employee.joinedAt = new Date()
        }

        $scope.onClose = function () {
            $mdDialog.cancel()
        }

    });
