const autoImportNgTemplateLoaderTemplate1 = require('../html/lms/leadQuickFilterModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/lms/allFilters.html');

angular.module('bitnudgeApp').service('leadsService', ['$http', '$q', '$mdDialog', 'Auth', 'UtilService', 'p0', '$rootScope', '$filter', 'WorkflowService',
	function ($http, $q, $mdDialog, Auth, UtilService, p0, $rootScope, $filter, WorkflowService) {
		var currentUser = '',
			modalObj = {},
			leadObj = {};
		var customerIds;
		var productFromCustomerPage;
		let self = this;
		this.setUploadModalObject = function (obj) {
			modalObj = obj;
		};
		
				this.leadCountryCodeList = this.countryList = [
					"+966",
					"+971",
						"+973",
						"+968",
						"+91",
						"+903",
						"+900",
						"+86",
						"+87",
						"+98",
						"+974",
						"+61",
						"+65",
						"+852",
						"+96",
						"+62"
					]

		this.getUploadModalObject = function () {
			return modalObj;
		};

		this.setCurrentUser = function (user) {
			currentUser = user;
		};

		this.getCurrentUser = function () {
			return currentUser;
		};

		this.setCustomerIds = function (input) {
			customerIds = input;
		}

		this.getCustomerIds = function () {
			return customerIds;
		};

		this.deleteCustomerIds = function () {
			customerIds = undefined;
		};

		this.setProductFromCustomerPage = function (product) {
			productFromCustomerPage = product;
		};

		this.getProductFromCustomerPage = function () {
			return productFromCustomerPage;
		};

		this.deleteproductFromCustomerPage = function () {
			productFromCustomerPage = undefined;
		};

		this.getSelectedLead = function () {
			return leadObj;
		};

		this.setSelectedLead = function (lead) {
			leadObj = lead;
		};


		this.getLeadsByManager = function (url) {
			var deferred = $q.defer();
			$http.post(url).then(function (customers) {
				customers = customers.data;
				deferred.resolve(customers);
			});

			return deferred.promise;
		};
//
		this.getLeads = function (filterObj, extraParams, pagination, category, isCustomerView, sortingObj) {
			console.log('I am here in getLeads###########')
			const baseUrl = '/api/leads/getLeads';
			const postBody = {category};
			console.log('filterObj.externalParams: ', filterObj.externalParams);
			if (filterObj.externalParams && !_.isEmpty(filterObj.externalParams)) {
				postBody.externalParams = JSON.stringify(filterObj.externalParams);
				postBody.productId = 'Others';
				postBody.userId = extraParams.userId;
				postBody.currentMonthlyCycleId = extraParams.currentMonthlyCycleId;
				postBody.selectedCycle = extraParams.selectedCycle;
			} else {
				if (filterObj.leadId) {
					postBody.leadId = filterObj.leadId.toString();
				}
				if (extraParams.cui) {
					postBody.cui = extraParams.cui;
				}
				if(filterObj.selectedCampaignCode) {
					postBody.selectedCampaign = filterObj.selectedCampaignCode.toString();
				}
				if (extraParams.userId) {
					postBody.userId = extraParams.userId.toString();
				}
				if (extraParams.currentMonthlyCycle) {
					postBody.startDate = new Date(extraParams.currentMonthlyCycle.startDate).getTime();
					postBody.endDate = new Date(extraParams.currentMonthlyCycle.endDate).getTime();
				}
				if (extraParams.selectedCycle) {
					postBody.dateFilterStartDate = new Date(extraParams.selectedCycle.startDate).getTime();
					postBody.dateFilterEndDate = new Date(extraParams.selectedCycle.endDate).getTime();
				}
	
				if (extraParams.productId) {
					postBody.productId = extraParams.productId.toString();
				} else if (filterObj.productId) {
					postBody.productId = filterObj.productId.toString();
				}
				if (filterObj.searchParam) {
					postBody.searchParam = btoa(filterObj.searchParam);
				}
				if (filterObj.leadStatusKey) {
					postBody.leadStatusKey = filterObj.leadStatusKey;
					if (filterObj.leadStatusKey == 'status') {
						postBody.status = filterObj.status;
					}
				}
				if (filterObj.actNowFilterKey) {
					postBody.actNowKey = filterObj.actNowFilterKey;
				}
				if (filterObj.leadDateKey) {
					postBody.leadDateKey = filterObj.leadDateKey;
				}
				if (filterObj.leadTypeKey) {
					postBody.leadTypeKey = filterObj.leadTypeKey;
				}
				if (filterObj.statusCode) {
					postBody.statusCode = filterObj.statusCode;
				}
				if (filterObj.colorCode) {
					postBody.colorCode = filterObj.colorCode
				}
				if (filterObj.stageCode) {
					postBody.stageCode = filterObj.stageCode.stageUid
				}
				if (filterObj.campaignCode) {
					postBody.campaignCode = filterObj.campaignCode
				}
				if (filterObj.customerObj && Object.keys(filterObj.customerObj).length) {
					var customer = {};
					if (filterObj.customerObj.age) {
						customer.ageFrom = filterObj.customerObj.age.from;
						customer.ageTo = filterObj.customerObj.age.to;
					}
					if (filterObj.customerObj.dnc) {
						customer.dnc = filterObj.customerObj.dnc._id
					}
					if (filterObj.customerObj.marital) {
						customer.maritalStatus = filterObj.customerObj.marital._id
					}
	
					postBody.customerObj = JSON.stringify(customer);
				}
				if (isCustomerView) {
					postBody.isCustomerView = true;
				}
				if (filterObj.leadSource) {
					postBody.leadSource = filterObj.leadSource._id;
				}
				if(filterObj.workflowIds){
					postBody.workflowIds = filterObj.workflowIds;
				}
			}
			if(filterObj.unassignedView){
				postBody.unassignedView = filterObj.unassignedView;
			}
			postBody.offset = pagination.offset;
			postBody.limit = pagination.limit;
			if (sortingObj && !_.isUndefined(sortingObj)) {
				postBody.sortKey = sortingObj.sortKey;
				postBody.sortOrder = sortingObj.sortOrder;
			}
			postBody.customerObj = customer;
			if(filterObj.isLeadCustomer) {
				postBody.isLeadCustomer = true;
			}
			console.log('postBody: ', postBody)
			return $http.post(baseUrl, postBody);
		}

		this.filterArchivedLeads = function (leads) {
			var filteredLists = [];
			filteredLists = _.filter(leads, {
				archived: true
			});
			return filteredLists;
		};

		this.archiveLead = function (leadId) {
			var deferred = $q.defer();
			$http.post('/api/leads/archiveLead',{ leadId}).then(function () {
				deferred.resolve(true);
			});
			return deferred.promise;
		};

		this.setContactedStatus = function (lead) {
			var deferred = $q.defer();
			$http.post('/api/leads/contacted',{ leadId:lead._id}).then(function () {
				deferred.resolve(true);
			});
			return deferred.promise;
		};

		this.getAllProductsForCustomer = function (customerId) {
			var deferred = $q.defer();
			$http.post('/api/customers/getAllAvailableProducts',{cui:customerId} ).then(function (products) {
				products = products.data;
				deferred.resolve(products);
			});
			return deferred.promise;
		};
		this.validateRimNumber = function (leadInfo) {
			var deferred = $q.defer();
			$http.post('/api/customers/checkUnique/rimNumber',{rimNumber: leadInfo.rimNumber, contactNumber: leadInfo.contactNumber}).then(function (response) {
				response = response.data;
				deferred.resolve(response);
			});
			return deferred.promise;
		};

		this.validateApplicationId = function (leadInfo) {
			var deferred = $q.defer();
			$http.post('/api/leads/checkUnique', {applicationId: leadInfo.applicationId, leadId: leadInfo.leadId}).then(function (response) {
				response = response.data;
				deferred.resolve(response);
			});
			return deferred.promise;
		};
		this.getCustomerData = function (customerId, leadId) {
			var deferred = $q.defer();
			const postBody = {
				customerId,
				leadId
			}
			$http.post('/api/leads/getAllProductsByCustomer', postBody).then(function (custData) {
				custData = custData.data;
				deferred.resolve(custData);
			});
			return deferred.promise;
		};
		this.followLead = function (leadId) {
			var deferred = $q.defer();
			$http.post('/api/leads/followLead',{leadId}).then(function (responce) {
				responce = responce.data;
				deferred.resolve(responce);
			});
			return deferred.promise;
		};
		this.unFollowLead = function (leadId) {
			var deferred = $q.defer();
			$http.post('/api/leads/unFollowLead', {leadId}).then(function (responce) {
				responce = responce.data;
				deferred.resolve(responce);
			});
			return deferred.promise;
		};


		function getCustomerObj(data) {
			var obj = {
				name: data.customerName,
				number: data.contactNumber,
				email: data.emailAddress,
				rimNumber: data.rimNumber,
				nationality: data.nationality,
				source: data.source,
				userId: data.userId,
				_id: data._id,
			};
			return obj;
		}

		this.checkCustomerUpdate = function (data) {
			var deferred = $q.defer();
			var customerObj = getCustomerObj(data);
			customerObj.lead = data.lead;
			$http.post('/api/customers/checkCustomerUpdate', customerObj).then(function (custData) {
				custData = custData.data;
				deferred.resolve(custData);
			});
			return deferred.promise;
		};

		this.updateCustomer = function (data) {
			var deferred = $q.defer();
			var customerObj = getCustomerObj(data);
			customerObj.lead = data.lead;
			customerObj.duplicateLeadInfo = data.duplicateLeadInfo;
			$http.post('/api/customers/updateCustomerDetails', customerObj).then(function (custData) {
				custData = custData.data;
				deferred.resolve(custData);
			});
			return deferred.promise;
		};
		this.validateCustomer = function (data) {
			var deferred = $q.defer();
			var customerObj = getCustomerObj(data);
			$http.post('/api/customers/validateCustomer', customerObj).then(function (custData) {
				custData = custData.data;
				deferred.resolve(custData);
			});
			return deferred.promise;
		};
		this.showQuickFilterModal = function (filters, allLeads, leads, callback) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate1,
				controller: 'leadQuickFilterModalController',
				clickOutsideToClose: true,
				fullscreen: false,
				resolve: {
					filters: function () {
						return filters;
					},
					allLeads: function () {
						return allLeads;
					},
					leads: function () {
						return leads;
					},
					callback: function () {
						return callback;
					}
				}
			});
		};

		this.showAllFilterModal = function (filters, selectedRole, isReferralsView, onApplyFilterCall, workflowColorCodes, workflowStagesCodes, callback) {
			$mdDialog.show({
				templateUrl: autoImportNgTemplateLoaderTemplate2,
				controller: 'allFilterController',
				clickOutsideToClose: true,
				fullscreen: false,
				resolve: {
					filters: function () {
						return filters;
					},
					selectedRole: function () {
						return selectedRole;
					},
					callback: function () {
						return callback;
					},
					workflowColorCodes: function () {
						return workflowColorCodes;
					},
					workflowStagesCodes: function () {
						return workflowStagesCodes;
					},
					isReferralsView: function () {
						return isReferralsView
					},
					onApplyFilterCall: function () {
						return onApplyFilterCall;
					}
				}
			});
		};


		this.lostLeadCount = 0;
		this.widthdrawnLeads;
		this.slippedLeads;
		var _this = this;

		this.fetchCustomers = function (mobile, email) {
			if (!email)
				email = undefined;
			return $http.post('/api/customers/fetchCustomers',{mobile: JSON.stringify(mobile), email}).then(function (customers) {
				customers = customers.data;
				return $q.resolve(customers);
			});
		};
		this.fetchLastSyncDate = function () {
			return $http.post('/api/lmsTrackers/status',{status:'LEAD_ADMIN_FILE_UPLOAD'}).then(function (lastSync) {
				lastSync = lastSync.data;
				return $q.resolve(lastSync);
			});
		};
		this.createNewCustomer = function (data) {
			var deferred = $q.defer();
			$http.post('/api/customers/createCustomer', {
				name: data.customerName,
				number: data.contactNumber,
				email: data.emailAddress,
				rimNumber: data.rimNumber,
				nationality: data.nationality,
				source: data.source,
				userId: data.id,
			}).then(function (custData) {
				custData = custData.data;
				deferred.resolve(custData);
			});
			return deferred.promise;
		};

		this.getPortfolioForCustomer = function (contactNumber) {
			return $http.post('/api/portfolio/checkPortfolioForUser',{contactNumber} ).then(function (portfoliosForUser) {
				portfoliosForUser = portfoliosForUser.data;
				return portfoliosForUser;
			})
		};

		var firstListFilter = [{
				key: 'new',
				name: 'MTD Leads'
			},
			{
				key: 'active',
				name: 'Active'
			},
			{
				key: 'hot',
				name: 'Hot'
			},
			{ key: 'notYetContacted', name: 'No Contact' },

			//TODO: Add statuses based on the selected product
			// { key: 'undecided', name: 'Undecided' },
			// { key: 'interested', name: 'Interested' },
			{
				key: 'archived',
				name: 'Closed'
			},
			{
				key: 'followUpComing',
				name: 'Upcoming Customer Interaction'
			},
			{
				key: 'followUpMissed',
				name: 'Customer Interaction Missed'
			}
		];

		this.getFirsterFilters = function () {
			return firstListFilter;
		};

		var secondListFilter = [];
		var secondListFiltersForNonReferralsView = [];
		var secondListFiltersForReferralsView = [];

		this.setSecondFilters = function (productCode, isReferralsView) {
			secondListFilter = [];
			secondListFiltersForNonReferralsView = [];
			secondListFiltersForReferralsView = [];
			switch (productCode) {
				case 'P01':

				case 'P02':

				case 'P03':

				case 'P04':

				case 'P05':
				case 'P06':
					secondListFiltersForNonReferralsView = [{
							key: 'followUpIn',
							name: 'Customer interaction'
						},
						{
                            key: 'followUpMissed',
                            name: 'Missed interaction'
                        },
                        {
                            key: 'followUpComing',
                            name: 'Upcoming interaction'
                        },
						{
							key: 'createdIn',
							name: 'Leads generation'
						},

						//TODO: Add submission states based on the selected product
						// { key: 'status', name: 'Sub. for Appr.', statusKey: 'Submitted for Approval', statusCode: 'ST07' },
						// { key: 'status', name: 'Pen. Appr. Line Mng.', statusKey: 'Pending Approval for Line manager', statusCode: 'ST08' },
						// { key: 'status', name: 'Pen. Appr. Credit', statusKey: 'Pending Approval for Credit', statusCode: 'ST11' },
						// { key: 'status', name: 'Pen. Appr. Operations', statusKey: 'Pending Approval for Operations', statusCode: 'ST14' },

						{
							key: 'wonIn',
							name: 'Successful'
						},
						{
							key: 'failedIn',
							name: 'Rejected'
						}
					];
					secondListFiltersForReferralsView = [{
							key: 'createdIn',
							name: 'Leads generation'
						},
						{
							key: 'wonIn',
							name: 'Successful'
						},
						{
							key: 'failedIn',
							name: 'Rejected'
						}
					];
					break;
				default:
					secondListFiltersForNonReferralsView = [
						{
							key: 'followUpIn',
							name: 'Customer interaction'
						},
						{
                            key: 'followUpMissed',
                            name: 'Missed interaction'
                        },
                        {
                            key: 'followUpComing',
                            name: 'Upcoming interaction'
                        },
						{
							key: 'createdIn',
							name: 'Leads generation'
						},
						{
							key: 'wonIn',
							name: 'Successful'
						},
						{
							key: 'failedIn',
							name: 'Rejected'
						}
					];
					secondListFiltersForReferralsView = [{
							key: 'createdIn',
							name: 'Leads generation'
						},
						{
							key: 'wonIn',
							name: 'Successful'
						},
						{
							key: 'failedIn',
							name: 'Rejected'
						}
					];
					break;
			}
			if (isReferralsView)
				return secondListFiltersForReferralsView;
			else
				return secondListFiltersForNonReferralsView;
		};

		this.getSecondFilters = function (isReferralsView) {
			if (isReferralsView)
				return secondListFiltersForReferralsView;
			else
				return secondListFiltersForNonReferralsView
		};

		var thirdListFiltersForNonReferralsView = [
			// { key: 'Self generated', name: 'Self generated' },
			// { key: 'Strategic Alliance', name: 'Strategic Alliance' },
			// { key: 'Pre-approved', name: 'Pre-approved' },
			{
				key: 'Marketing',
				name: 'Marketing'
			},
			{
				key: 'Managerial Referral',
				name: 'Manager'
			},
			{
				key: 'Intra BB-division',
				name: 'Intra BB-division'
			},
			{
				key: 'Outside BB-division',
				name: 'Outside BB-division'
			}
		];

		var thirdListFiltersForReferralsView = [
			// { key: 'Self generated', name: 'Self generated' },
			// { key: 'Strategic Alliance', name: 'Strategic Alliance' },
			// { key: 'Pre-approved', name: 'Pre-approved' },
			{
				key: 'Marketing',
				name: 'Marketing'
			},
			{
				key: 'Managerial Referral',
				name: 'Manager'
			},
			{
				key: 'Intra BB-division',
				name: 'Intra BB-division'
			},
			{
				key: 'Outside BB-division',
				name: 'Outside BB-division'
			}
		];

		this.getThirdFilters = function (isReferralsView) {
			if (isReferralsView)
				return thirdListFiltersForReferralsView;
			else
				return thirdListFiltersForNonReferralsView;
		};

		const sortKeys = [
			{key: 'name', name: 'Name'},
			{key: 'createdAt', name: 'Created'},
			{key: 'updatedAt', name: 'Update'}
		]

		const sortOrders = [
			{key: 'asc', name:'Ascending'},
			{key: 'desc', name:'Descending'},
		]

		this.getSortKeysAndOrders = function (){
			return [sortKeys, sortOrders];
		}

		this.getActiveFrontlines = function () {
			return $http.post('api/users/getActiveFrontLineUsers').then(function (result) {
				return result.data;
			});
		};

		this.getAllSeniorManagers = function (userId) {
			return $http.post('api/users/getAllSeniorManagers', {userId}).then(function (result) {
				return result.data;
			})
		};

		this.getUsersOfJobRoles = (userId, jobRoleIds) => {
			const url = '/api/users/playersOfJobRoles';
			return $http.post(url, {userId, scope:'full', jobRoles: jobRoleIds}).then(function (result) {
				return result.data;
			})
		}

		this.setExtrasInfo = function (leads, fromHB, startDate, endDate, currMonth) {
			let me = Auth.getCurrentUser();
			let today = new Date();
			let todayStart = new Date(today.setHours(0, 0, 0, 0));
			let yesterDayStart = new Date(today);
			yesterDayStart = new Date(yesterDayStart.setDate(yesterDayStart.getDate() - 1));
			let todayEnd = new Date(today.setHours(23, 59, 59, 59));

			let productiveCalls = [],
				productiveMeetings = [],
				touchpoints = [],
				completedInteractions = [];
			let hbCalls = [],
				hbMeetings = [];

			_.each(leads, function (lead) {
				//set permissions to edit lead details and lead status

				//set edit permissions for lead details
				//TODO: REMOVE THIS

				//setting lead creation date and text
				var leadDate = new Date(lead.leadCreationDate);
				if (leadDate > todayStart) {
					lead.creationText = 'Today';
					lead.todayOrYday = true;
				} else if (leadDate > yesterDayStart && leadDate < todayStart) {
					lead.creationText = 'Yesterday';
					lead.todayOrYday = true;
				} else {
					lead.todayOrYday = false;
					lead.creationText = $filter('date')(leadDate, 'mediumDate');
				}
				if (new Date(currMonth.startDate) <= leadDate && leadDate <= new Date(currMonth.endDate)) {
					lead.isNew = true;
					if (lead.leadStatus && lead.leadStatus.toLowerCase() == 'not yet contacted')
						lead.showBold = true;
					else
						lead.showBold = false;
				} else
					lead.isNew = false;

				//set followUp date from scheduled interactions
				if (lead.shortInteractions) {
					const { nextFollowup, missedFollowup} = lead.shortInteractions;
					if (( nextFollowup || missedFollowup) && (!_.isEmpty(nextFollowup) || !_.isEmpty(missedFollowup) )) {
						const { scheduledAt } = !_.isEmpty(nextFollowup)? nextFollowup: missedFollowup;
						let timestamp = Date.parse(lead.followUpDate);
						if (!isNaN(timestamp)) {
							lead.followUpDate = new Date(timestamp);
						}

						//setting followUpDate status and swot-color
						var noOfDays = Math.round(Math.abs(todayStart - lead.followUpDate) / (1000 * 60 * 60 * 24));
						var now = new Date();
						lead.followUpMissed = false;
						if (lead.followUpDate < now) {
							lead.followUpMissed = true;
							lead.followUpDateStatus = {
								class: "swot-red",
								text: 'Missed '
							}
						} else if (lead.followUpDate >= now && lead.followUpDate < todayEnd) {
							var dateText = $filter('date')(lead.followUpDate, 'MMM dd, yyyy hh:mm a');
							lead.followUpDateStatus = {
								class: "green",
								text: dateText
							}
						} else {
							var dateText = $filter('date')(lead.followUpDate, 'MMM dd, yyyy hh:mm a');
							lead.followUpDateStatus = {
								class: "swot-green",
								text: dateText
							};
						}
					}

					touchpoints = [];
					productiveCalls = [];
					productiveMeetings = [];
					completedInteractions = [];
					hbCalls = [];
					hbMeetings = [];
					lead.callHBCount = -1;
					lead.metHBCount = -1;

					completedInteractions = _.filter(lead.interactions, {
						status: 'completed'
					});
					productiveCalls = _.filter(completedInteractions, {
						type: 'call',
						outcome: 'contacted'
					});
					productiveMeetings = _.filter(completedInteractions, {
						type: 'meeting',
						outcome: 'met'
					});

					touchpoints = productiveCalls.concat(productiveMeetings);

					productiveCalls = _.sortBy(productiveCalls, 'completedAt');
					productiveMeetings = _.sortBy(productiveMeetings, 'completedAt');
					touchpoints - _.sortBy(touchpoints, 'completedAt');

					if (fromHB) {
						hbCalls = _.filter(productiveCalls, function (call) {
							return new Date(call.completedAt) >= startDate && new Date(call.completedAt) <= endDate;
						});
						hbMeetings = _.filter(productiveMeetings, function (meeting) {
							return new Date(meeting.completedAt) >= startDate && new Date(meeting.completedAt) <= endDate;
						});
						lead.callHBCount = hbCalls.length;
						lead.metHBCount = hbMeetings.length;
					}

					//setting interactions counts
					lead.completedInteractions = completedInteractions.length;
					lead.callInteractions = productiveCalls.length;
					lead.meetingInteractions = productiveMeetings.length;

					//setting last interaction dates
					lead.lastCallInteractionDate = lead.callInteractions ? productiveCalls[lead.callInteractions - 1].completedAt : null;
					lead.lastMeetingInteractionDate = lead.meetingInteractions ? productiveMeetings[lead.meetingInteractions - 1].completedAt : null;
					lead.lastTouchPoint = touchpoints.length ? touchpoints[touchpoints.length - 1].completedAt : null;
					// lead.lastStatusChange = statusChanged.length ? (statusChanged[statusChanged.length-1].timestamp) : lead.leadCreationDate;

				} else {
					lead.completedInteractions = 0;
					lead.callInteractions = 0;
					lead.meetingInteractions = 0;
				}

				if (lead.archived) {
					lead.showMissed = false;
				} else {
					lead.showMissed = true;
				}
				
				if (($rootScope.isMiddleManager || $rootScope.isSeniorManager ) && lead.userId == undefined) {
					lead.noHandler = true;
				}

				if (me.roleType === 'seniorManager') {
					lead.isEditable = true
				}
				else if (me.roleType === 'manager') {
					let index = _.findIndex(me.children, {
						childId: lead.userId._id
					})
					if (index > -1 || me._id === lead.userId._id) {
						lead.isEditable = true
					}
				} else if (lead.userId && (me._id === lead.userId._id)) {
					lead.isEditable = true
				}
				if($rootScope.isAgentOnBoardingVerificationUser){
					lead.isEditable = true;
				}

				if (!$rootScope.isFrontline && lead.userId != undefined && lead.userId._id == me._id) {
					lead.assignedToManager = true;
				}

				lead.followedByMe =  lead.followers && lead.followers.find(u => u.userId === me._id);
			});
			return leads;
		};

		this.getLead = function (leadId) {
			return $http.post('/api/leads/show',{id: leadId} );
		};

		this.createLead = function (data) {
			return $http.post('/api/leads/create', data);
		};

		this.getNextStates = function (leadId, userId) {
			return WorkflowService.getNextStates('api/leads/getNextStates', {leadId, userId})
		};

		this.getDocumentsCache = function (context, workflowId) {
			return $http.post(`/api/documents/documentsCache`, {workflowId, context});
		};

		this.gotoState = function (lead) {
			return WorkflowService.gotoState('api/leads/update', lead)
		};

		this.getLeadHistory = function (id) {
			return $http.post('/api/leads/history', {leadId:id});
		};

		this.getInteractions = function () {
			return $http.post('/api/interactions/types');
		};

		this.saveInteraction = function (interactedData) {
			return $http.post('/api/interactions/schedule', interactedData);
		};

		this.getFutureFollowUps = function (leadId) {
			return $http.post('/api/leads/futureFollowUps',{leadId});
		};

		this.getLeadPillsCounts = function (extraParams) {
			const postBody = {

			}
			var baseUrl = '/api/leads/getLeadPillsCounts';
			if (extraParams.cui) {
				postBody.cui =  extraParams.cui;
			}
			if (extraParams.userId) {
				postBody.userId =  extraParams.userId.toString();
			}
			postBody.startDate = p0.currentMonthlyCycle.startDate;;
			postBody.endDate = p0.currentMonthlyCycle.endDate;

			return $http.post(baseUrl, postBody);
		};
		this.getMyTeam = function (userId) {
			return $http.post('api/users/getActiveFrontLineUsers');
		};

		this.getCustomerNames = function (userId, includeUnassigned) {
			let url = '/api/customers/getCustomersByUser';
			let postData = {
				userId
			}
			if(includeUnassigned){
				postData['includeUnassigned'] = includeUnassigned
			}

			return $http.post(url, postData);
		};

		this.getCustomerByPhoneNumber = function (phoneNumber) {
			return $http.post(`/api/customers/getCustomersByPhoneNumber`, {phoneNumber});
		};

		this.getCampaigns = function () {
			return $http.post('/api/campaigns');
		};

		this.addNote = function(leadId, notes){
			return $http.post('/api/leads/notes',{leadId, notes});
		}

		this.getAttendanceLabel = function(isFrontline){
			let attendanceLabel = 'Loan Advisor present for the meeting';
			if(p0.strings){
				try{
					if (isFrontline && p0.strings.MEETING_ATTENDANCE_TRACK_FL.value) {
						attendanceLabel = p0.strings.MEETING_ATTENDANCE_TRACK_FL.value;
					}else if(!isFrontline && p0.strings.MEETING_ATTENDANCE_TRACK_NON_FL.value){
						attendanceLabel = p0.strings.MEETING_ATTENDANCE_TRACK_NON_FL.value;
					}
				}catch(err){
					attendanceLabel = "Loan Advisor present for the meeting"
				}
				return attendanceLabel;
			}
		};

		this.fetchLeadSourceList = function () {
			return $http.post('/api/leads/sources');
		}
		this.getPlayTypeUsers = (playTypeId) => {
			const url = "/api/users/playTypeUsers";
			const deferred = $q.defer();
			$http.post(url, {playTypeId}).then(
				result => {
					if(result.data.status){
						deferred.resolve(result.data.users);
					}else{
						deferred.reject(result.data.errors[0].message);
					}
				},
				error => {
					console.log(error);
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.getUserById = (userId) => {
			
			const url = "/api/users/getUserById";
			const deferred = $q.defer();
			$http.post(url,{id:userId}).then(
				result => {
					result = result.data;
					if(result.status){
						deferred.resolve(result.data);
					}else{
						deferred.reject(result.data.errors[0].message);
					}
				},
				error => {
					console.log(error);
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}

		this.isDuplicateCui = (cui) => {
			const url = `/api/customers/checkUniqueCui`;
			const deferred = $q.defer();
			$http.post(url,{cui}).then(
				result => {
					deferred.resolve(result);
				},
				error => {
					console.log(error);
					deferred.reject(error);
				}
			);
			return deferred.promise;
		}
		
		this.updateLeadInfo = (leadId, info) => {
			return $http.post(`/api/leads/updateLeadInfo`, {leadId,...info})
		}

		this.getDocEformInfo = (leadId) => {
			return $http.post(`/api/leads/saleskey/getDashboardDataFromSaleskey`,{leadId});
		}
		
		return this;
	}
]);
