 'use strict';
angular.module('bitnudgeApp')
    .controller('reporteeFiltersCtrl', function ($scope, $rootScope, p0, $http, Auth, userAnalyticsService) {
        $scope.roleTypeNames = {
            'seniorManager':{name:$rootScope.strings.SENIORMANAGER.value, level:2},
            'manager':{name:$rootScope.strings.MANAGER.value, level:1},
            'frontline':{name: $rootScope.strings.FRONTLINE.value, level:0}
        };
        $scope.me = Auth.getCurrentUser();
        $scope.strings = $rootScope.strings;
        $scope.selected = {};
        $scope.allPlayTypes = _.cloneDeep(p0.allPlayTypes);

        var setDefaultReportee = function () {
            $scope.selected.reportee = null;
            if(_.find($scope.allReportees, {_id:'all'})){
                var all = _.remove($scope.allReportees, {_id:'all'});
                $scope.allReportees.unshift(all[0]);
            }else if(_.find($scope.allReportees, {_id:$scope.me._id})){
                var me = _.remove($scope.allReportees, {_id:$scope.me._id});
                if($scope.addOwnUserEntry){
                    let meCopy = _.cloneDeep(me[0]);
                    meCopy._id = meCopy._id+':own';
                    meCopy.name = meCopy.name + ' (Own)';
                    meCopy.selfOnly = true;
                    $scope.allReportees.unshift(meCopy);
                }
                $scope.allReportees.unshift(me[0]);
            }
            if($scope.user){
                $scope.selected.reportee =  _.find($scope.allReportees, {_id:$scope.user._id});
            }
            if(!$scope.selected.reportee){
                $scope.selected.reportee =  $scope.allReportees[0];
            }
        };

        $scope.selectedReportee = $scope.selected.reportee;

        $scope.fetchReporteesHelper = function (jobRoleIds) {
            jobRoleIds = _.uniqBy(jobRoleIds, function(id){return id});
            const url = '/api/users/playersOfJobRoles';
            let postBody = {
                userId: $scope.me._id,
                jobRoles: JSON.stringify(jobRoleIds)
            }
            if($scope.selectedCycle){
                postBody.targetFilter = true;
                postBody.cycleId = $scope.selectedCycle._id;
            }
//temp fix.. need change once we decide about KPI dependance on cycle
            $http.post(url, postBody).then(function(reportees) {
                reportees=reportees.data;
                if(reportees.length == 0){
                    $scope.allReportees = [$scope.me];
                    setDefaultReportee();
                    $scope.onReporteeSelectionChange($scope.selected.reportee);
                    return;
                }
                $scope.allRoleTypes = [];
                $scope.reporteesGroupBy = _.groupBy(reportees, 'systemRole');
                _.each($scope.reporteesGroupBy, function (reportees, key) {
                    if(key == $scope.me.systemRole){
                        if($scope.showMyRole) $scope.allRoleTypes.push({_id:key ,name:$scope.roleTypeNames[key].name, level:$scope.roleTypeNames[key].level});
                    }else $scope.allRoleTypes.push({_id:key ,name:$scope.roleTypeNames[key].name, level:$scope.roleTypeNames[key].level});
                });
                $scope.allRoleTypes = _.sortBy($scope.allRoleTypes, 'level');
                $scope.selectedRoleType = null;
                if($scope.user){
                    $scope.selectedRoleType = _.find($scope.allRoleTypes, {_id:$scope.user.systemRole});
                }
                if(!$scope.selectedRoleType){
                    $scope.selectedRoleType = $scope.allRoleTypes[$scope.allRoleTypes.length-1];
                }
                if($scope.onRoleTypeChangeListener) {
                    $scope.onRoleTypeChangeListener($scope.selectedRoleType);
                }
                if($scope.me.systemRole == 'seniorManager' ){  // || $scope.user.systemRole == 'seniorManager'
                    var allReportees = _.cloneDeep($scope.reporteesGroupBy[$scope.selectedRoleType._id]);
                    $scope.allReportees = _.sortBy(allReportees, 'name');
                    if($scope.reporteeList){
                        $scope.reporteeList = $scope.allReportees;
                    }
                    if($scope.addOverallInReportees){
                        $scope.allReportees.unshift({_id:'all', name:'All Players'});
                    }
                }else {
                    $scope.allReportees =  _.sortBy(reportees, 'name');
                    if($scope.reporteeList) {
                        $scope.reporteeList = $scope.allReportees;
                    }
                    _.find($scope.allReportees, {_id:$scope.me._id}).name = 'Me';
                    if($scope.addOverallInReportees){
                        var overall = _.find($scope.allReportees, {_id:$scope.me._id});
                        overall.name = 'All Players';
                        overall._id = 'all';
                    }
                    if($scope.addOverallInReportees && $scope.me.systemRole != 'manager'){
                        $scope.allReportees.unshift({_id:'all', name:'All Players'});
                    } //Only for SM
                }
                setDefaultReportee();
                $scope.onReporteeSelectionChange($scope.selected.reportee);
            });
        };

        //Filter 2
        $scope.onPlayTypeChange = function (playType) {
            $scope.selectedPlayType = playType;
            $scope.playType = playType;
            $scope.allRoleTypes = [];
            $scope.allReportees = [];
            var allJobRoles = [];
            if($scope.playType._id != 'all'){
                var jobRoles = $scope.playType.frontline;
                jobRoles = jobRoles.concat($scope.playType.managers);
                allJobRoles = jobRoles.concat($scope.playType.seniorManagers);
            }else{
                if($scope.selectedDivision._id == 'all'){
                    _.each(p0.myPlayTypes, function (playType) {
                        allJobRoles = allJobRoles.concat(playType.frontline);
                        allJobRoles = allJobRoles.concat(playType.managers);
                        allJobRoles = allJobRoles.concat(playType.seniorManagers);
                    });
                }else{
                    var div = $scope.selectedDivision;
                    allJobRoles = allJobRoles.concat(div.frontline);
                    allJobRoles = allJobRoles.concat(div.managers);
                    allJobRoles = allJobRoles.concat(div.seniorManagers);
                }
            }
            $scope.fetchReporteesHelper(allJobRoles);
            if($scope.onPlayTypeChangeListener) $scope.onPlayTypeChangeListener($scope.playType);
        };

        $scope.allRoleTypes = [];

        $scope.onRoleTypeChange = function (roleType) {
            $scope.selectedRoleType = roleType;
            $scope.allReportees = [];
            var allReportees = _.cloneDeep($scope.reporteesGroupBy[$scope.selectedRoleType._id]);
            $scope.allReportees = _.sortBy(allReportees, 'name');
            if($scope.reporteeList){
                $scope.reporteeList = $scope.allReportees;
            }
            if($scope.addOverallInReportees) $scope.allReportees.unshift({_id:'all', name:'All Players'});
            setDefaultReportee();
            if ($scope.onRoleTypeChangeListener) {
                $scope.onRoleTypeChangeListener($scope.selectedRoleType);
            }
            $scope.onReporteeSelectionChange($scope.selected.reportee);
        };

        $scope.onReporteeSelectionChange = function (reportee) {
            if($scope.onReporteeChange && reportee) $scope.onReporteeChange(reportee);
        };


        $scope.onDivisionChange = function (division){
            $scope.selectedDivision = division;
            $scope.division = division;
            if($scope.division._id != 'all'){
                $scope.allPlayTypes = _.filter(p0.myPlayTypes, function(playType){
                    return _.includes(division.playTypes, playType._id);
                });
            }else{
                $scope.allPlayTypes = _.cloneDeep(p0.myPlayTypes);
            }
            if($scope.allPlayTypes.length > 1 && $scope.addAllPlayTypes){
                $scope.allPlayTypes.unshift({_id:'all', name:'All '+ $rootScope.strings.PLAYTYPE.value + 's'});
            }
            $scope.playType = null;
            if($scope.user){
                $scope.playType = _.find($scope.allPlayTypes, {_id: $scope.user.playTypes[0]})
            }
            if(!$scope.playType){
                $scope.playType = $scope.allPlayTypes[0];
            }
            $scope.onPlayTypeChange($scope.playType);
            if($scope.onDivisionChangeListener) $scope.onDivisionChangeListener($scope.division);
        };


        function init(){
            $scope.allDivisions = [];
            $scope.allDivisions =  _.cloneDeep(p0.myDivisions);
            if($scope.allDivisions.length > 1 && $scope.addAllDivisions){
                $scope.allDivisions.unshift({_id:'all', name:'All '+ $rootScope.strings.DIVISION.value + 's'});
            }
            if($scope.user){
                $scope.division = _.find($scope.allDivisions, {_id:$scope.user.divisions[0]})
            }
            if(!$scope.division){
                $scope.division = $scope.allDivisions[0];
            }
            $scope.onDivisionChange($scope.division);
        };
        init();

        $scope.$watch('user', function(newVal, oldVal){
            if(newVal){
                 var divisionIds = newVal.divisions;
                 $scope.division = _.find(p0.allDivisions, function(division){
                     return _.includes(divisionIds, division._id);
                 });
                $scope.onDivisionChange($scope.division);
            }
        })
    });
