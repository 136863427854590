'use strict';
angular.module('bitnudgeApp')
    .controller('leadtableCustomerContainerController',function($scope, $state, $stateParams, $rootScope, Auth, leadsService, p0) {
        console.log('hey I am leadtableCustomerContainerController')
        this.$onChanges = (changes) => {
            if (changes.customer.currentValue) {
                $scope.customer = changes.customer.currentValue;
                init();
            }
        };

        $scope.me = Auth.getCurrentUser();
        $scope.isFrontline = $rootScope.isFrontline;
        $scope.isMiddleManager = $rootScope.isMiddleManager;
        $scope.isSeniorManager = $rootScope.isSeniorManager;
        var originalLeadList = [];
        var filteredLists = [];
        $scope.selectedLead = {};
        $scope.selected = {
            cycle: {}
        };
        var currMonth = $scope.selected.cycle;
        $scope.leadList = [];
        $scope.pagination = {
            offset: 0,
            limit: 50,
            total: 0,
            disableNext: true,
            disablePrevious: true
        };
        $scope.sortingObj = {
            sortKey: 'followUpDate',
            sortOrder: 'asc'
        };
        $scope.nextPage = function () {
            $scope.pagination.offset += $scope.pagination.limit;
            $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
        }

        $scope.previousPage = function () {
            $scope.pagination.offset -= $scope.pagination.limit;
            $scope.fetchLeads($scope.selected.user ? $scope.selected.user : $scope.me);
        }

        var setExtraInfoOnLeadsList = function (data) {
            $scope.leadList = JSON.parse(JSON.stringify(data.data.leads.docs));
            //set the currently selected lead to the first one in the list
            $scope.selectedLead = $scope.leadList[0];
            //this sets whether to show the details of this lead on the RHS
            $scope.selectedLeadShow = false;
            if ($stateParams.fromHB) {
                var startDate = new Date(parseInt($stateParams.startDate));
                var endDate = new Date(parseInt($stateParams.endDate));
                $scope.startDate = $filter('date')(startDate, 'd MMM');
                $scope.endDate = $filter('date')(endDate, 'd MMM');
            }

            $scope.leadList = leadsService.setExtrasInfo($scope.leadList, $stateParams.fromHB, startDate, endDate, currMonth)
            $scope.leadList.forEach(lead => {
                if (!lead.userId){
                    lead.isUnassigned = true;
                }
            })
        }

        // var resetFilters = function () {
        //     $scope.filterObj = {
        //         productId: $scope.selectedProductFilter,
        //         leadStatus: "",
        //         leadStatusKey: "",
        //         leadDate: "",
        //         leadDateKey: "",
        //         leadTypeKey: "",
        //         searchParam: '',
        //         userId: '',
        //         referrals: $scope.isReferralsView,
        //         applyNOT: false
        //     };
        // }

        var setInitialFilters = function () {
            $scope.filterObj = {
                productId: $scope.myProducts[0]._id,
                leadStatus: "",
                leadStatusKey: "",
                leadDate: "",
                leadDateKey: "",
                leadTypeKey: "",
                searchParam: '',
                userId: '',
                referrals: $scope.isReferralsView,
                applyNOT: false
            };
            $scope.selectedProductFilter = $scope.myProducts[0]._id
            $scope.selected.cycle.startDate = p0.currentMonthlyCycle.startDate;
            $scope.filterObj.startDate = p0.currentMonthlyCycle.startDate;
            $scope.selected.cycle.endDate = p0.currentMonthlyCycle.endDate;
            $scope.filterObj.endDate = p0.currentMonthlyCycle.endDate;
        }
        $scope.setPaginationData = function (data) {
            $scope.pagination.total = data.data.total;
            if (data.data.leads.docs.length == 0) {
                $scope.filteredCount = 0;
            } else {
                if ($scope.pagination.offset + $scope.pagination.limit < $scope.pagination.total) {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.offset + $scope.pagination.limit);
                } else {
                    $scope.filteredCount = ($scope.pagination.offset + 1) + '-' + ($scope.pagination.total);
                }
            }
            if (($scope.pagination.offset + $scope.pagination.limit) >= $scope.pagination.total) {
                $scope.pagination.disableNext = true;
            } else {
                $scope.pagination.disableNext = false;
            }
            if ($scope.pagination.offset == 0) {
                $scope.pagination.disablePrevious = true;
            } else {
                $scope.pagination.disablePrevious = false;
            }
        }

        $scope.fetchLeads = function (user) {
            if (!user) {
                user = $scope.selected.user ? $scope.selected.user : $scope.me;
            }

            //reset the variable storing the currently fetched leads
            $scope.leadList = [];
            originalLeadList = [];
            filteredLists = [];
            //call the API to fetch leads
            // var deferred = $q.defer();
            // $scope.promise = deferred.promise;
            var promise;
            var extraParams = {
                currentMonthlyCycle: null,
                selectedCycle: null
            };
            //No cycle specific leads in customer 360
            // if ($scope.selected.cycle != undefined) {
            //     extraParams.selectedCycle = JSON.parse(JSON.stringify($scope.selected.cycle));
            // } else {
            //     extraParams.selectedCycle = null
            // }
            extraParams.productId = $scope.selectedProductFilter;
            extraParams.products = JSON.parse(JSON.stringify($scope.myProducts));
            //set the user
            //dont send userId for the case of manager or seniorManager as the API fetches it from the authentication middleware and fethces all its frontlines' leads
            if ($scope.me.systemRole == 'frontline') {
                extraParams.userId = $scope.me._id;
            } else if (user) {
                extraParams.userId = user._id;
            }

            //params set for customer 360 only
            extraParams.cui = $scope.customer.cui;
            extraParams.productId = null;
            $scope.filterObj.productId = null;

            if ($scope.isReferralsView) {
                if ($scope.showLostLeads) {
					promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'lost', true, $scope.sortingObj);
                } else {
					promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'referrals', true, $scope.sortingObj);
                }
            } else {
				promise = leadsService.getLeads($scope.filterObj, extraParams, $scope.pagination, 'myleads', true, $scope.sortingObj);
            }

            //set data loaded as false
            $scope.dataLoaded = false;
            $scope.apiLoaded = false;

            //make the API call
            promise
                .then(function (response) {
                    var data = response.data
                    //set the list of leads to the scope variable                 
                    $scope.leadList = data.data.leads.docs;
                    //set extra information on leads
                    setExtraInfoOnLeadsList(data);
                    //set product wise counts
                  //  setCounts(data);
                    $scope.setPaginationData(data);
                    originalLeadList = JSON.parse(JSON.stringify($scope.leadList));
                    filteredLists = JSON.parse(JSON.stringify($scope.leadList));
                    //set data loaded to true
                    $scope.dataLoaded = true;
                    $scope.apiLoaded = true;
                })
        }

        var checkAndRestoreStateParams = function () {
            //set tab related info
            if ($state.params.tabName !== undefined && $state.params.tabName !== '') {
                if ($stateParams.tabName === 'referrals') {
                    $scope.isReferralsView = true;
                    $scope.showLostLeads = false;
                    $scope.tabName = 'referrals';
                } else if ($stateParams.tabName === 'lostLeads') {
                    $scope.isReferralsView = true;
                    $scope.showLostLeads = true;
                    $scope.tabName = 'lostLeads';
                } else if ($stateParams.tabName === 'leads') {
                    $scope.isReferralsView = false;
                    $scope.showLostLeads = false;
                    $scope.tabName = 'leads';
                }
            } else {
                $scope.isReferralsView = false;
                $scope.showLostLeads = false;
                $scope.tabName = 'leads';
              //  $state.go('lms.listLead', { tabName: 'leads' }, { notify: false });
            }

            //set all the products that are to be shown on the tabs
            if ($scope.isReferralsView) {
                if ($scope.showLostLeads) {
                    $scope.myProducts = [];
                    p0.myProducts.forEach(function (product) {
                        $scope.myProducts.push({
                            productCode: product.productCode,
                            _id: product._id,
                            name: product.shortName
                        });
                    })
                } else {
                    $scope.myProducts = [];
                    p0.allProducts.forEach(function (product) {
                        $scope.myProducts.push({
                            productCode: product.productCode,
                            _id: product._id,
                            name: product.shortName
                        });
                    })
                }
            } else {
                $scope.myProducts = [];
                p0.myProducts.forEach(function (product) {
                    $scope.myProducts.push({
                        productCode: product.productCode,
                        _id: product._id,
                        name: product.shortName
                    });
                })
            }

            if ($stateParams.lostReassign) {
                showLostCount = false;
                showLostModal();
            }

            if ($stateParams.dateField === 'failed' && !$stateParams.lostReassign) {
                showLostCount = true;
                fetchLostLeadsCount();
            }

            if ($stateParams.userId) {
                $http.post('api/users/getUserById',{userId: $stateParams.userId}).then(function (user) {
                    user = user.data;
                    $scope.selectedUser = user;
                })
            }

            //restore filters if present in the state params
            if ($stateParams && Object.keys($stateParams) !== 0) {
                $scope.filterObj = JSON.parse(JSON.stringify($stateParams));

                if ($scope.filterObj.status) {
                   $scope.filterObj.leadStatusKey = $scope.filterObj.status;
                }
                if ($scope.filterObj.dateField) {
                    $scope.filterObj.leadDateKey = $scope.filterObj.dateField;
                }
                if ($stateParams.productId) {
                    $scope.selectedProductFilter = $stateParams.productId;
                } else {
                    $scope.selectedProductFilter = $scope.myProducts[0]._id;
                    $scope.filterObj.productId = $scope.myProducts[0]._id;
                }
                if ($stateParams.startDate) {
                    $scope.selected.cycle.startDate = new Date(parseInt($stateParams.startDate));
                } else {
                    $scope.selected.cycle.startDate = p0.currentMonthlyCycle.startDate;
                }
                $scope.filterObj.startDate = $scope.selected.cycle.startDate;
                if ($stateParams.endDate) {
                    $scope.selected.cycle.endDate = new Date(parseInt($stateParams.endDate));
                } else {
                    $scope.selected.cycle.endDate = p0.currentMonthlyCycle.endDate;
                }
                $scope.filterObj.endDate = $scope.selected.cycle.endDate;
            } else {
                setInitialFilters();
            }
        }

        checkAndRestoreStateParams()

        $scope.showSelectedLead = function (lead) {

            $scope.selectedLeadShow = true;
            _.each($scope.leadList, function (val) {
                val.selectedLead = lead._id === val._id;
            });
            $scope.selectedLead = JSON.parse(JSON.stringify(lead));
        };

        $scope.closeSelectedLead = function () {
            $scope.selectedLeadShow = false;
            _.each($scope.leadList, function (val) {
                val.selectedLead = false;
            });
            $scope.selectedLead = {};
        };

        $scope.filterOnStatus = function(status){
            if(status.id === $scope.filterObj.leadStatusKey){
                $scope.filterObj.leadStatusKey = null;
            }else{
                $scope.filterObj.leadStatusKey = status.id;
            }
            $scope.fetchLeads($scope.me);
        }
         $scope.fetchLeadPills = function(user){
            var extraParams ={};
            if ($scope.me.systemRole == 'frontline') {
                extraParams.userId = $scope.me._id;
            } else if (user) {
                extraParams.userId = user._id;
            }

            //params set for customer 360 only
            extraParams.cui = $scope.customer.cui;
            extraParams.productId = null;
            $scope.filterObj.productId = null;

             //get leads pill counts
            leadsService.getLeadPillsCounts(extraParams).then(function(result){
                $scope.leadStats = result.data;
            },function(err){
                console.log(err)
            })
        }
        function init(){
            //if ($scope.isFrontline) {
                $scope.selected.user = $scope.me;
                $scope.selected.role = $scope.me.organizationalTag.jobRole;
                $scope.fetchLeadPills($scope.me);
                $scope.fetchLeads($scope.me);
           // }
        }
    });
