'use strict';

angular.module('bitnudgeApp')
  .controller('geoLocationCtrl', function ($scope, Auth, $compile, leadsService) {
    $scope.message = 'Hello';
    $scope.getCurrentUser = Auth.getCurrentUser();
    console.log('I am here loaded')
    $scope.randomCustomers = [];
    $scope.randomDummyCustomers = [
      {
        name: "John Doe",
        payableAmount: 100.50,
        lastSyncLocation: "Dubai",
        cif: "12345",
        contactNumber: "555-123-4567",
        location: "New York",
        image: "https://i.pravatar.cc/300",
      },
      {
        name: "Jane Smith",
        payableAmount: 75.20,
        lastSyncLocation: "London",
        cif: "67890",
        contactNumber: "555-987-6543",
        location: "Los Angeles",
        image: "https://i.pravatar.cc/301",
      },
      {
        name: "Alice Johnson",
        payableAmount: 50.00,
        lastSyncLocation: "Paris",
        cif: "54321",
        contactNumber: "555-555-5555",
        location: "Berlin",
        image: "https://i.pravatar.cc/302",
      },
      {
        name: "Bob Anderson",
        payableAmount: 120.75,
        lastSyncLocation: "Sydney",
        cif: "98765",
        contactNumber: "555-321-6547",
        location: "Toronto",
        image: "https://i.pravatar.cc/303",
      },
      {
        name: "Ella Davis",
        payableAmount: 33.80,
        lastSyncLocation: "Tokyo",
        cif: "13579",
        contactNumber: "555-777-8888",
        location: "Shanghai",
        image: "https://i.pravatar.cc/304",
      },
      {
        name: "Mike Wilson",
        payableAmount: 65.25,
        lastSyncLocation: "Moscow",
        cif: "24680",
        contactNumber: "555-456-1234",
        location: "Mumbai",
        image: "https://i.pravatar.cc/305",
      },
      {
        name: "Linda Brown",
        payableAmount: 95.60,
        lastSyncLocation: "Rio de Janeiro",
        cif: "86420",
        contactNumber: "555-888-7777",
        location: "Cape Town",
        image: "https://i.pravatar.cc/306",
      },
      {
        name: "David White",
        payableAmount: 42.30,
        lastSyncLocation: "Madrid",
        cif: "11223",
        contactNumber: "555-666-9999",
        location: "Rome",
        image: "https://i.pravatar.cc/307",
      },
      {
        name: "Sarah Lee",
        payableAmount: 80.90,
        lastSyncLocation: "Beijing",
        cif: "55661",
        contactNumber: "555-333-2222",
        location: "Seoul",
        image: "https://i.pravatar.cc/308",
      },
      {
        name: "Chris Taylor",
        payableAmount: 110.25,
        lastSyncLocation: "Buenos Aires",
        cif: "98766",
        contactNumber: "555-222-1111",
        location: "Sao Paulo",
        image: "https://i.pravatar.cc/309",
      }
    ];

    $scope.markers = [];

    $scope.highlightedCard = null; // Variable to track the highlighted card
    $scope.highlightIndex = null;
    $scope.highlightCard = function (index, customer) {
      $scope.highlightedCard = customer;
      $scope.highlightIndex = index;
      console.log('customer: ', $scope.highlightIndex)
      $scope.animateMarker(index);
    };

    $scope.unhighlightCard = function () {
      $scope.highlightedCard = null;
      console.log('customer leavr: ', $scope.highlightIndex)
      $scope.stopAnimation($scope.highlightIndex)
    };

    // Function to animate a specific marker
    $scope.animateMarker = function (markerIndex) {
      if ($scope.markers[markerIndex]) {
        $scope.markers[markerIndex].setAnimation(google.maps.Animation.BOUNCE);
      }
    };
    // Function to stop animation a specific marker
    $scope.stopAnimation = function (markerIndex) {
      if ($scope.markers[markerIndex] && $scope.markers[markerIndex].getAnimation() !== null) {
        $scope.markers[markerIndex].setAnimation(null);
      }
    };




    // Add your Google Maps API key here
    const apiKey = 'AIzaSyC190mIuK86BGPdPLkSrEs21tzwWs-rTdk';

    // Function to generate random coordinates within a 10 km radius
    function generateRandomCoordinates(centerLat, centerLng, radiusInKm) {
      const earthRadius = 6371; // Earth's radius in km
      const randomAngle = Math.random() * 2 * Math.PI; // Random angle in radians
      const randomDistance = Math.random() * radiusInKm; // Random distance in km

      // Calculate new latitude and longitude
      const newLat = centerLat + (randomDistance / earthRadius) * (180 / Math.PI);
      const newLng = centerLng + (randomDistance / earthRadius) * (180 / Math.PI) / Math.cos(centerLat * Math.PI / 180);

      return { lat: newLat, lng: newLng };
    }

    // Center coordinates (Dubai)
    const centerLat = 25.06435205582904;
    const centerLng = 55.13802894232877;

    // Generate random coordinates within a 10 km radius of the center
    const locations = [];
    for (let i = 0; i < 100; i++) {
      locations.push(generateRandomCoordinates(centerLat, centerLng, 2));
    }

    $scope.randomCustomers.forEach((cust, index) => {
      cust.lat = locations[index].lat;
      cust.lng = locations[index].lat;
    })
    console.log('$scope.randomCustomers: ', $scope.randomCustomers);

    $scope.openDirections = function (lat, lng) {
      console.log('Got Called')
      const url = `https://www.google.com/maps/dir/?api=1&origin=${centerLat},${centerLng}&destination=${lat},${lng}`;
      window.open(url, '_blank');
    }

    $scope.moreInfo = function () {
      console.log('lets open customer')
    }
    $scope.leadCustomers = {};
    $scope.initPage = async () => {
      try {
        const filterObj = { 'isLeadCustomer': true };
        const extraParams = { userId: $scope.getCurrentUser._id }
        const { data } = await leadsService.getLeads(filterObj, extraParams, { offset: 0, limit: 50 }, 'myleads', false, { 'sortKey': 'updatedAt', 'sortOrder': 'desc' })
        console.log('')
        if (data.status) {
          const { leads } = data.data;
          console.log('custs#### ', leads.docs);
          leads.docs.forEach(lead => {
            $scope.randomCustomers.push({
              name: lead.contact.name,
              contactNumber: lead.contact.contactNumber.countryCode + lead.contact.contactNumber.number,
              status: lead.customerStatus,
              stage: lead.customerStage,
              customerLocations: lead.customerLocations,
            })
          })
          $scope.initMap(15, true);
          return leads.docs
        }

      } catch (err) {
        console.log('err: ', err);
      }

    }

    function findObjectWithLatestUpdatedAt(customerLocations) {
      if (customerLocations.length === 0) {
        return null; // Return null if the array is empty
      }

      // Sort the array in descending order based on the 'updatedAt' property
      customerLocations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      // The object with the latest 'updatedAt' will now be the first element in the sorted array
      return customerLocations[0];
    }
    $scope.data = {cb4: 'none', cb2: 100};
    $scope.changeRadius = function(radius) {
      if(radius === 50) {
        $scope.data.cb2 = false;
        $scope.initMap(20);
      } else {
        $scope.data.cb1 = false
        $scope.initMap(15);
      }
    }

    let infoWindow = null;
    $scope.initMap = async function (zoomLevel=15, isFirstInit=false) {
      $scope.markers = [];
      var myStyles = [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            { visibility: "off" }
          ]
        }
      ];

      const map = new google.maps.Map(document.getElementById('right-section'), {
        center: { lat: 25.06435205582904, lng: 55.13802894232877 }, // Coordinates for Dubai
        zoom: zoomLevel,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: myStyles
      });
      // Add a marker for the center location with a custom icon
      new google.maps.Marker({
        position: { lat: centerLat, lng: centerLng },
        map: map,
        title: 'My Location',
        //icon: '../../images/profile.png'
        icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png' // Replace with the URL to your custom icon
      });

      // Add markers for the random locations
      $scope.randomCustomers.forEach((customer, index) => {
        const latestLocation = findObjectWithLatestUpdatedAt(customer.customerLocations);
        console.log('cus lat: ', latestLocation.lat, ' lng: ', latestLocation.lng)
        let position = null;
        if(false && latestLocation.lat && latestLocation.lng) {
          position = { lat: Number.parseFloat(latestLocation.lat), lng: Number.parseFloat(latestLocation.lng) }
        } else {
          position =  { lat: locations[index].lat, lng: locations[index].lng }
        }
        console.log('position ', position)
        const marker = new google.maps.Marker({
          position,
          map: map,
          title: `Location ${index + 1}`
        });

        // Create custom HTML content for the InfoWindow
        const infoWindowContent = `
        <div style="border: 1px solid #ccc; border-radius: 5px; padding: 10px; max-width: 250px;">
          <div style="margin-top: 10px;">
            ${customer.name}<br>
            ${customer.contactNumber}<br>
            <strong>Status:</strong> ${customer.status}<br>
          </div>
        </div>
      `;
        // Add a click event listener to show a tooltip (InfoWindow) on marker click
        marker.addListener('mouseover', () => {
          console.log('mouse over')
          const elTooltip = $compile(infoWindowContent)($scope);
          infoWindow = new google.maps.InfoWindow({
            content: elTooltip[0]
          });
          infoWindow.open(map, marker);
        });

        // Add a click event listener to show a tooltip (InfoWindow) on marker click
        marker.addListener('mouseout', () => {
          console.log('mouse our')
          infoWindow.close();
        });
        $scope.markers.push(marker);
      });

      console.log('$scope.markers: ', $scope.markers)
    };
  });
