const autoImportNgTemplateLoaderTemplate1 = require('../html/playerList.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/playerListModal.html');

'use strict';

angular.module('bitnudgeApp')
    .controller('performanceBarometerCtrl', function ($scope, $http, Auth, $rootScope, p0, performanceBarometerService, $mdDialog, $filter, badgeCorner) {
        $scope.config = $rootScope.config;
        $scope.me = Auth.getCurrentUser();
        var leagues = $scope.me.leagues;
        $scope.myKpis = _.cloneDeep(p0.myKpis);
        $scope.myKpis.unshift({_id:'overall', name:'Overall'});
        $scope.strings = $rootScope.strings;
        $scope.showLevel = true;
        if(!$scope.config.liveLevel && !$scope.selectedCycle.previousCycleId){
            $scope.showLevel = false;
        }

        //Popover templates
        $scope.popover = {
            'rank' : 'rankPopover.html',
            'level' : 'levelPopover.html',
            'status' : 'statusPopover.html',
            'points' : 'pointsPopover.html',
            'redFlags' : 'redFlagsPopover.html',
            'formInGame' : 'formInGamePopover.html',
            'goFunnel' : 'goFunnelPopover.html',
            'gpCompliance' : 'gpCompliancePopover.html'
        };
        $scope.fetchSMData = function () {
            const postBody = {
                userId: $scope.userId,
                cycleId: $scope.selectedCycle._id
            }
            $http.post('/api/userAnalytics/seniorManager/performanceCount', postBody).then(function(smPbData) {
                smPbData=smPbData.data;
                $scope.smData = {};
                $scope.smData.redFlagsCount = smPbData.redFlag[$scope.selectedKpi._id];
                $scope.smData.gpNotCompilant = smPbData.gameplanCompliant[$scope.selectedKpi._id];
                $scope.smData.gpNotMade = smPbData.gameplanTarget[$scope.selectedKpi._id];
            });
        };
        $scope.initPlayerData = function (){
            $scope.cycles = [$scope.selectedCycle];
            if($scope.selectedCycle.cycleType == 'monthly'){
                $scope.cycleTypeName = 'Month';
            }else if($scope.selectedCycle.cycleType == 'weekly'){
                $scope.cycleTypeName = 'Week';
            }else{
                $scope.cycleTypeName = 'Day';
            }
            if($scope.selectedCycle.previousCycleId){
                var previousCycle = _.find(p0.allCycles,{_id:$scope.selectedCycle.previousCycleId._id});
                if(previousCycle ){
                    $scope.cycles.push(previousCycle);
                    if(previousCycle.previousCycleId) {
                        var lastCycle = _.find(p0.allCycles, {_id: previousCycle.previousCycleId._id});
                        $scope.cycles.push(lastCycle);
                    }
                }
            }
            $scope.kpiId = $scope.selectedKpi._id;
            $scope.delta = {};
            $scope.getPerformanceBarometer();
        };
        $scope.getPerformanceBarometer = function () {
            var cycleIds = _.map($scope.cycles,'_id');
            performanceBarometerService.getPerformanceBarometer($scope.userId, cycleIds, $scope.kpiId).then( function (pbData) {
                $scope.ranksPop = [];
                $scope.popoverData = {
                    'ranks':[],
                    'points':[],
                    'formInGame':[],
                    'status':[],
                    'gamePlan':[]
                };
                $scope.pbData = pbData;
                if(!pbData){
                    return;
                }
                if(pbData.ranks && pbData.ranks[0] != undefined && pbData.ranks[0].length){
                    $scope.rank = _.find(pbData.ranks[0],{teamType:'bank'}).value;
                }
                if(pbData.points && pbData.points.length){
                    $scope.points = pbData.points[0];
                }
                if(pbData.prevRanks && pbData.prevRanks.length && _.find(pbData.prevRanks[0],{teamType:'bank'})) {
                    var prevRank = _.find(pbData.prevRanks[0],{teamType:'bank'}).value
                }else {
                    var prevRank  = -1;
                };
                var prevPoints = pbData.prevPoints;
                if($scope.kpiId == 'overall' && prevPoints && prevPoints.length){
                    if(prevPoints.length) $scope.popoverData.points.push({name:'Previous '+$scope.cycleTypeName,value :prevPoints[0]});
                    $scope.popoverData.points.push({name:'Aggregate '+$scope.strings.IMPACT.value+' ',value : pbData.points[1], delta:prevPoints?pbData.points[1] - prevPoints[1] : 0 });
                    $scope.popoverData.points.push({name:'Aggregate '+$scope.strings.ACTIVITY.value+' ',value :pbData.points[2], delta:prevPoints?pbData.points[2] - prevPoints[2] : 0 });
                }else if(prevPoints && prevPoints.length){
                    //var kpiActivityRank = _.find(pbData.ranks[1],{teamType:'bank'});
                    //if(kpiActivityRank) $scope.popoverData.rank.push({name:'Activity points ',value : kpiActivityRank.value});
                    if(prevPoints.length) $scope.popoverData.points.push({name:'Previous '+$scope.cycleTypeName,value :prevPoints[0]});
                    $scope.popoverData.points.push({name:'Aggregate '+$scope.strings.IMPACT.value+' ',value : pbData.points[1], delta:prevPoints?pbData.points[1] - prevPoints[1] : 0 });
                    $scope.popoverData.points.push({name:'Aggregate '+$scope.strings.ACTIVITY.value+' ',value :pbData.points[2],delta:prevPoints?pbData.points[1] - prevPoints[1]:0 });
                }

                var bankLg = _.find(leagues, {teamType:'bank'});
                if(bankLg)
                    var bankName = bankLg.teamTypeName;
                var regionLg = _.find(leagues, {teamType:'region'});
                if(regionLg)
                    var regionName = regionLg.teamTypeName;
                var teamLg = _.find(leagues, {teamType:'team'});
                if(teamLg)
                    var teamName = teamLg.teamTypeName;

                var bankRanks = {
                    name: bankName+'-wide Rank',
                    ranks:[]
                };
                if(prevRank!=-1) bankRanks.ranks.push({name:'Previous '+$scope.cycleTypeName,value :prevRank});
                if(pbData.ranks[1]  && pbData.ranks[1] != undefined  && pbData.ranks[1].length) bankRanks.ranks.push({name:$scope.strings.IMPACT.value+' '+$scope.strings.POINTS.value,value : _.find(pbData.ranks[1],{teamType:'bank'}).value});
                if(pbData.ranks[2]  && pbData.ranks[2] != undefined && pbData.ranks[2].length) bankRanks.ranks.push({name:$scope.strings.ACTIVITY.value+' '+$scope.strings.POINTS.value,value : _.find(pbData.ranks[2],{teamType:'bank'}).value});
                $scope.popoverData.ranks.push(bankRanks);

                var regionRanks = {
                    name:'Rank in '+regionName,
                    ranks:[]
                };
                var teamRanks = {
                    name:'Rank in '+teamName,
                    ranks:[]
                };
                if(pbData.ranks && pbData.ranks[0]){
                    var rankRegion = _.find(pbData.ranks[0],{teamType:'region'});
                    var rankTeam = _.find(pbData.ranks[0],{teamType:'team'});
                }

                if(rankRegion && false) {
                    regionRanks.ranks.push({name:regionName,value : rankRegion.value});
                    $scope.popoverData.ranks.push(regionRanks);
                }
                //TODO: Show team wise rank in activity points and impact points
                if(rankTeam && false) {
                    $scope.popoverData.ranks.push(teamRanks);
                }

                var lastToCycles;
                if($scope.cycles.length > 2){
                    lastToCycles = $scope.cycles.splice(0,2);
                }else{
                    lastToCycles = $scope.cycles;
                }
                if($scope.pbData && $scope.pbData.percentageCompleted){
                    lastToCycles.forEach(function(cycle,i){
                            if(pbData.percentageCompleted[i]) $scope.popoverData.formInGame.push({name:cycle.name,value:($scope.pbData.percentageCompleted[i].delta > 0? '+':'')+$filter('number')($scope.pbData.percentageCompleted[i].delta,0) + '%'});
                    });
                }
                if ($scope.pbData.gpCompliance) {
                    if ($scope.pbData.gpCompliance.gpNotSetCount > 0) {
                        $scope.popoverData.gamePlan.push({ name: 'GP creation', value: $scope.pbData.gpCompliance.gpNotSetCount, descPost: ($scope.pbData.gpCompliance.gpNotSetCount == 1 ? ' KPI GP not made' : ' KPIs GP not made') });
                    }
                    $scope.popoverData.gamePlan.push({ name: 'GP progress', value: $scope.pbData.gpCompliance.laggingCount, descPre: 'Lagging behind in ', descPost: ($scope.pbData.gpCompliance.laggingCount == 1 ? ' KPI ' : ' KPIs ') });
                }

                // _.remove($scope.pbData.gameplanPercent,function(gameplanPercent){
                //     if(gameplanPercent._id == 'overall') return false;
                //     else{
                //         var kpi = _.find($scope.myKpis, {_id:gameplanPercent._id});
                //         if(kpi) return false;
                //         else return true;
                //     }
                // });

                //calculate deltas
                if(prevRank != -1)$scope.delta.rank = -1 * ($scope.rank - prevRank);
                else $scope.delta.rank = 0;
                if($scope.pbData.level){
                    $scope.delta.level = -1 * ($scope.pbData.level[0] - $scope.pbData.level[1] ? $scope.pbData.level[1]:0);
                }
                if(prevPoints){
                    $scope.delta.points = $scope.points - prevPoints[0];
                }

                //Set redflags kpiName
                _.forEach($scope.pbData.redFlags, function (badge) {
                    badge.kpiName = _.find(p0.allKpis, {_id:badge.from}).name;
					badge.imageUrl = badgeCorner.getImageUrl(badge.imageEnum);
                });

                //Status
                if($scope.pbData.statusBadge){
					$scope.pbData.statusBadge.imageUrl = badgeCorner.getImageUrl($scope.pbData.statusBadge.imageEnum);
                    $scope.popoverData.status.push({name:'Valid upto ', value:new Date($scope.pbData.statusBadge.expires)});
                    $scope.popoverData.status.push({name:'Earned on ', value:new Date($scope.pbData.statusBadge.date)});
                }else{
                    $scope.popoverData.status.push({name:'Not earned'});
                }


                //Styles, colors fa-arrows-h
                $scope.setIconsAndColors();
                if($scope.config.showBadges)
                {
                const postBody = {badgePeriod:'rolling'};
                $http.post('/api/badges/index', postBody).then(function(badges) {
                    var badges =  _.sortBy(badges.data,'badgeOrder');
                    $scope.popoverData.level = [];
                    if(pbData.statusBadge){
                        var index = _.findIndex(badges, {_id:pbData.statusBadge.badgeId.toString()});
                        if(index === 0){
                            $scope.popoverData.level.push({name:'Maintain',value:badges[index].metricValue});
                        }else{
                            $scope.popoverData.level.push({name:'Maintain',value:badges[index].metricValue});
                            $scope.popoverData.level.push({name:'Upgrade',value:badges[index-1].metricValue});
                        }
                    }else{
                        $scope.popoverData.level.push({name:'Upgrade',value:badges[badges.length-1].metricValue});
                    }
                });
            }
                $http.post('/api/gamePlans/getSimulationValidity').then(function(result) {
                    result=result.data;
                    $scope.simulationValid = result.status;
                });
            });
        };
        $scope.setIconsAndColors = function () {
            //Form in game
            $scope.greenText = false;
            $scope.redText = false;
            $scope.greyText = false;
            if($scope.pbData.formInGame == 0){
                $scope.formInGameColor = 'grey';
                $scope.formInGameArrow = 'fa-arrows-h';
                $scope.greyText = true;
            } else if($scope.pbData.formInGame == -1){
                $scope.formInGameColor = 'red';
                $scope.formInGameArrow = 'fa-arrow-down';
                $scope.redText = true;
            } else {
                $scope.formInGameColor = 'green';
                $scope.formInGameArrow = 'fa-arrow-up';
                $scope.greenText = true;
            }

            //Compliance
            if($scope.pbData.gpCompliance.status){
                $scope.complianceColor = 'green';
            } else {$scope.complianceColor = 'red';}
        };
        $scope.switch = true;

        $scope.openModal = function (type) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                controller: 'playerListCtrl',
                resolve:{
                    parameters: function(){
                        return {type:type, userId:$scope.userId, cycleId:$scope.selectedCycle._id};
                    }
                }
            });
        };

        $scope.fetchBarometerModalData = function(type){
            if(type == 'redFlag' && !$scope.smData.redFlagsCount)return;
            const postBody ={
                userId: $scope.userId,
                cycleId: $scope.selectedCycle._id,
                metric: type
            }
            $http.post('/api/userAnalytics/seniorManager/performanceList', postBody).then(function (result) {
                result=result.data;
                var userList = [];
                if(type == 'redFlag'){
                    _.each(result[type], function (users) {
                        userList = userList.concat(users);
                    });
                    showBadgePlayerList(userList, 'badge');
                }else{
                    showBadgePlayerList(result[type][$scope.selectedKpi._id], type);
                }
            });
        };

        var showBadgePlayerList = function(users, type) {
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate2,
                controller: 'playerListModalCtrl',
                resolve: {
                    data: function () {
                        return users;
                    },
                    type: function () {
                        return type;
                    },
                    title: function () {
                        if(type == 'badge'){
                            return 'Badges Earned';
                        }else if(type == 'gameplanTarget'){
                            return 'Gameplan Not Made';
                        }else{
                            return 'Gameplan Non Compliant';
                        }
                    },
                    cycleId : function () {
                        return $scope.selectedCycle._id;
                    },
                    roleToggle: function () {
                        return true;
                    }
                }
            });
        };

        $scope.$watchGroup(['selectedCycle', 'reportee', 'selectedKpi'],function (newValue, oldValue) {
            if(newValue[0] && newValue[1] && newValue[2] ){
                leagues = $scope.reportee.leagues;
                $scope.userId = $scope.reportee._id;
                $scope.isSeniorManager = ($scope.reportee.systemRole == "seniorManager");
                if(!$scope.isSeniorManager){
                    $scope.initPlayerData();
                }else{
                    $scope.fetchSMData();
                }
            }
        });
    });
