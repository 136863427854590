const autoImportNgTemplateLoaderTemplate1 = require('../html/admin/branch/branch.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/admin/branch/listBranch.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/admin/branch/addBranch.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/admin/branch/viewBranch.html');
const autoImportNgTemplateLoaderTemplate5 = require('../html/admin/branch/addBulkBranch.html');
const autoImportNgTemplateLoaderTemplate6 = require('../html/admin/branch/updateBulkBranch.html');

'use strict';

angular.module('bitnudgeApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('branch', {
        url: '/branch',
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        controller: 'BranchCtrl'
      })

        .state('lms.listBranch', {
        url: '/listBranch',
        templateUrl: autoImportNgTemplateLoaderTemplate2,
            controller: function ($http, $scope,  Auth, $location, $q, toaster) {
                $http.post('/api/branchs').then(function(branchs){
                    branchs=branchs.data;
                    $scope.branchs = branchs;
                    //socket.syncUpdates('branchs', $scope.branchs);
                });
            }
      })


        .state('lms.editBranch', {
            url: '/editBranch/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate3,
            controller: function ($stateParams, $scope, $http, $location,  toaster, Auth) {
                $scope.editForm = false;
                if($stateParams.id){
                    $scope.editForm = true;
                    $http.post('/api/branchs/show',{id: $stateParams.id} ).then(function (branch) {
                        branch=branch.data;
                        $scope.currentBranch = branch;
                        $http.post('/api/users/getUserById',{userId:branch.branchHeadId._id}).then(function (user) {
                            user=user.data;
                            $http.post('/api/users/getUsersOfJobRole',{jobRoleIds: [user.organizationalTag.jobRole._id]}).then(function (users) {
                                users=users.data;
                                $scope.users = users;
                            })
                        })
                    });
                }
                else{
                    $http.post('/api/users/').then(function (user) {
                        user=user.data
                        $scope.users = user;
                    })
                }
                $http.post('/api/regions/getByOrgId',{orgId: Auth.getCurrentUser().orgId}).then(function (regions) {
                    regions=regions.data;
                    $scope.regions = regions;
                });
                $scope.updateBranch = function () {
                    var branch = $.grep($scope.branchs, function(branch) {
                        return branch.name == $scope.branchName;
                    });

                    if(branch[0] == undefined || $scope.thisBranch[0].name == $scope.branchName) {
                        $http.post('/api/branchs/' + $stateParams.id, {
                                name: $scope.branchName,
                                regionId: $scope.branchRegion._id,
                                info: $scope.branchInfo} )
                            .then(function () {
                                $location.path('/branch/listBranch');
                                toaster.pop('success', "Branch Update", "Branch Details Updated.");
                            })
                            .catch(function () {
                                alert($scope.branchRegion._id);
                            });
                    } else {
                        toaster.pop('error', "Branch Edition", "Branch '"+$scope.branchName+"' Already Exists!");
                    }
                };
            }
        })

        .state('lms.viewBranch', {
            url: '/viewBranch/:id',
            templateUrl: autoImportNgTemplateLoaderTemplate4,
            controller: function ($http, $scope, $stateParams) {
                $http.post('/api/branchs/show',{id: $stateParams.id} ).then(function (branch) {
                    branch=branch.data;
                    $scope.branch = branch;
                    $http.post('/api/regions/show',{id: $scope.branch.regionId} ).then(function (region) {
                        region=region.data;
                        $scope.branch.region = region.name;
                        if($scope.branch.info == undefined)
                            $scope.branch.info = "-"
                        //socket.syncUpdates('branch', $scope.branch);
                    });
                });
            }
        })
        .state('lms.addBulkBranch',{
            url: '/addBulkBranch',
            templateUrl: autoImportNgTemplateLoaderTemplate5,
            controller:function ($http, $scope, Auth, Upload, $location, toaster) {

                $scope.onFileSelectLead = function () {
                    if ($scope.bulkLead == undefined) {
                        toaster.pop('error', "Submission.", "Select File to Upload.");

                    } else {
                        var fileName = $scope.bulkLead[0].name.split(".");
                        var fileType = fileName[fileName.length - 1];
                        if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                            Upload.upload({
                                url: '/api/branchs/import/excel',
                                data: {userId: Auth.getCurrentUser()._id, orgId: Auth.getCurrentUser().orgId},
                                file: $scope.bulkLead[0],
                                progress: function (e) {
                                }
                            }).then(function (data, status, headers, config) {
                                // file is uploaded successfully
                                toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                            });
                        } else {
                            toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                        }

                    }
                }

            }
        })
        .state('lms.updateBulkBranch',{
          url: '/updateBulkBranch',
          templateUrl: autoImportNgTemplateLoaderTemplate6,
          controller:function ($http, $scope, Auth, Upload, $location, toaster) {

            $scope.onFileSelectLead = function () {
              if ($scope.bulkLead == undefined) {
                toaster.pop('error', "Submission.", "Select File to Upload.");

              } else {
                var fileName = $scope.bulkLead[0].name.split(".");
                var fileType = fileName[fileName.length - 1];
                if (fileType == "xls" || fileType == "xlsx" || fileType == "csv") {
                  Upload.upload({
                    url: '/api/branchs/importUpdate/excel',
                    data: {userId: Auth.getCurrentUser()._id, orgId: Auth.getCurrentUser().orgId},
                    file: $scope.bulkLead[0],
                    progress: function (e) {
                    }
                  }).then(function (data, status, headers, config) {
                    // file is uploaded successfully
                    toaster.pop('success', "Excel uploaded", "Lead creation successfully finished.");
                  });
                } else {
                  toaster.pop('error', "Submission.", "Upload '.xls', '.xlsx' or '.csv' Types Only.");
                }

              }
            }

          }
        })

  });
