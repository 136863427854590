'use strict';

const lmsAdminSideBar = require('../html/sidebar/lmsAdminSideBar.html');
const generalSidebar = require('../html/sidebar/sidebar.html');

angular.module('bitnudgeApp').controller('LmsCtrl', function ($scope, Auth, User,$state, $rootScope) {
    $scope.currentUser = Auth.getCurrentUser();
    $scope.$state = $state;
    $rootScope.isRightSideBarExpand = false;
    $scope.config = $rootScope.config;
    $scope.setSidebar = function() {
      if ($scope.currentUser.systemRole === "lmsAdmin") return lmsAdminSideBar;
      else return generalSidebar;
    };
    $scope.toggleRightSide = function(){
      $rootScope.isRightSideBarExpand = !$rootScope.isRightSideBarExpand
      if(!$rootScope.isRightSideBarExpand){
        $rootScope.$emit('hideSideProductView',{})
      }
    }
    $rootScope.$on('hideSideRHSView', function(event, obj){
        $rootScope.isRightSideBarExpand = false
    })

    let dmsCloseListener = null;

    const initDMS = (parameters) => {
      $scope.isSelectMode = parameters.isSelectMode;
      $scope.isApproveMode = parameters.isApproveMode;
      $scope.isCustomerMode = parameters.isCustomerMode;
      $scope.isViewMode = parameters.isViewMode;
      $scope.workflowId = parameters.workflowId;
      $scope.cui = parameters.cui;
      $scope.context = parameters.context;
      $scope.selectedDocumentId = parameters.selectedDocumentId;
      $scope.selectedDocumentType = parameters.selectedDocumentType;
  
      if ($scope.isApproveMode || $scope.isCustomerMode) {
        if (parameters.documents && !Array.isArray(parameters.documents)) {
          $scope.documentIds = [parameters.documents];
        } else {
          $scope.documentIds = parameters.documents;
        }
      }
  
      if ($scope.isSelectMode) {
        if (parameters.documentTypes && !Array.isArray(parameters.documentTypes)) {
          $scope.documentTypes = [parameters.documentTypes];
        } else {
          $scope.documentTypes = parameters.documentTypes;
        }
      }
  
      if($scope.isViewMode){
        if($scope.workflowId){
          if (parameters.documentTypes && !Array.isArray(parameters.documentTypes)) {
            $scope.documentTypes = [parameters.documentTypes];
          } else {
            $scope.documentTypes = parameters.documentTypes;
          }
        }else{
          if (parameters.documents && !Array.isArray(parameters.documents)) {
            $scope.documentIds = [parameters.documents];
          } else {
            $scope.documentIds = parameters.documents;
          }
        }
  
      }
  
      const closeModal = () => {
        $rootScope.openDMS = false;
        $scope.$broadcast("dms:onCloseModal");
      };
  
      dmsCloseListener = $scope.$on("dms:trigger:close", (evt) => {
        closeModal();
      });
  
      $scope.closeDMS = function() {
        closeModal()
        $rootScope.$broadcast("documentAdded");
      };
      $rootScope.openDMS = true;
    };
  
    $rootScope.$on('dms:openModalLMS', function(event, parameters){
      //De register the existing event listener
      if(dmsCloseListener){
        dmsCloseListener();
      }
      initDMS(parameters);
    });
});
