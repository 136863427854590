'use strict';

angular.module('bitnudgeApp').controller('allTicketFiltersController', function($scope, $rootScope, $mdDialog, Tickets,
    p0, $timeout, filters, CurrentCycle, callback, UtilService) {
        $scope.filterObj = filters;
    $scope.selected = {};
    $scope.ticketTypeKey = $scope.filterObj.ticketTypeKey;
    $scope.ticketStateKey = $scope.filterObj.state;
    $scope.applyNOT = $scope.filterObj.applyNOT;
    var setTimeRange = function(monthType) {

        $scope.timeRange = UtilService.getTimeRange(monthType, 'ticket');
        if ($scope.filterObj.startDate) {
            $scope.selected.cycle = _.find($scope.timeRange, function(range) {
                return moment(range.startDate).valueOf() == moment($scope.filterObj.startDate).valueOf() &&
                    moment(range.endDate).valueOf() == moment($scope.filterObj.endDate).valueOf();
            });

            if (!$scope.selected.cycle) {
                $scope.selected.cycle = _.find($scope.timeRange, { _id: 'custom' });
                $scope.selected.cycle.startDate = new Date($scope.filterObj.startDate);
                $scope.selected.cycle.endDate = new Date($scope.filterObj.endDate);
            }
        }
        if (!$scope.selected.cycle) {
            $scope.selected.cycle = $scope.timeRange[0];
        }
    };
    setTimeRange('regular');
    $scope.filterTicketsOnType = function(key) {
        if ($scope.filterObj.type && $scope.ticketTypeKey == key) {
            $scope.filterObj.type = "";
            $scope.ticketTypeKey = null;
        } else {
            $scope.filterObj.type = key;
            $scope.ticketTypeKey = key;
        }
    };

    $scope.filterTicketsOnState = function(key) {
        if ($scope.filterObj.state && $scope.ticketStateKey === key) {
            $scope.filterObj.state = "";
            $scope.ticketStateKey = null;
        } else {
            $scope.filterObj.state = key;
            $scope.ticketStateKey = key;
        }
    };

    $scope.$watch('selected.cycle', function(newVal, oldVal) {
        if (newVal) {
            setDates(newVal);
        }
    });

    function setDates(cycle) {
        $scope.startDate = new Date(cycle.startDate);
        $scope.endDate = new Date(cycle.endDate);
        $scope.filterObj.startDate = $scope.startDate;
        $scope.filterObj.endDate = $scope.endDate;
    }

    $scope.cycleChange = {
        cycle: function(cycle) {
            if ($scope.selected.cycle && cycle._id != $scope.selected.cycle._id) {
                $scope.selected.cycle = cycle;
            };
            CurrentCycle.setCycle(cycle);
            $scope.filterObj.startDate = $scope.selected.cycle.startDate;
            $scope.filterObj.endDate = $scope.selected.cycle.endDate;
        },
        startDate: function(startDate) {
            $scope.startDate = new Date(startDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.startDate = $scope.startDate;
            }
            $scope.filterObj.startDate = $scope.startDate;
        },
        endDate: function(endDate) {
            $scope.endDate = new Date(endDate);
            if ($scope.selected.cycle) {
                $scope.selected.cycle.endDate = $scope.endDate;
            }
            $scope.filterObj.endDate = $scope.endDate;
        }
    };

    $scope.removeFilter = function(filter) {
        $scope.filterObj.actNowFilterKey = null;
        switch (filter) {
            case 'type':
                $scope.filterObj.type = "";
                break;
            case 'state':
                $scope.filterObj.state = "";
                break;
            case 'source':
                $scope.filterObj.source = "";
                break;
            default:
                break;
        }
    };

    $scope.onClose = function() {
        $mdDialog.hide();
    };

    var init = function() {
        Tickets.fetchFiltersList().then(function(result) {
            var filters = result.data.filters;
            $scope.firstListFilter = filters[0];
            $scope.firstListFilter = $scope.firstListFilter.filter(filter => filter.product === $scope.filterObj.tab)
            $scope.secondListFilter = filters[1];
        })
    };

    $scope.applyFilters = function() {
        callback($scope.filterObj);
        $mdDialog.hide();
    }

    init();
});