'use strict';
angular.module('bitnudgeApp')
    .service('AlertsService', function($http, Auth, $q, p0) {
        var _this = this;
        _this.getUserAlerts = function(filterObj, pagination, isUserContext, sortingObj, isAggregatedView, userId) {

        	const filters = {};
			filters.offset = pagination.offset;
			filters.limit = pagination.limit;
			filters.isUserContext = isUserContext;
			if(filterObj.level1){
				filters.tag = filterObj.level1;
			}

			if(filterObj.searchParam){
				filters.searchParam = filterObj.searchParam;
			}

			if(filterObj.level2 && filterObj.level2.name && filterObj.level2.name.toLowerCase() !== 'all'){
				filters.type = filterObj.level2.type;
				if(filterObj.level2.triggerField){
					filters.triggerField = filterObj.level2.triggerField;
				}
			}

			if((filterObj.level3 && filterObj.level3 !== 'all') || (filterObj.status && filterObj.status !== 'all')){
				filters.status = filterObj.level3 || filterObj.status;
			}

			if(filterObj.level4 && filterObj.level4 !== 'overall'){
				filters.productId = filterObj.level4;
			}

			if(filterObj.level5){
				if(filterObj.level5.startDate && filterObj.level5.endDate){
					filters.startDate = filterObj.level5.startDate;
					filters.endDate = filterObj.level5.endDate;
				}else{
					filters.interval = filterObj.level5._id;
				}
            }
            
            if(filterObj.cui){
                filters.cui = filterObj.cui
            }

            if (sortingObj && !_.isUndefined(sortingObj)) {
                filters.sortKey = sortingObj.sortKey;
                filters.sortOrder = sortingObj.sortOrder;
            }
            filters.isAggregatedView = isAggregatedView;
            if (userId) {
                filters.userId = userId;
            }
            filters['id'] = Auth.getCurrentUser()._id;
            const deferred = $q.defer();
            const baseUrl = '/api/alertsrec/user';
            $http.post(baseUrl, filters)
                .then(function(result) {
                    if (result.data.status) {
                        deferred.resolve(result.data.data)
                    } else {
                        deferred.reject(result.errors[0])
                    }
                }, function(error) {
                    deferred.reject(error)
                });
            return deferred.promise;
        };

        //delete functionality can be needed in future
        // _this.deleteAlert= function(alert){
        //     var deferred = $q.defer();
        //     $http.post('/api/alerts/deleteAlert/'+alert)
        //     .then(function(result){
        //         deferred.resolve(result.data)
        //         //socket.syncUpdates('notif', $scope.palerts);
        //     },function(error){
        //         deferred.reject(error)
        //     });
        //     return deferred.promise;
        // }

        _this.setAlertRead = function(alertObj) {
            const deferred = $q.defer();
            if (!alertObj.read) {
                alertObj.read = true;
                const baseUrl = '/api/alertsrec/setAlertRead';
                $http.post(baseUrl, { id:alertObj._id, read: alertObj.read }).then(function(response) {
                    if (response.data.status)
                        deferred.resolve(true)
                    else
                        deferred.reject(false)
                }, function(err) {
                    deferred.reject(false)
                });
            } else {
                deferred.resolve(true)
            }
            return deferred.promise;
        }

        _this.recentGeneratedAlertNotifications = function(filterObj, pagination) {
            filterObj.offset = pagination.offset;
            filterObj.limit = pagination.limit

            const deferred = $q.defer();
            const baseUrl = '/api/notifications/recentGeneratedAlertNotifications';
            filterObj.userId = Auth.getCurrentUser()._id;
            $http.post( baseUrl, filterObj)
                .then(function(result) {
                    if (result.data.status)
                        deferred.resolve(result.data.data)
                    else
                        deferred.reject(result.errors[0])

                }, function(error) {
                    deferred.reject(error)
                });
            return deferred.promise;
        }

        _this.setAlertActionRead = function(alertObj) {
            const deferred = $q.defer();
            if (!alertObj.read) {
                alertObj.read = true;
                const baseUrl = '/api/alertsrec/setAlertActionRead';
                $http.post(baseUrl, { id: alertObj._id, status: 'READ' }).then(function(response) {
                    if (response.data.status)
                        deferred.resolve(true)
                    else
                        deferred.reject(false)
                }, function(err) {
                    deferred.reject(false)
                });
            } else {
                deferred.resolve(true)
            }
            return deferred.promise;
        }

        //only this function relevant in this service.
        _this.setNonActionableAlertsRead = function(alertIds) {
            const deferred = $q.defer();
            const baseUrl = '/api/alertsrec/setNonActionableAlertsRead';
            $http.post(baseUrl, { ids: alertIds, read: 'READ' }).then(function(response) {
                if (response.data.status) {
                    deferred.resolve(true)
                } else {
                    deferred.reject(false)
                }
            }, function(err) {
                deferred.reject(false)
            });
            return deferred.promise;
        };

		_this.getFiltersList = function(){
			const deferred = $q.defer();
            const baseUrl = '/api/alertsrec/filters';
			$http.post(baseUrl)
				.then(function(result) {
					if (result.data.status)
						deferred.resolve(result.data.data);
					else
						deferred.reject(result.errors[0])

				}, function(error) {
					deferred.reject(error)
				});
			return deferred.promise;
		};

		_this.getTabsCountsSummary = function(tabId, isAggregatedView, userId){
            const deferred = $q.defer();
            const baseUrl = `/api/alertsrec/filters/summary`;
            let postData = {
                isAggregatedView,
                tabId
            }
            if (userId) {
                postData['userId'] = userId;
            }
            if (isAggregatedView) {
                postData['id'] = Auth.getCurrentUser()._id;
            }
			$http.post(baseUrl, postData)
				.then(function(result) {
					if (result.data.status)
						deferred.resolve(result.data.data);
					else
						deferred.reject(result.errors[0])

				}, function(error) {
					deferred.reject(error)
				});
			return deferred.promise;
		}
    });
