const autoImportNgTemplateLoaderTemplate1 = require('../html/activityTrendModal.html');
const autoImportNgTemplateLoaderTemplate2 = require('../html/activityLB.html');
const autoImportNgTemplateLoaderTemplate3 = require('../html/activityExtraDataModal.html');
const autoImportNgTemplateLoaderTemplate4 = require('../html/healthMetrics.html');

'use strict';
angular.module('bitnudgeApp')
    .filter('decimal', function ($filter) {
        return function (number, decimalPlace) {
            if (number == 0) return 0;
            else {
                if (!decimalPlace) {
                    var number = Math.round(number);
                    return $filter('number')(number, 0);
                } else {
                    var divisor = Math.pow(10, decimalPlace);
                    var number = Math.round(number * divisor) / divisor;
                    var fraction = (number % 1) ? 1 : 0;
                    return $filter('number')(number, fraction);
                }
            }
        }
    })
    .controller('labCtrl', function ($scope, Auth, $http, p0, $rootScope, decimalFilter, userAnalyticsService, moment, $mdDialog) {
        let self = this;
        var liveLabForActivityInput,previousDayCycle,allKpis, yesterday;

        self.init = function () {
            $scope.me = Auth.getCurrentUser();
            $scope.config = $rootScope.config;
            $scope.reporteeFeed = [];
            $scope.isFrontline = $rootScope.isFrontline;
            $scope.isManager = $rootScope.isManager;
            $scope.isSeniorManager = $rootScope.isSeniorManager;
            var today = p0.currentDailyCycle.name.split(" ");
            $scope.managerFeed = {}
            today.shift();
            today = today.join(' ');
            liveLabForActivityInput = $scope.config.liveLabForActivityInput;
            $scope.dropDowns = [{ name: 'MTD', _id: 'mtd' }];
            previousDayCycle = $scope.config.lms ? p0.currentDailyCycle.previousCycleId : p0.trackDailyCycle.previousCycleId;
            if (previousDayCycle && !liveLabForActivityInput) {
                yesterday = previousDayCycle.name.split(" ");
                yesterday.shift();
                yesterday = yesterday.join(' ');
                $scope.dropDowns.unshift({ name: yesterday, _id: 'yesterday' });
            }
            $scope.dropDowns.unshift({ name: today, _id: 'today' });
            if (liveLabForActivityInput) {
                var live = p0.liveDailyCycle.name.split(" ");
                live.shift();
                live = live.join(' ');
                $scope.dropDowns.unshift({ name: 'Today', _id: 'live' });
            }

            $scope.selectedPeriod = $scope.dropDowns[0];
            if (!$scope.config.liveLab) {
                $scope.selectedPeriod = _.find($scope.dropDowns, { _id: 'mtd' });
            }
            allKpis = _.map(p0.myKpis, '_id');
            allKpis.push('overall');
            $scope.labStatus = "";
            if (previousDayCycle) {
                userAnalyticsService.fetchUserAnalytics($scope.me._id, previousDayCycle._id).then(function (userAnalytic) {
                    if (!userAnalytic) {
                        _.remove($scope.dropDowns, { _id: 'yesterday' })
                    }
                });
            } else {
                _.remove($scope.dropDowns, { _id: 'yesterday' });
            }
            $scope.reportees = $scope.me.leafCount;
            $scope.showHealthMetrics = false;
        }
        self.isDailyCycleisToday=function() {
            var dt = new Date(p0.currentDailyCycle.startDate);
            return moment(dt).format('MM-DD-YYYY') == moment(new Date()).format('MM-DD-YYYY');
        }


        $scope.fetchReporteeLab = function (cycleId) {
            const postBody = {
                userId: $scope.userId,
                cycleId,
                type: 'web'
            }
            return $http.post('/api/userAnalytics/getLab', postBody).then(function (labData) {
                if(labData.data.status) {
                    labData = labData.data.data;
                    let data = {};
                    labData.forEach(user => {
                        data[user.userId] = {
                            userId: user.userId,
                            name:user.name,
                            image: user.image,
                            'overall':user.overall,
                        }
                        user.kpiActivities.forEach(kpi => {
                            if(p0.config.showDocumentShareLab){
                                kpi.activityData = kpi.activityData.filter( data => data.fieldName !== p0.config.ReferralActivity)
                            }else{
                                kpi.activityData = kpi.activityData.filter( data => data.fieldName !== p0.config.DocumentShareActivity)
                            }
                            data[user.userId][kpi.kpiId] = kpi.activityData;
                        })
                    })
					_.forEach(data, function (user) {
						_.forEach(allKpis, function (kpi) {
							user[kpi] = _.sortBy(user[kpi], 'order');
						})
					});
					$scope.managerFeed = data[$scope.userId];
                    $scope.reporteeFeed = [];
					_.forOwn(data, function (value, userId) {
						if (userId == $scope.userId) return;
						$scope.reporteeFeed.push(data[userId]);
                    });
				}
			});
        };
        $scope.$watchGroup(['selectedKpi', 'selectedUser', 'selectedCycle'], function (newValue, oldValue) {
            if (newValue[0] && newValue[1] && newValue[2]) {
                $scope.userId = $scope.selectedUser._id;
                if ($scope.selectedKpi._id == 'overall' && !$scope.config.showDayLabInOverallKPI) {
                    _.remove($scope.dropDowns, function (dp) {
                        return dp._id != 'mtd';
                    });
                    $scope.selectedPeriod = $scope.dropDowns[0];
                } else {
                    if (!_.find($scope.dropDowns, { _id: 'yesterday' }) && $scope.config.liveLab && !liveLabForActivityInput)
                        $scope.dropDowns.unshift({ name: yesterday, _id: 'yesterday' });
                    if (!_.find($scope.dropDowns, { _id: 'today' }) && $scope.config.liveLab)
                        $scope.dropDowns.unshift({ name: today, _id: 'today' });
                    // $scope.selectedPeriod = $scope.dropDowns[0];
                }
                $scope.changePeriod();
            }
        });
        $scope.resizeClicked = function () {
            $scope.resize();
        };

        $scope.changePeriod = function () {
            if ($scope.selectedPeriod._id == 'today') {
                $scope.fetchReporteeLab(p0.currentDailyCycle._id);
                if (self.isDailyCycleisToday()) {
                    $scope.labStatus = 'LIVE';
                } else $scope.labStatus = 'ACTUAL';
            } else if ($scope.selectedPeriod._id == 'yesterday') {
                $scope.labStatus = 'ACTUAL';
                $scope.fetchReporteeLab(previousDayCycle._id);
            } else if ($scope.selectedPeriod._id == 'live') {
                $scope.labStatus = 'LIVE';
                $scope.fetchReporteeLab(p0.liveDailyCycle._id);
            } else {
                var cycleId = $scope.selectedCycle && $scope.selectedCycle._id;
                if (!$scope.selectedCycle.active)
                    cycleId = _.find(p0.allMonthlyCycles, { subType: $scope.selectedCycle.subType, active: true })._id;
                self.isDailyCycleisToday() ? $scope.labStatus = 'LIVE' : $scope.labStatus = 'ACTUAL';
                $scope.fetchReporteeLab(cycleId);
            }
        };



        $scope.switchChanged = function (changed) {
            {
                $scope.perIFEenabled = changed;
                if (changed) $scope.divisor = $scope.reportees;
                else $scope.divisor = 1;
            }
        }

        $scope.trendGraph = function (activity, $event) {
            var options = [{ id: activity.fieldName, name: activity.name, metric: 'kai', activityType: activity.activityType, unit: activity.unit }];
            var cycle = _.find(p0.allMonthlyCycles, { subType: $scope.selectedCycle.subType, active: true });
            var timeRange = { startDate: moment(cycle.startDate).valueOf(), endDate: moment(cycle.endDate).valueOf() };
            var cycleType = { id: 'daily', name: 'Daily' };
            var parameters = {
                userId: $scope.me._id, timeRange: timeRange,
                kpi: $scope.selectedKpi, metric: options[0], cycleType: cycleType, cycle: cycle
            };
            $mdDialog.show({
                controller: 'activityTrendModalCtrl',
                templateUrl: autoImportNgTemplateLoaderTemplate1,
                parent: angular.element(document.body),
                targetEvent: $event,
                clickOutsideToClose: true,
                escapeToClose: true,
                resolve: {
                    parameters: function () {
                        return parameters;
                    },
                    metricOptions: function () {
                        return options;
                    }
                }
            });
        };
        $scope.fractionSize = function (value) {
            if (value == 0) return 0;
            else if (value != 0) return 1;
        }
        $scope.saveActivity = function (activity) {
            var cycle;
            if ($scope.selectedPeriod._id === 'live') {
                cycle = p0.liveDailyCycle._id;
            }
            const postBody = {
                userId: $scope.me._id,
                cycleId: cycle,
                kpiId: $scope.selectedKpi._id,
                fieldName: activity.fieldName,
                value: activity.value
            }
            $http.post('/api/userAnalytics/setActivity', postBody).then(function () {

            });
        };
        $scope.saveActivityForReportee = function (activity, userId) {
            var cycle;
            if ($scope.selectedPeriod._id === 'live') {
                cycle = p0.liveDailyCycle._id;
            }
            const postBody = {
                userId,
                cycleId: cycle,
                kpiId: $scope.selectedKpi._id,
                fieldName: activity.fieldName,
                value: activity.value
            }
            return $http.post('/api/userAnalytics/setActivity', postBody).then(function (increment) {
                increment = increment.data;
                var newActivity = _.find($scope.managerFeed[$scope.selectedKpi._id], { fieldName: activity.fieldName });
                newActivity.value += increment.increment;
            });
        };
        $scope.getLBofActivity = function (activity) {
            var kpiId = $scope.selectedKpi._id;
            var activityId = activity.activityId, activityName = activity.name;
            var cycleId = p0.liveDailyCycle._id;
            const postBody = {
                activityId,kpiId,cycleId
            }
            return $http.post('api/useranalytics/activityLB', postBody).then(function (leaderBoard) {
                leaderBoard = leaderBoard.data;
                $mdDialog.show({
                    controller: 'activityLBCtrl',
                    templateUrl: autoImportNgTemplateLoaderTemplate2,
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    resolve: {
                        data: function () { return leaderBoard; },
                        activityName: function () { return activityName }
                    }
                });
            });
        };
        $scope.showExtraData = function (user, extraData) {
            if (!extraData) return;
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate3,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                escapeToClose: true,
                controller: 'activityExtraDataCtrl',
                resolve: {
                    user: function () { return user },
                    data: function () { return extraData; },
                    kpi: function () { return $scope.selectedKpi }
                }
            });
        };


        $scope.$watch('selectedPlayType', function (newVal, oldVal) {
            if (newVal && newVal.healthMetrics && newVal.healthMetrics.length) {
                $scope.showHealthMetrics = true;
            } else {
                $scope.showHealthMetrics = false;
            }
        });

        $scope.healthMetrics = function() {
            var playType = $scope.selectedPlayType;
            var user = $scope.selectedUser;
            var cycle = $scope.selectedCycle;
            $mdDialog.show({
                templateUrl: autoImportNgTemplateLoaderTemplate4,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                escapeToClose: true,
                controller: 'healthMetricsCtrl',
                resolve: {
                    user: function () { return user },
                    cycle: function () { return cycle; },
                    playType: function () { return playType }
                }
            });
        };

		// $scope.rotateIcon = function (){
		// 	var icon = document.getElementById("expand-icon");
		// 	if(icon.className == "fa fa-collapse") icon.className = "fa fa-expand blue ng-scope";
		// 	else{
		// 		icon.className = "fa fa-expand";
		// 	}
		// };

        self.init();
    });
