const autoImportNgTemplateLoaderTemplate1 = require('../html/customers/leadtableCustomerContainer.html');

'use strict';
angular.module('bitnudgeApp')
    .component('leadtableCustomerContainer', {
        templateUrl: autoImportNgTemplateLoaderTemplate1,
        bindings:{
            customer : "<"
        },
        controller: 'leadtableCustomerContainerController' 
    });
