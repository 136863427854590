'use strict';

angular.module('bitnudgeApp')
  .factory('$redirectRouter', function $redirectRouter($location,$state, $rootScope,p0) {

    var isRedirected,extraData;
    var router={
    	'lead':{
    		state:'lms.listLead',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
    	},
      'huddleBoard':{
          state:'lms.huddleBoard',
              routerCallbacks:{
          },
          routerCallbackStatus:[]
      },
      'analytic':{
    		state:'userFlow.analytics',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
    	},
        'analytic:myForm':{
            state:'userFlow.analytics',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'analytic:comparison':{
            state:'userFlow.analytics',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'playbook':{
            state:'userFlow.myPlayBook',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
    	'playbook:badgeCorner':{
    		state:'userFlow.myPlayBook',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
    	},
        'gameplan:simulate':{
            state:'userFlow.gameplan',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
    	'gameplan:analyze':{
            state:'userFlow.gameplan',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
    	},
        'userFlow.gameplan':{
            state:'userFlow.gameplan',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
    	'profile':{
            state:'userFlow.profile',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
    	},
        'chats':{
            state:'userFlow.chats',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'badge':{
            state:'userFlow.badgeCorner',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'redemptionPoints':{
    	    state:'userFlow.myPlayBook',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'alerts':{
            state:'userFlow.customer',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
        'ticket':{
            state:'lms.tickets',
            routerCallbacks:{
            },
            routerCallbackStatus:[]
        },
		'userFlow.editLead':{
            state: p0.config.leadUI_v1 ? 'userFlow.editLead_v1': 'userFlow.editLead_v2',
			routerCallbacks:{
			},
			routerCallbackStatus:[]
		},
		'userFlow.editTicket':{
			state:'userFlow.editTicket',
			routerCallbacks:{
			},
			routerCallbackStatus:[]
		},
    };

    return {
    	redirectTo:function(link,extraData) {
            console.log('here redirect')
            isRedirected=link;
    		if(router[link]){
    			var selectedRoute=router[link];
    			selectedRoute.extraData=extraData;
    			$state.go(selectedRoute.state,selectedRoute.extraData);
				$rootScope.isRightSideBarExpand = false;
    		}
    		else{
    			console.log('invalid redirection. '+link+' config doesnt exist');
    		}
    	},
    	getExtraDataOf:function(link) {
    		if(router[link])
    			return router[link].extraData;
    		else {
    			console.log('error. '+link+' config not found');
    			return {};
    		}
    	},
        isRedirected:function() {
            return isRedirected;
        },
        unsetRedirected:function() {
            isRedirected=false;
        }
    };
  });
