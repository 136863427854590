'use strict';
angular.module('bitnudgeApp').controller('ticketExportCtrl', function ($scope,p0, $mdDialog, parameters,Tickets, UtilService) {
    $scope.pagination = Tickets.getPaginationObj();
    $scope.pagination.limit =  parameters.totalTickets
    $scope.strings = p0.strings;

    var appUrl = p0.config.domain;
    var subfolder = p0.config.subfolder;
    if(subfolder){
        if(appUrl[appUrl.length -1] !== '/'){
            appUrl += '/';
        }
        if(subfolder[0] == '/'){
            subfolder = subfolder.slice(1);
        }
        appUrl += subfolder;
    }
    function diableAllFilters(){
        parameters.filterObj.startDate = null;
        parameters.filterObj.endDate = null;
        parameters.filterObj.state = null;
        parameters.filterObj.type = null;
        parameters.filterObj.searchKey =  null;
        parameters.filterObj.archived = null;
        parameters.filterObj.tab = null;
        $scope.pagination.offset = 0;
        $scope.pagination.limit = 0;

    }
    var downloadOptTickets = function(userId, filterObj,pagination){
        if (!appUrl || appUrl == undefined) {
            appUrl='';
        }
        var baseUrl = appUrl + '/api/tickets/downloadTickets/';
        var paramData = {
            userId:userId._id,
            withReporting : filterObj.withReporting,
            state : filterObj.state ? filterObj.state : null,
            type : filterObj.type ? filterObj.type : null ,
            searchKey : filterObj.searchParam ? filterObj.searchParam : null,
            productId : filterObj.tab,
            archived : filterObj.archived ? filterObj.archived : null,
            offset : pagination.offset,
            limit : pagination.limit,
            download : filterObj.download ? filterObj.download : false,
            unassignedView: filterObj.viewCategory === "unassigned"
        };
        if(filterObj.playtypeSelected){
            paramData.playtypeSelected = filterObj.playtypeSelected;
        }

        if (filterObj.startDate) {
            paramData.startDate = new Date(filterObj.startDate).getTime()
        }
        if (filterObj.endDate) {
            paramData.endDate = new Date(filterObj.endDate).getTime()
        }
        if (parameters.sortingObj && !_.isUndefined(parameters.sortingObj)) {
            paramData.sortKey = parameters.sortingObj.sortKey;
            paramData.sortOrder = parameters.sortingObj.sortOrder;
        }

         UtilService.downloadFile(baseUrl, paramData)
    }
    $scope.exportTickets = function (isAllTickets) {
  
        if(isAllTickets){
            diableAllFilters();
        }
        parameters.filterObj.download=true;
        downloadOptTickets(parameters.currentUser, parameters.filterObj, $scope.pagination)
        $scope.cancel();
    };
    $scope.cancel = function() {
        $mdDialog.cancel();
    };

});