'use strict';

angular.module('bitnudgeApp').controller('editLeadInfoCtrl', function (Auth, $scope, leadsService, lead, $mdDialog, reloadLeadInfoPage, toaster, p0) {
	$scope.config = p0.config;
	$scope.lead = lead;
	$scope.myIntlTelInputCtrl;
	const {contactNumber} = lead.contact;
	$scope.selected = {
		firstName: lead.contact.firstName,
		middleName: lead.contact.middleName,
		lastName: lead.contact.lastName,
		email: lead.contact.email,
		number: `${contactNumber.countryCode}${contactNumber.number}`
	};
	
	if(contactNumber.countryCode){
		const allCounties = window.intlTelInputGlobals.getCountryData();
		const code = contactNumber.countryCode.replace('+','');
		$scope.country = allCounties.find(c => c.dialCode === code);
	}

	$scope.onClose = function () {
		$mdDialog.hide();
	};
	
	$scope.onSubmit = () => {
		const data = {
			firstName: $scope.selected.firstName,
			middleName: $scope.selected.middleName,
			lastName: $scope.selected.lastName,
			email: $scope.selected.email,
		};

		leadsService.updateLeadInfo(lead._id, data).then((result) => {
			const { data } = result;
			const header = 'Update Lead info';
			if(data.status){
				toaster.pop('success', header, `${header} is successful.`);
				reloadLeadInfoPage();
				$scope.onClose();
			}else if(data.errors && data.errors.length){
				data.errors.forEach(e => {
					toaster.pop('error', header, e.message);
				})
			}
		}).catch(function (err) {
			toaster.pop('Error', err.message)
		});
	}
})
